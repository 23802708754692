import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const NotificationButton = styled(IconButton)`
  cursor: pointer;
  align-self: center;
  margin-left: auto;
  padding: 0;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-left: 4px;
`;

export const TooltipTitle = styled(Tooltip)`
  height: 17px;
  width: 17px;
`;
