import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormHelperText, Grid } from '@material-ui/core';
import SelectInfiniteAsync from '@rchlo/storybook/core/SelectInfiniteAsync';
import { IntegrationPlatformOnboarding } from '../../../../@types/onboarding';
import { PlatformValuesState } from '../../../../@types/platformValues';
import InformationModal from './InformationModal';

interface Props {
  formik: FormikProps<IntegrationPlatformOnboarding>;
}

const PlatformChoice: React.FC<Props> = ({ formik: { values, setFieldValue } }) => {
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const dispatch = useDispatch();
  const { selectedPlatform, integrationPlatformList, hasNextPage, fetchingSelect, pageIndex } = useSelector<
    {
      platformValues: PlatformValuesState;
    },
    PlatformValuesState
  >(({ platformValues }) => ({ ...platformValues }));

  useEffect(() => {
    if (selectedPlatform?.id) {
      setFieldValue('integrationPlatformId', selectedPlatform?.id);
      setFieldValue('integrationPlatformName', selectedPlatform?.name);
    }
  }, [selectedPlatform]);

  const handlePlatformIntegration = (event: any) => {
    setFieldValue('integrationPlatformId', event?.id);
    setFieldValue('integrationPlatformName', event?.name);
  };

  const getPlatforms = () => {
    dispatch({
      type: '@platformValues/FETCH_LIST',
      payload: {
        pageIndex,
      },
    });
  };

  return (
    <Grid container justify="space-between" spacing={4} alignItems="center">
      <Grid item xs={12}>
        <FormControl fullWidth>
          <SelectInfiniteAsync
            id="integrationPlatformName"
            name="integrationPlatformName"
            onChange={handlePlatformIntegration}
            value={
              values?.integrationPlatformName
                ? { id: values?.integrationPlatformId, name: values?.integrationPlatformName }
                : selectedPlatform
            }
            items={integrationPlatformList}
            hasNextPage={hasNextPage}
            isNextPageLoading={fetchingSelect}
            loadNextPage={getPlatforms}
            placeholder="Selecione a sua integradora"
          />
          <FormHelperText style={{ color: '#4B4B4B', textDecoration: 'underline' }}>
            <div onClick={() => setOpenInformationModal(true)} style={{ cursor: 'pointer' }}>
              <span>O que é uma plataforma integradora?</span>
            </div>
          </FormHelperText>
        </FormControl>
        <InformationModal open={openInformationModal} setOpen={setOpenInformationModal} />
      </Grid>
    </Grid>
  );
};

export default PlatformChoice;
