import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Steps } from '../../../../@types/platformValues';
import CopyAdornmentButton from '../../../../shared/components/CopyAdornmentButton';
import { IntegrationSettingsState } from '../../../../store/ducks/seller/integration-settings.duck';
import { Container, Header, StyleModal } from '../../FirstSteps.styles';
import {
  ModalTitle,
  ModalText,
  HelpTextBold,
  HelpText,
  LinkHelperUnderline,
  HelperContainer,
} from '../components/fontStyles';
import { ArrowLeftIcon, BackButton } from './BackButton';
import ModalHeader from './ModalHeader';
import { ModalContainerBody, ModalHeaderContainer } from './shared-components';

interface Props {
  sellerId: string;
  backStep: Function;
  onSupportClick: Function;
  onTicketHistoryClick: Function;
}

const IntegrationSettingsModal: React.FC<Props> = ({ sellerId, backStep, onSupportClick, onTicketHistoryClick }) => {
  const dispatch = useDispatch();
  const title = 'Bem-vindo, para continuar confira as informações';
  const { fetching, clientId, secret, success, error } = useSelector<{ integrationSettings: IntegrationSettingsState }>(
    (state) => state.integrationSettings,
  ) as IntegrationSettingsState;

  useLayoutEffect(() => {
    dispatch({
      type: '@integrationSettings/FETCH',
      payload: {
        sellerId,
      },
    });
  }, [dispatch]);

  const nextStep = () => {
    dispatch({
      type: '@platformValues/SET_NEXT_STEP',
      payload: { nextStep: Steps.FREIGHT, isSubmitting: false },
    });
  };

  const retryFetch = () => {
    dispatch({
      type: '@integrationSettings/FETCH',
      payload: {
        sellerId,
      },
    });
  };

  const renderIntegrationSettings = () => {
    if (!error && success) {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                id="clientId"
                label="Client id"
                value={clientId}
                fullWidth
                disabled
                InputProps={{ endAdornment: <CopyAdornmentButton value={clientId} /> }}
              />
              <Grid item xs={12} md={12}>
                <TextField
                  label="Client Secret"
                  value={secret}
                  fullWidth
                  disabled
                  InputProps={{ endAdornment: <CopyAdornmentButton value={secret} /> }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
    return null;
  };

  const renderRetryIntegrationSettings = () => {
    if (error) {
      return (
        <>
          <ModalHeaderContainer>
            <ModalTitle>Ops, ocorreu um erro.</ModalTitle>
          </ModalHeaderContainer>
          <ModalText>Encontramos problemas ao carregar as informações.</ModalText>
          <ModalText>Caso o erro persista, entre em contato com nosso time de suporte</ModalText>
        </>
      );
    }
    return null;
  };

  return (
    <StyleModal
      disableAutoFocus
      disableEnforceFocus
      open={true}
      aria-labelledby="modal-first-steps-setup"
      aria-describedby="modal-setup-with-first-steps"
    >
      <Container>
        <Header data-testid={`firstStepsSetupTitle-${title}`}>{title}</Header>
        <ModalContainerBody>
          {!error && (
            <ModalHeader
              title="Configurações de integração"
              description="Guarde as credenciais abaixo, você precisará delas para acessar nossas API`S"
              tooltipTitle="Acesse estas informações em Configurações > Configurações de Integração"
              onPress={onTicketHistoryClick}
            />
          )}
          {renderIntegrationSettings()}
          {renderRetryIntegrationSettings()}
          {fetching && (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          )}
          <HelperContainer>
            <HelpTextBold>Precisa de ajuda? </HelpTextBold>
            <HelpText>Entre em contato com o nosso </HelpText>
            <LinkHelperUnderline onClick={() => onSupportClick()}>Suporte.</LinkHelperUnderline>
          </HelperContainer>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <BackButton onClick={() => backStep()} color="default" type="button" startIcon={<ArrowLeftIcon />}>
              Voltar
            </BackButton>
            <Button
              onClick={() => (error ? retryFetch() : nextStep())}
              variant="contained"
              color="primary"
              type="button"
              style={{ marginBottom: 20, marginTop: 20 }}
            >
              {error ? 'Tentar novamente' : 'Continuar'}
            </Button>
          </Box>
        </ModalContainerBody>
      </Container>
    </StyleModal>
  );
};

export default IntegrationSettingsModal;
