import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { BusinessParamsState } from '../../@types/businessParams';
import { OnboardingState } from '../../@types/onboarding';
import { PlatformValuesState, Steps } from '../../@types/platformValues';
import { history } from '../../shared/utils/history';
import verifyPermissionToManage from '../../shared/utils/verifyPermissionToManage';
import CreateTicketForm from '../SAC/CreateTicketForm';
import { GTAG, tagEventActionEnum } from '../SAC/utils';
import SetupIntegration from './SetupIntegration';
import FinishOnboardingModal from './SetupIntegration/components/FinishOnboardingModal';
import FreightProviderModal from './SetupIntegration/components/FreightProviderModal';
import IntegrationSettingsModal from './SetupIntegration/components/IntegrationSettingsModal';
import TicketHistoryModal from './SetupIntegration/components/TicketHistoryModal';
import WebhookModal from './SetupIntegration/components/WebhookModal';
import Tutorial from './Tutorial';

interface ReduxState {
  onboarding: OnboardingState;
  platformValues: PlatformValuesState;
  businessParams: BusinessParamsState;
}

interface ReduxValue {
  onboarding: OnboardingState;
  platformValues: PlatformValuesState;
  businessParams: BusinessParamsState;
}

interface Props {
  setShouldRenderFirstStep: Function;
  sellerId: string;
}

export default function FirstSteps({ setShouldRenderFirstStep, sellerId }: Props) {
  const dispatch = useDispatch();

  const isOnboardingAvailable = verifyPermissionToManage({
    api: 'mktp-mktp-odis-api-seller',
    role: 'onboarding',
  });

  const {
    onboarding: { hasSetupCompleted, featureToggleOnboarding },
    platformValues: { integrationDataCompleted, selectedPlatform, nextStep },
    businessParams: { fetching },
  } = useSelector<ReduxState, ReduxValue>((state) => ({
    onboarding: state.onboarding,
    platformValues: state.platformValues,
    businessParams: state.businessParams,
  }));

  const isVtexOrAnymarket =
    selectedPlatform?.name?.toUpperCase() === 'VTEX' || selectedPlatform?.name?.toUpperCase() === 'ANYMARKET';
  const shouldRenderSetup = isOnboardingAvailable && featureToggleOnboarding && !hasSetupCompleted;

  const [currentStep, setCurrentStep] = useState<Steps>(shouldRenderSetup ? nextStep : Steps.TUTORIAL);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showTicketHistoryModal, setShowTicketHistoryModal] = useState(false);

  useEffect(() => {
    shouldRenderSetup && setCurrentStep(nextStep);
  }, [nextStep]);

  useEffect(() => {
    if (integrationDataCompleted && isVtexOrAnymarket) {
      setCurrentStep(Steps.FINISH);
    } else if (integrationDataCompleted) {
      dispatch({
        type: '@onboarding/ONBOARDING_HAS_FINISHED',
        payload: {
          hasSetupCompleted: true,
        },
      });
    }
  }, [integrationDataCompleted]);

  const backStep = () => {
    dispatch({
      type: '@platformValues/SET_NEXT_STEP',
      payload: { nextStep: nextStep - 1, isSubmitting: false },
    });
  };

  const onSupportClick = () => {
    GTAG(`${tagEventActionEnum.CLICK}:botao:integracao`, 'abrir-ticket');
    setShowSupportModal(true);
  };

  const getStep = () => {
    if (shouldRenderSetup) {
      let currentComponent: React.ReactNode = null;

      switch (currentStep) {
        case Steps.SETUP:
          currentComponent = (
            <SetupIntegration
              sellerId={sellerId}
              onSupportClick={onSupportClick}
              onTicketHistoryClick={() => setShowTicketHistoryModal(true)}
            />
          );
          break;
        case Steps.FREIGHT:
          currentComponent = (
            <FreightProviderModal
              backStep={backStep}
              sellerId={sellerId}
              onSupportClick={onSupportClick}
              onTicketHistoryClick={() => setShowTicketHistoryModal(true)}
            />
          );
          break;
        case Steps.WEBHOOK:
          currentComponent = (
            <WebhookModal
              backStep={backStep}
              sellerId={sellerId}
              onSupportClick={onSupportClick}
              onTicketHistoryClick={() => setShowTicketHistoryModal(true)}
            />
          );
          break;
        case Steps.INFO:
          currentComponent = (
            <IntegrationSettingsModal
              backStep={backStep}
              sellerId={sellerId}
              onSupportClick={onSupportClick}
              onTicketHistoryClick={() => setShowTicketHistoryModal(true)}
            />
          );
          break;
        case Steps.FINISH:
          currentComponent = (
            <FinishOnboardingModal open={true} sellerId={sellerId} integrationPlatformName={selectedPlatform?.name} />
          );
          break;
      }
      return (
        <>
          {!showTicketHistoryModal && currentComponent}
          <CreateTicketForm isIntegration open={showSupportModal} closeModal={() => setShowSupportModal(false)} />
          <TicketHistoryModal
            open={showTicketHistoryModal}
            closeModal={() => setShowTicketHistoryModal(false)}
            onSupportClick={onSupportClick}
          />
        </>
      );
    }
    return (
      <Tutorial
        goOutFirstStep={() => {
          setShouldRenderFirstStep(false);
          window.location.href;
        }}
      />
    );
  };

  return fetching ? <LinearProgress /> : getStep();
}
