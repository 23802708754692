import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { HelpOutline } from '@material-ui/icons';
import LayoutStorybook from '@rchlo/storybook/core/Layout';
import { BrandsState } from '../@types/brands';
import { BusinessParamsState } from '../@types/businessParams';
import { NotificationState } from '../@types/notifications';
import { PlatformValuesState } from '../@types/platformValues';
import { useAuth } from '../contexts/AuthProvider';
import { StorybookContext } from '../contexts/Storybook.context';
// import { logoutUser } from '../index';
import { allMenuOptions, SubMenuProps } from '../shared/components/Menu/MenuOptions';
import verifyPermissionToManage from '../shared/utils/verifyPermissionToManage';

const preloadPages = (submenu: SubMenuProps[]) => {
  submenu?.forEach(({ preloadPage }) => preloadPage && import(`../pages/${preloadPage}`));
};

const Layout: React.FC<{ style?: {}; className?: string }> = ({ children, style, className }) => {
  // const { keycloak } = useKeycloak();
  const { user, authenticated, resourceAccess, logout } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const { sellers, selected, userId, isLiteAccount } = useSelector<{ brands: BrandsState }, BrandsState>(
    ({ brands }) => ({
      ...brands,
    }),
  );
  const { items, hasNewNotifications } = useSelector<{ notifications: NotificationState }, NotificationState>(
    ({ notifications }) => ({ ...notifications }),
  );
  const { featureToggleUpdate, featureToggleSAC, featureToggleActionCenter } = useSelector<
    { businessParams: BusinessParamsState },
    BusinessParamsState
  >((state) => state.businessParams);
  const { isVtexOrAnymarket } = useSelector<
    {
      platformValues: PlatformValuesState;
    },
    PlatformValuesState
  >(({ platformValues }) => ({ ...platformValues }));

  const notificationsIds = items.map(({ id }) => id);

  const sendConfirmationRead = () => {
    dispatch({
      type: '@notifications/READ_NOTIFICATIONS',
      payload: {
        notificationsIds,
      },
    });
  };

  const changeBrand = (value: string) => {
    dispatch({
      type: '@layout/FETCH_BACK_DROP',
      payload: {
        backDropOpen: true,
      },
    });

    dispatch({
      type: '@brands/UPDATE_BRAND',
      payload: {
        sellerId: value,
        userId,
        callback: () => {
          window.location.href = '/';
        },
      },
    });
  };

  const sideMenuOptions = allMenuOptions({ featureToggleUpdate, featureToggleSAC, featureToggleActionCenter })
    .filter((menuOption) => {
      return verifyPermissionToManage(menuOption.requiredUserPermission, isLiteAccount);
    })
    .map((item) => {
      return {
        ...item,
        submenu: isLiteAccount
          ? item?.submenu?.filter((item) => item.label !== 'Parâmetros de integração')
          : item?.submenu?.filter((item) => {
              if (isVtexOrAnymarket && item.label === 'Configurações de integração') {
                return false;
              }
              return verifyPermissionToManage(item.requiredUserPermission, isLiteAccount, undefined, isVtexOrAnymarket);
            }),
      };
    });

  const cbAction = (action: string, submenu?: SubMenuProps[]) => {
    switch (action) {
      case 'logout':
        logout();
        break;
      case 'preload_pages':
        preloadPages(submenu || []);
        break;
      case 'menuCollapseButton':
        dispatch({
          type: '@layout/MENU_CLICK',
        });
        break;
    }
  };

  return (
    <LayoutStorybook
      style={style}
      className={className}
      tokenParsed={{
        name: user.name,
        seller_id: user.seller_id,
        family_name: user.family_name,
        given_name: user.given_name,
      }}
      cbAction={cbAction}
      menuOptions={sideMenuOptions}
      history={history}
      multiStore
      brands={sellers}
      changeBrand={changeBrand}
      selected={selected}
      isNotification
      notifications={items}
      sendConfirmationRead={sendConfirmationRead}
      notificationStyle={{
        style: { marginRight: '10px' },
      }}
      hasNewNotifications={hasNewNotifications}
      custom={
        isLiteAccount
          ? {
              title: 'Conta Lite',
              bgHeader: '#808080',
              bgMenu: '#505050',
              bgIcon: '#ffffff',
              icon: <HelpOutline style={{ cursor: 'pointer' }} />,
              iconText: 'Conta de Homologação do Riachuelo Marketplace',
            }
          : undefined
      }
    >
      {children}
    </LayoutStorybook>
  );
};

const useLayout = () => {
  const context = useContext(StorybookContext);
  const history = useHistory();

  if (!context?.menu) {
    throw new Error('Without context!');
  }

  return { menu: context.menu, history, Layout };
};

export default useLayout;
