import * as yup from 'yup';

const confirmIsRequired = yup.string().required('Confirme as informações');
const isRequired = 'Esta informação é obrigatória';
const YupRequiredValidation = yup.string().required(isRequired);

export const generateValidationSchema = () => {
  return yup.object().shape({
    isRequired: yup.boolean(),
    salesChannel: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'VTEX',
      then: YupRequiredValidation,
    }),
    affiliateId: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'VTEX',
      then: YupRequiredValidation,
    }),
    accountName: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'VTEX',
      then: YupRequiredValidation,
    }),
    applicationKey: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'VTEX',
      then: YupRequiredValidation,
    }),
    applicationToken: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'VTEX',
      then: YupRequiredValidation,
    }),
    confirmPlatform: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'VTEX',
      then: confirmIsRequired,
    }),
    oi: yup.string().when('integrationPlatformName', {
      is: (value: string) => value === 'ANYMARKET',
      then: YupRequiredValidation,
    }),
  });
};

export const validationSchema = generateValidationSchema();
