import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ErrorMessages } from '../../../../@types';
import { IntegrationPlatformOnboarding } from '../../../../@types/onboarding';
import WarningLabel from './WarningLabel';
import { LinkHelper, ModalText, ModalTextBold, LinkHelperUnderline } from './fontStyles';

const IntegrationPlatform: React.FC<{
  formik: FormikProps<IntegrationPlatformOnboarding>;
  isDisabled?: boolean;
  errorMessage?: ErrorMessages;
  formikInputValues: IntegrationPlatformOnboarding;
  onSupportClick: Function;
}> = ({ formik: { values, touched, errors, handleChange, setErrors }, errorMessage, onSupportClick }) => {
  const isVtex = values?.integrationPlatformName === 'VTEX';
  const isAnymarket = values?.integrationPlatformName?.toUpperCase() === 'ANYMARKET';
  const { REACT_APP_RCHLO_PORTAL_DEV, REACT_APP_VTEX_HELPER_URL } = process.env;

  useEffect(() => {
    if (errorMessage && errorMessage?.length > 0) {
      if (errorMessage?.[0]?.status === 'application') {
        setErrors({
          ['applicationKey']: errorMessage?.[0].message,
          ['applicationToken']: errorMessage?.[0].message,
        });
      } else if (errorMessage?.[0]?.status !== 'genericError') {
        setErrors({
          [errorMessage?.[0].status]: errorMessage?.[0].message,
        });
      }
    }
  }, [errorMessage]);

  const renderVtexFields = () => (
    <>
      <Grid item sm={8} md={8} lg={8}>
        <TextField
          name="accountName"
          label="Nome da conta"
          fullWidth
          value={values?.accountName}
          error={Boolean(errors?.accountName) && touched?.accountName}
          helperText={errors?.accountName && touched?.accountName ? errors?.accountName : null}
          onChange={handleChange}
          inputProps={{ maxLength: 150 }}
        />
      </Grid>
      <Grid item sm={4} md={4} lg={4}>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                name="confirmPlatform"
                color="primary"
                value={values?.confirmPlatform}
                onChange={handleChange}
                checked={values?.confirmPlatform}
              />
            }
            label="Confirmar"
          />
          <FormHelperText>
            {errors?.confirmPlatform && touched?.confirmPlatform ? errors.confirmPlatform : null}
          </FormHelperText>
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <TextField
          name="salesChannel"
          label="Política comercial"
          fullWidth
          value={values?.salesChannel}
          error={Boolean(errors?.salesChannel) && touched?.salesChannel}
          helperText={errors?.salesChannel && touched?.salesChannel ? errors?.salesChannel : null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
              handleChange(e);
            } else {
              return false;
            }
          }}
          inputProps={{ maxLength: 150 }}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <TextField
          name="affiliateId"
          label="ID do afiliado"
          fullWidth
          value={values?.affiliateId}
          error={Boolean(errors?.affiliateId) && touched?.affiliateId}
          helperText={errors?.affiliateId && touched?.affiliateId ? errors?.affiliateId : null}
          onChange={handleChange}
          inputProps={{ maxLength: 150 }}
          InputProps={{
            endAdornment: (
              <Tooltip
                title="Sugerimos usar RCH, RCL ou RLO"
                placement="top"
                arrow
                style={{ background: '#9E9E9E', minHeight: 25, padding: 8 }}
              >
                <HelpOutlineIcon style={{ color: 'gray' }} />
              </Tooltip>
            ),
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          name="applicationKey"
          label="Access Key"
          fullWidth
          value={values?.applicationKey}
          error={Boolean(errors?.applicationKey) && touched?.applicationKey}
          helperText={errors?.applicationKey && touched?.applicationKey ? errors?.applicationKey : null}
          onChange={handleChange}
          inputProps={{ maxLength: 150 }}
        />
      </Grid>
      <Grid item sm={12}>
        <FormControl fullWidth>
          <TextField
            name="applicationToken"
            label="Token"
            fullWidth
            value={values?.applicationToken}
            error={Boolean(errors?.applicationToken) && touched?.applicationToken}
            helperText={errors?.applicationToken && touched?.applicationToken ? errors?.applicationToken : null}
            onChange={handleChange}
            inputProps={{ maxLength: 150 }}
          />
          <FormHelperText style={{ color: '#4B4B4B', textDecoration: 'underline' }}>
            <span>Dúvidas sobre as informações?</span>
          </FormHelperText>
        </FormControl>
      </Grid>
    </>
  );

  const renderAnymarketFields = () => (
    <Grid item sm={12}>
      <TextField
        name="oi"
        label="Organization ID (OI)"
        fullWidth
        value={values?.oi}
        onChange={handleChange}
        inputProps={{ maxLength: 150 }}
        error={Boolean(errors?.oi) && touched?.oi}
        helperText={errors?.oi && touched?.oi ? errors?.oi : null}
      />
    </Grid>
  );

  const renderWarning = () =>
    errorMessage &&
    errorMessage?.length > 0 && (
      <>
        <Grid item md={12}>
          <WarningLabel message={errorMessage?.[0]?.message} />
        </Grid>
        <Grid item md={12}>
          <span>
            Para maiores informações da integração com VTEX,{' '}
            <LinkHelper href={REACT_APP_VTEX_HELPER_URL} target="_blank">
              clique aqui
            </LinkHelper>{' '}
            ou entre em contato com o{' '}
            <LinkHelperUnderline data-testid="supportButton" onClick={() => onSupportClick()}>
              suporte
            </LinkHelperUnderline>{' '}
            em nossa central de ajuda.
          </span>
        </Grid>
      </>
    );

  const renderIntegrationInfo = () => {
    if (!isVtex && !isAnymarket && values?.integrationPlatformName) {
      return (
        <Grid item md={12}>
          <ModalTextBold>Não encontrei minha integradora na lista, o que faço?</ModalTextBold>
          <ModalText>
            Para consultar maiores informações, acesse nossa Central de Ajuda,
            <LinkHelper href={REACT_APP_RCHLO_PORTAL_DEV} target="_blank">
              {' '}
              clicando aqui
            </LinkHelper>
            .
          </ModalText>
        </Grid>
      );
    }
  };

  return (
    <Grid container spacing={4}>
      {isVtex && renderVtexFields()}
      {isAnymarket && renderAnymarketFields()}
      {isVtex && renderWarning()}
      {renderIntegrationInfo()}
    </Grid>
  );
};

export default IntegrationPlatform;
