import React from 'react';
import { DialogActions, DialogContent, DialogContentText, IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles, WithStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@rchlo/storybook/core/Button';

type Props = {
  open: boolean;
  title: string;
  subtitle: string;
  handleClose?: () => void;
  handleConfirm: () => void;
  disagreeTitle?: string;
  agreeTitle?: string;
  fetching?: boolean;
};

interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose?: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: '15px 0 -8px 18px',
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: '10px',
      top: theme.spacing(2),
      color: '#252525',
    },
  });

const useStyles = makeStyles({
  content: {
    margin: '0 0 10px 0',
    overflow: 'hidden',
  },
  subtitle: {
    color: '#808080',
  },
  actions: {
    padding: '0 25px  26px 0',
  },
});

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography data-testid="titleTypography" component="span" style={{ fontSize: '17px', color: '#252525' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton data-testid="closeButton" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AlertDialog: React.FC<Props> = ({
  open,
  title,
  subtitle,
  handleClose,
  handleConfirm,
  disagreeTitle,
  agreeTitle,
  fetching,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" aria-labelledby="form-dialog-title" open={open} onClose={handleClose}>
        <DialogTitle data-testid="closeButton" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText data-testid="closeButton" className={classes.subtitle}>
            {subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {disagreeTitle && (
            <Button
              data-testid="cancelButton"
              onClick={handleClose}
              label={disagreeTitle || 'Cancelar'}
              borderColor="#ffffff"
              disabled={fetching}
            />
          )}
          <Button
            data-testid="confirmButton"
            onClick={handleConfirm}
            primary
            label={agreeTitle || 'OK'}
            disabled={fetching}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
