import * as yup from 'yup';
import { IFormSchema } from './utils';

const Messages = {
  isRequired: 'Esta informação é obrigatória',
  cpf: 'Dados incompletos',
};

export default (formSchema: IFormSchema): any => {
  let schemaData: any = {};

  Object.entries(formSchema).forEach(([key, value]) => {
    let yupValidation;
    if (key === 'customerDocument') {
      yupValidation = yup
        .string()
        .required(Messages.isRequired)
        .test({
          message: Messages.cpf,
          test: function (value) {
            if (!value) {
              return false;
            }

            const cpf = value.replace(/\D/g, '');

            return cpf.length === 11;
          },
        });
    } else yupValidation = value.required ? yup.string().required(Messages.isRequired) : yup.string();

    schemaData = { ...schemaData, [key]: yupValidation };
  });

  return yup.object().shape(schemaData);
};
