import type { Action } from 'redux';
import { DefaultRequest, ErrorMessages } from '.';
import { IntegrationPlatformOnboarding } from './onboarding';
import { PlatformValueRequest, PlatformValuesRequest } from './users';

export enum Steps {
  SETUP,
  INFO,
  FREIGHT,
  WEBHOOK,
  TUTORIAL,
  FINISH,
}

export interface IPlatform {
  id: string;
  isActive: boolean;
  name: string;
}
export type PlatformValues = {
  [key: string]: string | number | { value?: string };
};

export type PlatformValuesState = {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  updating: boolean;
  values?: PlatformValues;
  integrationPlatformList: IPlatform[];
  onboardFinished: boolean;
  formikInputValues?: IntegrationPlatformOnboarding;
  isSubmitting: boolean;
  integrationDataCompleted: boolean;
  hasNextPage: boolean;
  pageIndex: number;
  selectedPlatform: { id?: string; name: string };
  fetchingSelect: boolean;
  nextStep: Steps;
  isVtexOrAnymarket: boolean;
};

type FetchSellerIntegrationPlatformRequest = {
  sellerId: string;
};

type FetchPlatformValuesOnboardingRequest = {
  sellerId: string;
  integrationPlatformName: string;
};

export type PlatformValuesActions<T> = Action<string> & {
  payload: T;
};

type IntegrationPlatformOnboardingRequest = {
  sellerId: string;
  integrationPlatformValues: IntegrationPlatformOnboarding;
  integrationPlatformName: string;
};

export type IntegrationPlatformOnboardingAction = PlatformValuesActions<IntegrationPlatformOnboardingRequest>;

export type FetchPlatformValuesAction = PlatformValuesActions<PlatformValuesRequest>;

export type UpdatePlatformValueAction = PlatformValuesActions<PlatformValueRequest>;

export type FetchSellerIntegrationPlatformAction = PlatformValuesActions<FetchSellerIntegrationPlatformRequest>;

export type FetchPlatformValuesOnboarding = PlatformValuesActions<FetchPlatformValuesOnboardingRequest>;

export type FetchPlatformList = PlatformValuesActions<DefaultRequest>;
