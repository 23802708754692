/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, InputAdornment, Snackbar, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Alert } from '@material-ui/lab';
import PasswordFeedback from '../../shared/components/PasswordFeedback/PasswordFeedback';
import useStyles from './ChangePassword.styles';

const { REACT_APP_API_BASEPATH_URL, REACT_APP_USER_MANAGEMENT_API_URL } = process.env;

interface Rule {
  text: string;
  hasError: boolean;
  hasSuccess: boolean;
}

const rulesDefault: Rule[] = [
  {
    text: 'A senha precisa ter pelo menos uma letra minúscula.',
    hasError: false,
    hasSuccess: false,
  },
  {
    text: 'A senha precisa ter pelo menos uma letra maiúscula.',
    hasError: false,
    hasSuccess: false,
  },
  {
    text: 'A senha precisa ter pelo menos um número.',
    hasError: false,
    hasSuccess: false,
  },
  {
    text: 'A senha precisa ter pelo menos um caractere especial.',
    hasError: false,
    hasSuccess: false,
  },
  {
    text: 'A senha precisa ter pelo menos 8 caracteres.',
    hasError: false,
    hasSuccess: false,
  },
];

const ChangePassword = () => {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [rules, setRules] = useState(rulesDefault);
  const [hasMatchPasswordError, setHasMatchPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>({});
  const token = window?.location?.search?.substring(1);

  const validatePassword = (passwordValue?: string, repeatPasswordValue?: string) => {
    const updatedRules = rules.map((rule) => ({ ...rule }));
    const passwordUpdated = passwordValue || password;
    const repeatPasswordUpdated = repeatPasswordValue || repeatPassword;

    if (passwordUpdated !== repeatPasswordUpdated) {
      setHasMatchPasswordError(true);
      return;
    }

    setHasMatchPasswordError(false);

    const validations = [
      { regex: /[a-z]/, ruleIndex: 0 },
      { regex: /[A-Z]/, ruleIndex: 1 },
      { regex: /\d/, ruleIndex: 2 },
      { regex: /[\W_]/, ruleIndex: 3 },
      { condition: passwordUpdated.length >= 8, ruleIndex: 4 },
    ];

    validations.forEach(({ regex, condition, ruleIndex }) => {
      const isValid = regex ? regex.test(passwordUpdated) : condition;
      updatedRules[ruleIndex].hasError = !isValid;
      updatedRules[ruleIndex].hasSuccess = isValid;
    });

    setRules(updatedRules);
    return updatedRules.some((rule) => rule.hasError) ? updatedRules : [];
  };

  function onChangePassword(value: string) {
    validatePassword(value);
    setPassword(value);
  }

  function onChangeRepeatPassword(value: string) {
    validatePassword('', value);
    setRepeatPassword(value);
  }

  const onSubmit = async () => {
    const validateResponse = validatePassword();
    if (!validateResponse) {
      return;
    }
    if (password !== repeatPassword) {
      return;
    }
    try {
      const payload: any = {
        tokenCrypt: token,
        value: password,
      };

      const responseService = await fetch(
        `${REACT_APP_API_BASEPATH_URL}/${REACT_APP_USER_MANAGEMENT_API_URL}/auth/portal-seller/reset-password`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );
      if (responseService.status !== 200) {
        setError({ error: true, message: 'Ocorreu um erro ao trocar a senha! Tente novamente' });
      }
      if (responseService.status === 200) {
        setError({ error: false, message: 'Senha trocada com sucesso! Aguarde, redirecionando para tela de login...' });
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      }
    } catch (error) {
      setError({ error: true, message: 'Ocorreu um erro ao trocar a senha! Tente novamente' });
      throw error;
    }
  };

  const validateToken = async () => {
    try {
      const payload: any = {
        tokenCrypt: token,
      };
      const formBody = Object.keys(payload)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]))
        .join('&');
      const responseService = await fetch(
        `${REACT_APP_API_BASEPATH_URL}/${REACT_APP_USER_MANAGEMENT_API_URL}/auth/portal-seller/check-token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        },
      );
      if (responseService.status !== 200) {
        setError({ error: true, message: 'Token expirado, solicite novamente a recuperação da senha!' });
      }
    } catch (error) {
      console.log('Falha na recuperação:');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  if (isLoading) {
    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <CircularProgress color="primary" />
        </Box>
      </>
    );
  }

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={error?.message} autoHideDuration={2000}>
        <Alert severity={error.error ? 'error' : 'success'}>{error.message}</Alert>
      </Snackbar>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.logoContainer}>
            <img
              src="/static/media/img/logo-riachuelo-portal-do-seller.png"
              width="240px"
              height="18px"
              alt="Riachuelo Marketplace"
            />
          </Box>
        </Box>
        <Box className={classes.content}>
          <p className={classes.title}>Criar senha</p>
          <span className={classes.subtitle}>Crie sua nova senha.</span>
          <TextField
            label="Senha"
            variant="outlined"
            value={password}
            onChange={(e) => onChangePassword(e.target.value)}
            error={hasMatchPasswordError}
            helperText={hasMatchPasswordError ? 'As senhas não são iguais.' : null}
            className={classes.textFieldMargin}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classes.cursorPointer}
                  onClick={() => setShowPassword(!showPassword)}
                  position="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirme sua senha"
            variant="outlined"
            value={repeatPassword}
            onChange={(e) => onChangeRepeatPassword(e.target.value)}
            error={hasMatchPasswordError}
            helperText={hasMatchPasswordError ? 'As senhas não são iguais.' : null}
            className={classes.textFieldMargin}
            type={showRepeatPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classes.cursorPointer}
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  position="end"
                >
                  {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              ),
            }}
          />
          <PasswordFeedback rules={rules} hasMatchPasswordError={hasMatchPasswordError} />
          <Button className={classes.textFieldMargin} variant="contained" color="primary" onClick={() => onSubmit()}>
            Alterar senha
          </Button>
        </Box>
      </Box>
      {/* <Box className={classes.footer}>
        <Box>
          <span>Riachuelo © 2024</span>
        </Box>
        <Box>
          <img src="/static/media/img/rchlo.png" width="56px" height="12px" alt="Riachuelo Marketplace" />
        </Box>
      </Box> */}
    </>
  );
};

export default ChangePassword;
