import axios from 'axios';
import { Reducer } from 'react';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { DataResponse, ErrorMessages, IError, Pagination } from '../../../@types';
import { BusinessParamsResponse } from '../../../@types/businessParams';
import type {
  Contract,
  SellerActionsTypes,
  SellerBusinessParamsAction,
  AnticipationRequest,
  AnticipationResponse,
  ContractResponse,
  StringResponse,
  AnticipationValueResponse,
  SellerResponse,
  AcceptedTermsResponse,
  AllContractResponse,
} from '../../../@types/seller';
import { api } from '../../../services/api';

const {
  REACT_APP_BLOB_CONTRACTS_URL,
  REACT_APP_SELLER_API_URL,
  REACT_APP_BUSINESS_PARAMS_API_URL,
  REACT_APP_BLOB_BASE_CONTRACTS_URL_PRD,
} = process.env;

export const MAIN_CONTRACT = 'GeneralConditionGuidelines';
export const AFTERMARKET_CONTRACT = 'AttendancePolicyAftermarket';
export const SLA_CONTRACT = 'ServiceLevelAgreement';
export const PROHIBITED_PRODUCTS_CONTRACT = 'UnacceptableProhibitedProducts';

export interface SellerState extends Pagination<Contract> {
  acceptanceTerm?: Array<Contract>;
  fetching: boolean;
  errorMessages: ErrorMessages;
  contracts?: Contract[];
  unregisteredContract?: boolean;
  contractCheckEnded?: boolean;
  generalConditions?: string;
  afterSales?: string;
  serviceLevelSla?: string;
  prohibitedMarketing?: string;
  featureToggleOptin?: boolean;
  seller_id?: string;
  zendeskOrganizationId?: string;
  anticipationCommission: AnticipationResponse | null;
  anticipationContract?: string;
  success: boolean;
}

const initialState: SellerState = {
  errorMessages: [],
  fetching: false,
  items: [],
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  generalConditions: '',
  featureToggleOptin: false,
  unregisteredContract: false,
  anticipationCommission: null,
  anticipationContract: '',
  success: false,
};

const sellerReducer: Reducer<SellerState, SellerActionsTypes> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@seller/CLEAN':
      return {
        ...state,
        fetching: false,
        success: false,
      };
    case '@seller/FETCH':
    case '@seller/FETCH_BUSINESS_PARAM':
    case '@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@seller/FETCH_SUCCESS':
    case '@seller/FETCH_ALL_CONTRACTS_SUCCESS':
    case '@seller/UPDATE_CONTRACT_SUCCESS':
    case '@seller/FETCH_GENERAL_CONDITIONS_SUCCESS':
    case '@seller/FETCH_AFTER_SALES_SUCCESS':
    case '@seller/FETCH_SERVICE_LEVEL_SLA_SUCCESS':
    case '@seller/FETCH_PROHIBITED_MARKETING_SUCCESS':
    case '@seller/FETCH_ANTICIPATION_CONTRACT_SUCCESS':
    case '@seller/UPDATE_SELLER_ACCEPT_TERMS_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: true,
      };
    case '@seller/FETCH_ERROR':
    case '@seller/UPDATE_SELLER_ACCEPT_TERMS_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: false,
      };
    case '@seller/FETCH_BUSINESS_PARAM_SUCCESS':
    case '@seller/FETCH_BUSINESS_PARAM_ERROR':
    case '@seller/FETCH_ANTICIPATION_VALUE_SUCCESS':
    case '@seller/FETCH_ANTICIPATION_VALUE_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS_SUCCESS':
    case '@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        contractCheckEnded: true,
      };
    case '@seller/FETCH_DATA_INIT':
      return {
        ...state,
        fetching: true,
      };
    case '@seller/UPDATE_CONTRACT_ERROR':
    case '@seller/FETCH_ALL_CONTRACTS_ERROR':
    case '@seller/FETCH_DATA_ERROR':
    case '@seller/FETCH_ANTICIPATION_CONTRACT_ERROR':
    case '@seller/FETCH_GENERAL_CONDITIONS_ERROR':
    case '@seller/FETCH_AFTER_SALES_ERROR':
    case '@seller/FETCH_SERVICE_LEVEL_SLA_ERROR':
    case '@seller/FETCH_PROHIBITED_MARKETING_ERROR': {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};

function* fetchSeller({ payload: { seller_id } }: SellerActionsTypes) {
  try {
    if (seller_id?.endsWith('-lite')) {
      yield put({
        type: '@seller/FETCH_SUCCESS',
        payload: {
          integrationPlatformId: process.env.REACT_APP_CUSTOM,
          success: true,
        },
      });
    } else {
      const response: SellerResponse = yield call(api.get, `${REACT_APP_SELLER_API_URL}/sellers/${seller_id}`);

      yield put({
        type: '@seller/FETCH_SUCCESS',
        payload: {
          success: true,
          ...response.data.information,
        },
      });
    }
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@seller/FETCH_ERROR',
      payload: e.message && JSON.parse(e.message),
    });
  }
}

function* fetchAllContracts({ payload: { seller_id } }: SellerActionsTypes) {
  yield put({ type: '@seller/FETCH_DATA_INIT' });
  const { brands } = yield select();
  try {
    const path = `${REACT_APP_SELLER_API_URL}/sellers/${seller_id}/contracts`;

    const config = {
      params: {
        'page-index': 0,
        'page-size': 40,
      },
    };

    const response: AllContractResponse = yield call(api.get, path, config);

    if (response) {
      const items = response.data.items?.filter(
        (item: any) =>
          item.type !== 'Anticipation' ||
          (item.type === 'Anticipation' && item.acceptedDate) ||
          (item.type === 'Anticipation' && item.responsible === brands?.email),
      );

      yield put({
        type: '@seller/FETCH_ALL_CONTRACTS_SUCCESS',
        payload: { items },
      });
    }
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@seller/FETCH_ALL_CONTRACTS_ERROR',
      payload: e.message && JSON.parse(e.message),
    });
  }
}

function* fetchGeneralConditions() {
  const path = `${REACT_APP_BLOB_CONTRACTS_URL}/condicoes_gerais.html`;

  try {
    // Aqui precisamos usar o axios para não enviar o access_token do usuário para o blob;

    const response: StringResponse = yield call(axios.get, path);

    if (response) {
      yield put({
        type: '@seller/FETCH_GENERAL_CONDITIONS_SUCCESS',
        payload: { generalConditions: response.data },
      });
    }
  } catch (error) {
    yield put({
      type: '@seller/FETCH_GENERAL_CONDITIONS_ERROR',
    });
  }
}

function* fetchAfterSales() {
  const path = `${REACT_APP_BLOB_CONTRACTS_URL}/politica_pos_venda.html`;
  try {
    const response: DataResponse<{}> = yield call(axios.get, path);
    if (response) {
      yield put({ type: '@seller/FETCH_AFTER_SALES_SUCCESS', payload: { afterSales: response.data } });
    }
  } catch (error) {
    yield put({ type: '@seller/FETCH_AFTER_SALES_ERROR' });
  }
}

function* fetchServiceLevelSla() {
  const path = `${REACT_APP_BLOB_CONTRACTS_URL}/sla.html`;
  try {
    const response: DataResponse<{}> = yield call(axios.get, path);
    if (response) {
      yield put({ type: '@seller/FETCH_SERVICE_LEVEL_SLA_SUCCESS', payload: { serviceLevelSla: response.data } });
    }
  } catch (error) {
    yield put({ type: '@seller/FETCH_SERVICE_LEVEL_SLA_ERROR' });
  }
}

function* fetchProhibitedMarketing() {
  const path = `${REACT_APP_BLOB_CONTRACTS_URL}/produtos_proibidos.html`;
  try {
    const response: DataResponse<{}> = yield call(axios.get, path);
    if (response) {
      yield put({
        type: '@seller/FETCH_PROHIBITED_MARKETING_SUCCESS',
        payload: { prohibitedMarketing: response.data },
      });
    }
  } catch (error) {
    yield put({ type: '@seller/FETCH_PROHIBITED_MARKETING_ERROR' });
  }
}

function* checkHasAcceptedTerms({ payload: { seller_id } }: SellerActionsTypes) {
  const path = `${REACT_APP_SELLER_API_URL}/sellers/${seller_id}/contracts`;

  try {
    const params = {
      'page-index': 0,
      'page-size': 20,
    };

    const response: AcceptedTermsResponse = yield call(api.get, path, { params });

    if (response) {
      const acceptanceTerm =
        response.data?.items?.filter(({ type }: Contract) => {
          return (
            type === MAIN_CONTRACT ||
            type === AFTERMARKET_CONTRACT ||
            type === SLA_CONTRACT ||
            type === PROHIBITED_PRODUCTS_CONTRACT
          );
        }) || [];

      yield put({
        type: '@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS_SUCCESS',
        payload: { acceptanceTerm },
      });
    }
  } catch (error) {
    const e = error as IError;

    const msgUnregisteredContract = 'Operation not effected. Contract is not registered.';
    const errorMessages = e.data?.errorMessages;

    if (errorMessages) {
      type ErrorMessage = {
        message: string;
      };

      const [unregisteredContract] = errorMessages.filter((e: ErrorMessage) => e.message === msgUnregisteredContract);

      yield put({
        type: '@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS_ERROR',
        payload: {
          unregisteredContract: !!unregisteredContract,
        },
      });
    }
  }
}

function* updateSellerAcceptTerms({ payload: { seller_id, contracts } }: SellerActionsTypes) {
  yield put({ type: '@seller/FETCH_DATA_INIT' });

  const v1_rchlo = `${REACT_APP_SELLER_API_URL}/sellers/${seller_id}/contracts`;

  try {
    const acceptanceTerms = [...contracts];
    const acceptanceTermIndex = acceptanceTerms.findIndex((term) => term.type === MAIN_CONTRACT);
    if (acceptanceTermIndex >= 0) {
      const [term] = acceptanceTerms.splice(acceptanceTermIndex, 1);
      const response: DataResponse<{ items: [] }> = yield call(api.put, `${v1_rchlo}/${term.id}`);
      acceptanceTerms.forEach((item) => {
        api.put(`${v1_rchlo}/${item.id}`);
      });

      const acceptanceTerm = response?.data ? [response?.data] : [];

      yield put({
        type: response?.data?.items
          ? '@seller/UPDATE_SELLER_ACCEPT_TERMS_SUCCESS'
          : '@seller/UPDATE_SELLER_ACCEPT_TERMS_ERROR',
        payload: { acceptanceTerm },
      });
    } else yield put({ type: '@seller/UPDATE_SELLER_ACCEPT_TERMS_ERROR' });
  } catch (error) {
    yield put({ type: '@seller/UPDATE_SELLER_ACCEPT_TERMS_ERROR' });
  }
}

function* fetchBusinessParamsOptin({ payload: { ruleId, sellerId } }: SellerBusinessParamsAction) {
  try {
    const response: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/${ruleId}`,
    );
    let fetching = false;
    const defaultValue = response.data.defaultValue === 'true';
    let featureToggleOptin = response.data.isActive ? defaultValue : false;

    if (featureToggleOptin && sellerId) {
      yield put({ type: '@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS', payload: { seller_id: sellerId } });
      fetching = true;
    } else if (featureToggleOptin && !sellerId) {
      featureToggleOptin = false;
    }

    yield put({
      type: '@seller/FETCH_BUSINESS_PARAM_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        featureToggleOptin,
        fetching,
      },
    });
  } catch (error) {
    yield put({
      type: '@seller/FETCH_BUSINESS_PARAM_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* fetchAnticipationValue({ payload: { sellerId } }: AnticipationRequest) {
  try {
    const path = `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/antecipation`;
    const response: AnticipationValueResponse = yield call(api.get, path);
    yield put({
      type: '@seller/FETCH_ANTICIPATION_VALUE_SUCCESS',
      payload: {
        anticipationCommission: response.data,
      },
    });
  } catch (error) {
    const e = error as IError;

    if (e?.status === 404) {
      yield put({
        type: '@seller/FETCH_ANTICIPATION_VALUE_ERROR',
        payload: { anticipationCommission: { isActive: false } },
      });
    } else {
      yield put({
        type: '@seller/FETCH_ANTICIPATION_VALUE_ERROR',
        payload: { anticipationCommission: null },
      });
    }
  }
}

function* fetchAnticipationContract() {
  const path = `${REACT_APP_BLOB_BASE_CONTRACTS_URL_PRD}/seller-contracts/antecipacao.html`;

  try {
    const response: StringResponse = yield call(axios.get, path);

    if (response) {
      yield put({
        type: '@seller/FETCH_ANTICIPATION_CONTRACT_SUCCESS',
        payload: { anticipationContract: response.data },
      });
    }
  } catch (error) {
    yield put({
      type: '@seller/FETCH_ANTICIPATION_CONTRACT_ERROR',
    });
  }
}

function* updateSellerContract({ payload: { sellerId, contractId } }: SellerActionsTypes) {
  const v1_rchlo = `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/contracts`;
  const { seller } = yield select();
  const contractsUpdated = seller?.items.filter((item: any) => item?.id !== contractId);

  try {
    const response: ContractResponse = yield call(api.put, `${v1_rchlo}/${contractId}`);

    yield put({
      type: '@seller/UPDATE_CONTRACT_SUCCESS',
      payload: { success: true },
    });
    yield put({
      type: '@seller/FETCH_ALL_CONTRACTS_SUCCESS',
      payload: { items: [...contractsUpdated, response?.data] },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Aceite realizado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({ type: '@seller/UPDATE_CONTRACT_ERROR' });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* sellerSaga() {
  yield takeLatest('@seller/FETCH', fetchSeller);
  yield takeLatest('@seller/FETCH_ALL_CONTRACTS', fetchAllContracts);
  yield takeLatest('@seller/UPDATE_ACCEPTANCE_TERM', updateSellerAcceptTerms);
  yield takeLatest('@seller/CHECK_SELLER_HAS_ACCEPTED_TERMS', checkHasAcceptedTerms);
  yield takeLatest('@seller/FETCH_GENERAL_CONDITIONS', fetchGeneralConditions);
  yield takeLatest('@seller/FETCH_AFTER_SALES', fetchAfterSales);
  yield takeLatest('@seller/FETCH_SERVICE_LEVEL_SLA', fetchServiceLevelSla);
  yield takeLatest('@seller/FETCH_PROHIBITED_MARKETING', fetchProhibitedMarketing);
  yield takeLatest('@seller/FETCH_BUSINESS_PARAM', fetchBusinessParamsOptin);
  yield takeLatest('@seller/FETCH_ANTICIPATION_VALUE', fetchAnticipationValue);
  yield takeLatest('@seller/FETCH_ANTICIPATION_CONTRACT', fetchAnticipationContract);
  yield takeLatest('@seller/UPDATE_CONTRACT', updateSellerContract);
}

export { sellerReducer, sellerSaga };
