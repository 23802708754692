import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ModalTitle, ModalText, ModalTextBold } from './fontStyles';

export interface Props {
  open: boolean;
  setOpen?: Function;
  sellerId: string;
  integrationPlatformName: string;
}

const FinishOnboardingModal: React.FC<Props> = ({ open, integrationPlatformName }) => {
  const dispatch = useDispatch();

  const finishIntegration = () => {
    dispatch({
      type: '@onboarding/ONBOARDING_HAS_FINISHED',
      payload: {
        hasSetupCompleted: true,
        hasVtexFirstLoadTriggered: true,
      },
    });

    if (integrationPlatformName === 'VTEX') {
      sessionStorage.setItem('mktp_integration_notification', 'true');
      dispatch({ type: '@notifications/ADD_NOTIFICATION' });
    }
  };

  const renderMessage = () => {
    if (integrationPlatformName === 'VTEX') {
      return (
        <ModalText>
          Nas próximas horas o sistema irá realizar a integração do catálogo,{' '}
          <ModalTextBold>fique atento às notificações e boas vendas.</ModalTextBold>
        </ModalText>
      );
    }
    if (integrationPlatformName.toUpperCase() === 'ANYMARKET') {
      return (
        <ModalText>
          <ModalTextBold>Para completar a integração do catálogo, </ModalTextBold>
          acesse o painel Anymarket e realize os passos indicados.
        </ModalText>
      );
    }

    return null;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} aria-labelledby="form-dialog-title">
      <DialogContent>
        <ModalTitle>Configuração realizada com sucesso!</ModalTitle>
        <CheckCircleIcon style={{ color: 'green', fontSize: 22, verticalAlign: 'sub' }} />
      </DialogContent>
      <DialogContent>{renderMessage()}</DialogContent>
      <DialogActions
        style={{
          padding: '20px',
        }}
      >
        <Button
          data-testid={`confirmModalCancelButton`}
          onClick={() => finishIntegration()}
          variant="contained"
          color="primary"
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishOnboardingModal;
