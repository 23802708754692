import { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IError } from '../../../@types';
import { ListBrandsResponse } from '../../../@types/brands';
import { api } from '../../../services/api';

const { REACT_APP_REPORTS_URL } = process.env;

const initialState: any = {
  fetching: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  fetchReasons: true,
};

const actionCenterReducer: Reducer<any, any> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@actionCenter/FETCH_DOWNLOADS':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@actionCenter/FETCH_DOWNLOADS_LIST_SUCCESS':
    case '@actionCenter/FETCH_DOWNLOADS_LIST_ERROR':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

function* fetchDownloads({ payload: { pageIndex = 0, pageSize = 20, sellerId } }: any) {
  try {
    yield put({
      type: '@actionCenter/FETCH_DOWNLOADS',
    });
    const payload: ListBrandsResponse = yield call(api.get, `${REACT_APP_REPORTS_URL}/reports`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
        sellerId,
      },
    });

    yield put({
      type: '@actionCenter/FETCH_DOWNLOADS_LIST_SUCCESS',
      payload: {
        success: payload.success,
        fetching: false,
        ...payload.data,
      },
    });
  } catch (error) {
    const e = error as IError;
    let message = 'Não existem downloads para listar.';
    if (e.status === 404) {
      const [errorMessage] = e.data.errorMessages;
      yield put({
        type: '@actionCenter/FETCH_DOWNLOADS_LIST_ERROR',
        payload: {
          ...initialState,
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: message,
            },
          ],
        },
      });
    } else {
      message = 'Ocorreu um erro inesperado, por favor tente novamente novamente.';
      yield put({
        type: '@actionCenter/FETCH_DOWNLOADS_LIST_ERROR',
        payload: {
          ...initialState,
          ...e.data,
        },
      });
    }
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Ocorreu um erro ao buscar as marcas`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchDownloadFile({ payload: { contextType, requestedReportId } }: any) {
  try {
    yield call(
      api.get,
      `${REACT_APP_REPORTS_URL}/report-managements/requested-report-id/${contextType}/${requestedReportId}`,
    );
    yield put({
      type: '@actionCenter/FETCH_DOWNLOAD_FILE_SUCCESS',
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Solicitação realizada com sucesso, verifique o download no seus arquivos.',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    const message = 'Ocorreu um erro inesperado, por favor faça o download novamente.';
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: message,
        type: 'error',
        open: true,
      },
    });
  }
}

function* actionCenterSaga() {
  yield takeLatest('@actionCenter/FETCH_DOWNLOADS_LIST', fetchDownloads);
  yield takeLatest('@actionCenter/FETCH_DOWNLOAD_FILE', fetchDownloadFile);
}

export { actionCenterReducer, actionCenterSaga };
