import type { Action, Reducer } from 'redux';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { DataResponse, DefaultRequest, ErrorMessages, IError, Pagination } from '../../../@types';
import {
  IPortalsProductBase,
  PortalsProductsRequest,
  DisapprovalReasonsRequest,
  ISKU,
  IReason,
} from '../../../@types/products';
import { api } from '../../../services/api';
import isValidFilter from '../../../shared/utils/isValidFilter';

const API_SERVICE = process.env.REACT_APP_CATALOG_API_URL;

export type ProductsState = Pagination<IPortalsProductBase> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  fetchReasons: boolean;
};

export type ProductsAction<T> = Action<string> & {
  payload: T;
};

export type FetchPortalsProductsAction = ProductsAction<PortalsProductsRequest>;

export type FetchChannelsAction = ProductsAction<DefaultRequest>;

export type DisapprovalReasonsAction<T> = Action<string> & {
  payload: T;
};

export type FetchDisapprovalReasonsAction = DisapprovalReasonsAction<DisapprovalReasonsRequest>;

const initialState: ProductsState = {
  fetching: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  fetchReasons: true,
};

const reducer: Reducer<ProductsState, ProductsAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@products/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
        fetchReasons: true,
        pageIndex: 0,
      };

    case '@products/FETCH_SUCCESS':
    case '@products/FETCH_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@skus/DISAPPROVAL_REASONS': {
      return {
        ...payload,
        ...state,
      };
    }
    case '@skus/DISAPPROVAL_REASONS_SUCCESS': {
      return {
        ...payload,
        ...state,
        items: payload.items,
        fetchReasons: false,
      };
    }
    case '@skus/DISAPPROVAL_REASONS_ERROR': {
      return {
        ...payload,
        ...state,
        fetchReasons: false,
      };
    }
    default:
      return state;
  }
};

function* disapprovalReasons({ payload: { ids } }: FetchDisapprovalReasonsAction) {
  try {
    const { data } = yield call(api.get, `${API_SERVICE}/skus/disapproval-reasons?skuMarketplaceIds=${ids.toString()}`);

    const { products } = yield select();

    const items = products.items.map((item: IPortalsProductBase) => {
      return {
        ...item,
        skus: item.skus.map((sku: ISKU) => {
          return {
            ...sku,
            disapprovalReasons: data.find((reason: IReason) => reason.skuMarketplaceId === sku.id)?.disapprovalReasons,
          };
        }),
      };
    });

    yield put({
      type: '@skus/DISAPPROVAL_REASONS_SUCCESS',
      payload: {
        items,
      },
    });
  } catch (err) {
    yield put({
      type: '@skus/DISAPPROVAL_REASONS_ERROR',
    });
  }
}

function* fetchPortalsProducts({
  payload: { pageSize = 20, pageIndex = 0, situation, isActive, search, channel },
}: FetchPortalsProductsAction) {
  const status = isActive === 'true' ? 'Active' : isActive === 'false' ? 'Inactive' : null;

  const isValid = isValidFilter({
    situation,
    isActive,
    search,
  });

  try {
    const response: DataResponse<{ products: {} }> = yield call(api.get, `${API_SERVICE}/portals/products`, {
      params: {
        'page-size': pageSize,
        'page-index': pageIndex,
        situation,
        status,
        search,
        channel,
      },
    });

    yield put({
      type: '@products/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response.data.products,
      },
    });
  } catch (error) {
    const e = error as IError;

    if (e.status === 404) {
      const [errorMessage] = e.data.errorMessages;
      yield put({
        type: '@products/FETCH_ERROR',
        payload: {
          ...initialState,
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: !isValid
                ? 'Não existem produtos integrados para listar.'
                : 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@products/FETCH_ERROR',
        payload: {
          ...initialState,
          ...e.data,
        },
      });
    }
  }
}

function* saga() {
  yield takeLatest('@skus/DISAPPROVAL_REASONS', disapprovalReasons);
  yield takeLatest('@products/FETCH', fetchPortalsProducts);
}

export { reducer as productsReducer, saga as productsSaga };
