import { FormOptions, TicketForm } from '../../../@types/sac';
import formSchema from './formSchema.json';

interface IBase {
  type: string;
  label: string;
  required: boolean;
}

export interface ISelect extends IBase {
  selectLabel: string;
  options: FormOptions[];
}

interface IText extends IBase {
  placeholder?: string;
}

export interface IFormSchema {
  contactReason: ISelect;
  subReason: ISelect;
  subject: IText;
  description: IText;
}

export const getSubReasonOptions = (contactReason: string): FormOptions[] | [] => {
  switch (contactReason) {
    case 'motivo_contato_duvidas_comerciais':
      return [
        { label: 'Alteração contratual / cadastral', value: 'submotivo_alteracao_contratual_cadastral' },
        { label: 'Promoções e marketing', value: 'submotivo_promocoes_e_marketing' },
        { label: 'Rescisão de contrato', value: 'submotivo_rescisao_de_contrato' },
      ];
    case 'motivo_contato_informações_para_seu_cliente':
      return [
        { label: 'Atraso na entrega', value: 'atraso_na_entrega' },
        { label: 'Avaria no produto', value: 'avaria_no_produto' },
        { label: 'Devolução do produto', value: 'cancelamento_do_cliente' },
        { label: 'Cancelamento do pedido', value: 'cancelamento_do_pedido' },
        { label: 'Roubo ou extravio', value: 'extravio_de_carga' },
        { label: 'Insucesso na entrega', value: 'submotivos_info_cliente_insucesso_na_entrega' },
        { label: 'Quebra de estoque', value: 'submotivos_info_cliente_quebra_logistica' },
      ];
    case 'motivo_contato_suporte_técnico':
      return [
        { label: 'Conciliação Financeira / Repasse', value: 'submotivos_falha_de_integração_repasse' },
        { label: 'Estoque', value: 'submotivos_falha_de_integração_estoque' },
        { label: 'Frete', value: 'submotivos_falha_de_integração_frete' },
        { label: 'NFe', value: 'submotivos_falha_de_integração_nfe' },
        { label: 'Pedido', value: 'submotivos_falha_de_integração_pedidos' },
        { label: 'Preço', value: 'submotivos_falha_de_integração_preço' },
        { label: 'Produto', value: 'submotivos_falha_de_integracao_produto' },
        { label: 'Tracking', value: 'submotivos_falha_na_atualização_do_tracking' },
        { label: 'Zendesk', value: 'submotivos_falha_de_integração_zendesk' },
        { label: 'Site Riachuelo', value: 'submotivos_falha_de_integração_geral' },
      ];

    default:
      return [];
  }
};

const getQuantityOptions = (label: string, showMoreOption?: boolean) => {
  const options = {
    type: 'select',
    label,
    selectLabel: 'Selecione a quantidade',
    required: true,
    fullWidth: true,
    options: [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
    ],
  };

  if (showMoreOption) {
    options.options.push({
      label: 'Mais de 5',
      value: '6',
    });
  }

  return options;
};

export const getFormSchema = (values: TicketForm): IFormSchema => {
  const optionsByReason = getSubReasonOptions(values.contactReason);
  let currentFormSchema = formSchema as IFormSchema;
  currentFormSchema.subReason.options = optionsByReason;

  if (values.subReason) {
    let newFields = {};
    switch (values.subReason) {
      case 'submotivo_alteracao_contratual_cadastral':
        newFields = {
          contractOrRecordChange: {
            type: 'text',
            label: 'O que deseja alterar?',
            required: true,
            fullWidth: true,
          },
        };
        break;
      case 'atraso_na_entrega':
      case 'avaria_no_produto':
      case 'cancelamento_do_pedido':
      case 'extravio_de_carga':
        newFields = {
          orderCode: {
            type: 'text',
            label: 'Número do pedido',
            required: true,
          },
          customerDocument: {
            type: 'text',
            label: 'CPF do cliente',
            required: true,
          },
        };
        break;
      case 'cancelamento_do_cliente':
      case 'submotivos_info_cliente_quebra_logistica':
        newFields = {
          orderCode: {
            type: 'text',
            label: 'Número do pedido',
            required: true,
          },
          customerDocument: {
            type: 'text',
            label: 'CPF do cliente',
            required: true,
          },
          skuProductProblem: {
            type: 'text',
            label: 'SKU do produto',
            required: true,
          },
        };
        break;
      case 'submotivos_info_cliente_insucesso_na_entrega':
        newFields = {
          orderCode: {
            type: 'text',
            label: 'Número do pedido',
            required: true,
          },
          customerDocument: {
            type: 'text',
            label: 'CPF do cliente',
            required: true,
          },
          orderReturnedReason: {
            type: 'select',
            label: 'Motivo de pedido devolvido',
            selectLabel: 'Selecione o motivo',
            required: true,
            options: [
              {
                label: 'Endereço inválido',
                value: 'mot_dev_endereço_invalido',
              },
              {
                label: 'Não havia responsável pelo recebimento do pedido',
                value: 'mot_dev_nao_havia_responsavel_pelo_recebimento_do_pedido',
              },
              {
                label: 'Recusa',
                value: 'mot_dev_recusa',
              },
              {
                label: 'Outro',
                value: 'mot_dev_outro',
              },
            ],
          },
          NFe: {
            type: 'text',
            label: 'NF',
            required: true,
          },
        };
        break;
      case 'submotivos_falha_de_integração_estoque':
      case 'submotivos_falha_de_integração_preço':
      case 'submotivos_falha_de_integracao_produto':
        newFields = {
          quantity: getQuantityOptions('Quantidade de produtos'),
        };
        break;
      case 'submotivos_falha_de_integração_frete':
        newFields = {
          quantity: getQuantityOptions('Quantidade de transportadoras', true),
        };
        break;
      case 'submotivos_falha_de_integração_nfe':
      case 'submotivos_falha_de_integração_pedidos':
      case 'submotivos_falha_na_atualização_do_tracking':
        newFields = {
          quantity: getQuantityOptions('Quantidade de pedidos', true),
        };
        break;
    }

    if (values.quantity) {
      let label = '';
      let partialKey = '';

      switch (values.subReason) {
        case 'submotivos_falha_de_integração_estoque':
        case 'submotivos_falha_de_integração_preço':
        case 'submotivos_falha_de_integracao_produto':
          label = 'SKU do produto';
          partialKey = 'skuSeller';
          break;
        case 'submotivos_falha_de_integração_frete':
          label = +values.quantity < 6 ? 'Transportadora' : 'Transportadoras';
          partialKey = +values.quantity < 6 ? 'carrier' : 'carriersList';
          break;
        case 'submotivos_falha_de_integração_nfe':
        case 'submotivos_falha_de_integração_pedidos':
        case 'submotivos_falha_na_atualização_do_tracking':
          label = 'Número do pedido';
          partialKey = +values.quantity < 6 ? 'orderCode' : 'orderCodesList';
          break;
      }

      if (+values.quantity < 6) {
        [...Array(+values.quantity)].forEach((i, index) => {
          const key = `${partialKey}${index + 1}`;
          newFields = {
            ...newFields,
            [key]: { type: 'text', label: `${label} ${index + 1}`, required: true },
          };
        });
      } else {
        newFields = {
          ...newFields,
          [`${partialKey}`]: { type: 'text', label: `${label}`, required: true },
        };
      }
    }

    const { contactReason, subReason, subject, description } = currentFormSchema;

    currentFormSchema = {
      contactReason,
      subReason,
      ...newFields,
      subject,
      description,
    };
  }

  return currentFormSchema;
};

export const getComplementFormSchema = (values: TicketForm): any => {
  if (!values.contactReason) return formSchema;
  let newFields: any = {};

  if (values.subReason) {
    let nameKey = '';
    let countKey = '';
    let countValue = '';
    switch (values.contactReason) {
      case 'motivo_contato_duvidas_comerciais':
        nameKey = 'comercialSubReason';
        break;
      case 'motivo_contato_informações_para_seu_cliente':
        nameKey = 'sacSubReason';
        break;
      case 'motivo_contato_suporte_técnico':
        nameKey = 'technicalSupportSubReason';
        break;
    }

    newFields = {
      [`${nameKey}`]: values.subReason,
    };

    if (values.quantity) {
      switch (values.subReason) {
        case 'submotivos_falha_de_integração_estoque':
        case 'submotivos_falha_de_integração_preço':
        case 'submotivos_falha_de_integracao_produto':
          countKey = 'skusCount';
          countValue = +values.quantity < 6 ? values.quantity : 'mais_de_5';
          break;
        case 'submotivos_falha_de_integração_frete':
          countKey = 'carriersCount';
          countValue =
            +values.quantity < 6
              ? `quantidade_transportadoras_${values.quantity}`
              : 'quantidade_transportadoras_mais_de_5';
          break;
        case 'submotivos_falha_de_integração_nfe':
        case 'submotivos_falha_de_integração_pedidos':
        case 'submotivos_falha_na_atualização_do_tracking':
          countKey = 'ordersCount';
          countValue = +values.quantity < 6 ? `quantidade_pedidos_${values.quantity}` : 'quantidade_pedidos_mais_de_5';
          break;
      }

      newFields = {
        ...newFields,
        [`${countKey}`]: countValue,
      };
    }
  }

  const result = { ...values, ...newFields };

  const hasCPF = Object.keys(result).find((key) => key === 'customerDocument');
  if (hasCPF) result.customerDocument = result.customerDocument.replace(/[^\w\s]/gi, '');

  return result;
};

export const cpfMask = (value: string | undefined): string | undefined => {
  return value
    ? value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    : value;
};
