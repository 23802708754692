import styled from 'styled-components';
import { Close, ArrowBackIos } from '@material-ui/icons';

const ModalContainerBody = styled.div`
  background: white;
  border-radius: 3px;
  padding: 24px;
  width: 600px;
`;

const ModalHeaderContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const TicketDetailsTitle = styled.span`
  font-size: 17px;
  color: #ff5f00;
`;

const TicketDetailsOpenInfo = styled.span`
  font-size: 9px;
  color: #707070;
  margin-left: 10px;
`;

const BackIcon = styled(ArrowBackIos)`
  height: 15px;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  float: right;
`;

const SubjectText = styled.span`
  color: #3e3e3e;
  font-size: 14px;
  display: inline-block;
  padding: 6px;
`;

const TicketStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
`;

export {
  ModalContainerBody,
  ModalHeaderContainer,
  TicketDetailsTitle,
  TicketDetailsOpenInfo,
  BackIcon,
  CloseIcon,
  SubjectText,
  TicketStatusContainer,
};
