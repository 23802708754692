import type { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorMessages, IError } from '../../../@types';
import { api } from '../../../services/api';
import { ProductsAction } from './products.duck';

const API_SERVICE = process.env.REACT_APP_CATALOG_API_URL;

type Attr = {
  id: string;
  name: string;
  value: string;
  productId: string;
};

type DashboardInfo = {
  sellerId: string;
  publishedQuantity: number;
  reprovedQuantity: number;
  blockedQuantity: number;
  executionDate: string;
};

export type ProductDetailState = {
  brand?: string;
  categorySeller?: string;
  description?: string;
  id?: string;
  productSeller?: string;
  sellerId?: string;
  situation?: string;
  originType?: string;
  attributes: Attr[];
  title?: string;
  errorMessages: ErrorMessages;
  notFoundError?: boolean;
  fetching: boolean;
  success: boolean;
  dashboardInfo?: DashboardInfo;
};

const initialState: ProductDetailState = {
  errorMessages: [],
  fetching: false,
  attributes: [],
  success: false,
};

const reducer: Reducer<ProductDetailState, ProductsAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@product/FETCH':
    case '@product/FETCH_DASHBOARD_INFO':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@product/FETCH_SUCCESS':
    case '@product/FETCH_DASHBOARD_INFO_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: true,
      };
    case '@product/FETCH_ERROR':
    case '@product/FETCH_DASHBOARD_INFO_ERROR':
    case '@product/FETCH_DASHBOARD_ORDERS_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: false,
      };
    default:
      return state;
  }
};

function* fetchProductDetails({ payload: { id } }: ProductsAction<any>) {
  try {
    const { data } = yield call(api.get, `${API_SERVICE}/products/${id}`);

    yield put({
      type: '@product/FETCH_SUCCESS',
      payload: {
        ...data,
        fetching: false,
        success: true,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@product/FETCH_ERROR',
      payload: {
        errorMessages: e.data?.errorMessages,
        fetching: false,
        success: false,
        notFoundError: e.status === 404,
      },
    });
  }
}

function* fetchDashboardInfo() {
  try {
    const { data } = yield call(api.get, `${API_SERVICE}/dashboard/skus`);
    yield put({
      type: '@product/FETCH_DASHBOARD_INFO_SUCCESS',
      payload: {
        dashboardInfo: data,
        success: true,
      },
    });
  } catch {
    yield put({ type: '@product/FETCH_DASHBOARD_ORDERS_ERROR' });
  }
}

function* saga() {
  yield takeLatest('@product/FETCH', fetchProductDetails);
  yield takeLatest('@product/FETCH_DASHBOARD_INFO', fetchDashboardInfo);
}

export { reducer as productReducer, saga as productSaga };
