import { Reducer } from 'react';
import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { store } from '../..';
import { IError } from '../../../@types';
import {
  SACAction,
  SACRequest,
  SACResponse,
  SACState,
  TicketCountResponse,
  TicketInfoResponse,
  TicketInfoRequest,
  SendTicketAttachmentFileAction,
  TicketAttachmentResponse,
  TicketStatusResponse,
  ReputationResponse,
  TicketInformations,
} from '../../../@types/sac';
import { api } from '../../../services/api';

const { REACT_APP_ZENDESK_API_URL, REACT_APP_SELLER_API_URL, REACT_APP_ORDER_SALE_API_URL } = process.env;

const initialState: SACState = {
  fetching: false,
  countFetching: false,
  success: false,
  error: false,
  items: [],
  errorMessages: [],
  pageIndex: 1,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  totalTickets: 0,
  currentTicket: undefined,
  attachments: [],
  ticketId: '',
  statusList: [],
  formData: {
    contactReason: '',
    subReason: '',
    subject: '',
    description: '',
  },
  sellerReputationUrl: '',
  errorsTicketsOrdersSac: [],
};

const sacReducer: Reducer<SACState, SACAction<any>> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@sac/GET_TICKETS':
    case '@sac/GET_TICKET_INFO':
    case '@sac/POST_TICKETS_PAYLOADS':
    case '@sac/GET_TICKETS_STATUS':
    case '@sac/FETCH_REPUTATION':
      return { ...state, fetching: true };
    case '@sac/GET_TICKETS_COUNT':
      return { ...state, countFetching: true };
    case '@sac/GET_TICKETS_SUCCESS':
    case '@sac/GET_TICKETS_ERROR':
    case '@sac/GET_TICKET_INFO_SUCCESS':
    case '@sac/GET_TICKET_INFO_ERROR':
    case '@sac/FETCH_REPUTATION_SUCCESS':
      return { ...state, ...payload, fetching: false };
    case '@sac/GET_TICKETS_STATUS_SUCCESS':
      return { ...state, ...payload };
    case '@sac/GET_TICKETS_COUNT_SUCCESS':
    case '@sac/GET_TICKETS_COUNT_ERROR':
    case '@sac/POST_TICKETS_PAYLOAD_ERROR':
      return { ...state, ...payload, countFetching: false };
    case '@sac/POST_TICKETS_ATTACHMENTS_ERROR':
    case '@sac/FETCH_REPUTATION_ERROR':
      return { ...state, ...payload, success: false };
    case '@sac/POST_TICKETS_ATTACHMENTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        attachments: [...state.attachments, { token: payload.token, fileName: payload.fileName }],
      };
    case '@sac/POST_TICKETS_PAYLOAD_SUCCESS':
      return { ...state, ticketId: payload.ticketId, fetching: false };
    case '@layout/FETCH_SNACKBAR_MESSAGE':
      return {
        ...state,
        fetching: false,
      };
    case '@sac/UPDATE_FORM_DATA': {
      if (payload.name === 'contactReason') {
        return {
          ...state,
          formData: { ...initialState.formData, [payload.name]: payload.value },
        };
      } else if (payload.name === 'subReason') {
        return {
          ...state,
          formData: {
            ...initialState.formData,
            contactReason: state.formData.contactReason,
            [payload.name]: payload.value,
          },
        };
      }
      return {
        ...state,
        formData: { ...state.formData, [payload.name]: payload.value },
      };
    }
    case '@sac/CLEAN_FORM_DATA': {
      return {
        ...state,
        formData: { ...initialState.formData },
      };
    }
    case '@sac/CLEAR_CURRENT_TICKET':
      return {
        ...state,
        ticketId: '',
        currentTicket: undefined,
      };
    case '@sac/ERROR_ORDER_NOT_FOUND':
      return {
        ...state,
        errorsTicketsOrdersSac: [...state.errorsTicketsOrdersSac, [payload.errorsTicketsOrdersSac]],
      };
    case '@sac/CLEAR_VALIDATE':
      return {
        ...state,
        errorsTicketsOrdersSac: [],
      };
    case '@sac/CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};

function* fetchTicketsStatus() {
  try {
    const response: TicketStatusResponse = yield call(api.get, `${REACT_APP_ZENDESK_API_URL}/tickets/status`);

    yield put({
      type: '@sac/GET_TICKETS_STATUS_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        statusList: response.data.status,
      },
    });
  } catch (error) {
    yield put({
      type: '@sac/GET_TICKETS_STATUS_ERROR',
    });
  }
}

function* fetchTickets({
  payload: { zendeskOrganizationId, pageIndex, pageSize = 12, startDate, endDate, myTickets, type, search, status },
}: SACRequest) {
  try {
    const response: SACResponse = yield call(
      api.get,
      `${REACT_APP_ZENDESK_API_URL}/organizations/${zendeskOrganizationId}/tickets`,
      {
        params: {
          'page-index': pageIndex,
          'page-size': pageSize,
          'start-date': startDate,
          'end-date': endDate,
          'my-tickets': myTickets,
          type,
          search,
          status,
        },
      },
    );

    yield put({
      type: '@sac/GET_TICKETS_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response.data,
      },
    });
  } catch (error) {
    yield put({
      type: '@sac/GET_TICKETS_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* fetchTicketsCount({ payload: { zendeskOrganizationId } }: SACAction<{ zendeskOrganizationId: string }>) {
  try {
    const response: TicketCountResponse = yield call(
      api.get,
      `${REACT_APP_ZENDESK_API_URL}/organizations/${zendeskOrganizationId}/tickets/count`,
    );

    yield put({
      type: '@sac/GET_TICKETS_COUNT_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        totalTickets: response.data.count,
      },
    });
  } catch (error) {
    yield put({
      type: '@sac/GET_TICKETS_COUNT_ERROR',
      payload: {
        ...initialState,
        error: true,
      },
    });
  }
}

function* fetchTicketInfo({ payload: { zendeskOrganizationId, ticketId } }: TicketInfoRequest) {
  yield put({ type: '@order/CLEAR' });

  try {
    const response: TicketInfoResponse = yield call(
      api.get,
      `${REACT_APP_ZENDESK_API_URL}/organizations/${zendeskOrganizationId}/tickets/${ticketId}`,
    );

    if (response.data.order) {
      yield put({
        type: '@order/GET_BY_CONTEXT',
        payload: {
          order: response.data.order,
        },
      });
    }
    yield put({
      type: '@sac/GET_TICKET_INFO_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        currentTicket: response.data,
        fetching: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@sac/GET_TICKET_INFO_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* sendTicketsAttachments({ payload }: SendTicketAttachmentFileAction) {
  const formData = new FormData();
  formData.append('File', payload.file);
  formData.append('FileName', payload.file.name);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    transformRequest: (formData: FormData) => formData,
  };

  try {
    const response: TicketAttachmentResponse = yield call(
      api.post,
      `${REACT_APP_ZENDESK_API_URL}/tickets/attachments`,
      formData,
      config,
    );
    if (response.success) {
      yield put({
        type: '@sac/POST_TICKETS_ATTACHMENTS_SUCCESS',
        payload: {
          success: response.success,
          attachments: { token: response.data.token, fileName: response.data.fileName },
          ...response.data,
        },
      });
    } else {
      yield put({
        type: '@sac/POST_TICKETS_ATTACHMENTS_ERROR',
        payload: {
          success: false,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Não foi possível enviar o(s) arquivo(s).`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* sendTicketsPayloads({ payload: { informations, attachments } }: any) {
  try {
    const response: TicketAttachmentResponse = yield call(api.post, `${REACT_APP_ZENDESK_API_URL}/tickets`, {
      ...informations,
      uploads: attachments,
    });
    yield put({
      type: '@sac/POST_TICKETS_PAYLOAD_SUCCESS',
      payload: {
        informations,
        attachments: response.data.token,
        ticketId: response.data,
        ...response.data,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Não foi possível abrir o ticket.`,
        type: 'error',
        open: true,
      },
    });

    yield put({
      type: '@sac/POST_TICKETS_PAYLOAD_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* fetchReputation() {
  try {
    const response: ReputationResponse = yield call(api.get, `${REACT_APP_SELLER_API_URL}/reputation/current`);
    yield put({
      type: '@sac/FETCH_REPUTATION_SUCCESS',
      payload: {
        sellerReputationUrl: response?.data?.reputationFileUrl,
        success: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@sac/FETCH_REPUTATION_ERROR',
    });
  }
}

function* validateMultipleOrders({ payload }: SendTicketAttachmentFileAction) {
  const { orders, informations, attachments } = payload;
  yield all(
    orders.map((order: string) => {
      if (order) {
        return call(fetchOrderForValidate, order);
      }
      return null;
    }),
  );
  yield call(finishValidate, informations, attachments);
}

function* finishValidate(informations: TicketInformations, attachments: File[]) {
  const {
    sac: { errorsTicketsOrdersSac },
  } = store.getState();
  if (errorsTicketsOrdersSac?.length <= 0) {
    yield put({
      type: '@sac/POST_TICKETS_PAYLOADS',
      payload: {
        informations,
        attachments,
      },
    });
  }
}

function* fetchOrderForValidate(orderId: string) {
  try {
    yield call(api.get, `${REACT_APP_ORDER_SALE_API_URL}/context/orders/${orderId}`);
  } catch (error) {
    const e = error as IError;
    if (e.status === 404) {
      yield put({
        type: '@sac/ERROR_ORDER_NOT_FOUND',
        payload: {
          errorsTicketsOrdersSac: orderId,
        },
      });
    }
  }
}

function* sacSaga() {
  yield takeLatest('@sac/GET_TICKETS_STATUS', fetchTicketsStatus);
  yield takeLatest('@sac/GET_TICKETS', fetchTickets);
  yield takeLatest('@sac/GET_TICKETS_COUNT', fetchTicketsCount);
  yield takeLatest('@sac/GET_TICKET_INFO', fetchTicketInfo);
  yield takeEvery('@sac/POST_TICKETS_ATTACHMENTS', sendTicketsAttachments);
  yield takeLatest('@sac/POST_TICKETS_PAYLOADS', sendTicketsPayloads);
  yield takeLatest('@sac/FETCH_REPUTATION', fetchReputation);
  yield takeLatest('@sac/VALIDATE_ORDER', validateMultipleOrders);
}

export { sacReducer, sacSaga };
