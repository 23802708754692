import { Reducer } from 'react';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { store } from '../..';
import { IError } from '../../../@types';
import { CommentAttachmentRequest, TicketTokenResponse, TicketRequest } from '../../../@types/ticket';
import { api } from '../../../services/api';

export interface TicketState {
  tokens: string[];
  fetching?: boolean;
  fetchingTokens?: boolean;
  attachments: File[];
  comment: string;
}

const { REACT_APP_ZENDESK_API_URL } = process.env;

const initialState: TicketState = {
  tokens: [],
  fetching: false,
  fetchingTokens: false,
  attachments: [],
  comment: '',
};

const reducer: Reducer<TicketState, CommentAttachmentRequest | TicketRequest> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@ticket/FETCH_COMMENT_ATTACHMENT':
      return { ...state, fetching: true };
    case '@ticket/FETCH_ATTACHMENTS_TOKEN':
      return { ...state, fetchingTokens: true };
    case '@ticket/FETCH_ATTACHMENTS_TOKEN_SUCCESS':
      return {
        ...state,
        fetchingTokens: false,
        tokens: [...state.tokens, payload.token],
        attachments: [...state.attachments, payload.file],
      };
    case '@ticket/FETCH_COMMENT_ATTACHMENT_SUCCESS':
      return { ...state, attachments: [], comment: '', fetching: false };
    case '@ticket/FETCH_COMMENT_ATTACHMENT_ERROR':
      return { ...state, fetching: false };
    case '@ticket/SET_COMMENT':
      return { ...state, comment: payload.comment };
    default:
      return state;
  }
};

function* fetchAttachmentToken({ payload: { file } }: TicketRequest): TicketTokenResponse {
  const formData = new FormData();
  formData.append('File', file);
  formData.append('FileName', file.name);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    transformRequest: (formData: FormData) => formData,
  };

  try {
    const response = yield call(api.post, `${REACT_APP_ZENDESK_API_URL}/tickets/attachments`, formData, config);
    yield put({
      type: '@ticket/FETCH_ATTACHMENTS_TOKEN_SUCCESS',
      payload: {
        file,
        token: response.data.token,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Houve um problema para fazer upload do arquivo ${file[0].name} no servidor, tente novamente.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* ticketCommentsAttachment({ payload: { ticketId } }: CommentAttachmentRequest) {
  const {
    ticket: { comment, tokens },
    seller: { zendeskOrganizationId },
  } = store.getState();
  try {
    yield call(api.put, `${REACT_APP_ZENDESK_API_URL}/tickets/${ticketId}/comments`, {
      comment,
      attachments: tokens,
    });

    yield put({
      type: '@sac/GET_TICKET_INFO',
      payload: { zendeskOrganizationId, ticketId },
    });

    yield put({
      type: '@ticket/FETCH_COMMENT_ATTACHMENT_SUCCESS',
    });
  } catch (error) {
    const e = error as IError;

    if (e.status === 422) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'O ticket está fechado e não pode ser comentado',
          type: 'error',
          open: true,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Formato recomendado:  CSV, XLS, XLSX ou JPG/PNG . Tamanho máximo suportado: 5MB.',
          type: 'error',
          open: true,
        },
      });
    }
  }
  yield put({
    type: '@ticket/FETCH_COMMENT_ATTACHMENT_ERROR',
  });
}

function* saga() {
  yield takeEvery('@ticket/FETCH_ATTACHMENTS_TOKEN', fetchAttachmentToken);
  yield takeLatest('@ticket/FETCH_COMMENT_ATTACHMENT', ticketCommentsAttachment);
}

export { reducer as ticketReducer, saga as ticketSaga };
