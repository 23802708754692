import moment from 'moment';
import type { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import type {
  FetchTestOrderAction,
  TestOrder,
  TestOrdersAction,
  TestOrdersState,
  UpdateQuantityAction,
  FetchSkuPriceAction,
} from '../../../@types/testOrder';
import { api } from '../../../services/api';
import { store } from '../../index';

const { REACT_APP_CATALOG_API_URL, REACT_APP_ORDER_SALE_API_URL } = process.env;

const initialState: TestOrdersState = {
  fetching: false,
  searching: false,
  success: false,
  selectedSKUs: [],
  errorMessages: [],
  orderCode: '',
};

const reducer: Reducer<TestOrdersState, TestOrdersAction<any | FetchTestOrderAction | UpdateQuantityAction>> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@testOrders/SEARCH_BY_SKU':
      return {
        ...state,
        ...payload,
        success: false,
        searching: true,
      };
    case '@testOrders/CREATE_ORDER_TEST':
      return {
        ...state,
        ...payload,
        success: false,
        fetching: true,
      };
    case '@testOrders/UPDATE_QUANTITY': {
      const selectedSKUs = state.selectedSKUs;
      selectedSKUs[payload.index].quantity = Number(payload.quantity);
      return {
        ...state,
        ...payload,
        selectedSKUs,
      };
    }
    case '@testOrders/SEARCH_BY_SKU_SUCCESS':
      return {
        ...state,
        searching: false,
        selectedSKUs: [payload.newSku, ...state.selectedSKUs],
      };
    case '@testOrders/FEAT_SKU_PRICE_SUCCESS': {
      const index = state.selectedSKUs.findIndex((el) => el.sku === payload.newSku.sku);
      const updateSelectedSKUs = state.selectedSKUs;
      updateSelectedSKUs[index] = payload.newSku;

      return {
        ...state,
        selectedSKUs: updateSelectedSKUs,
      };
    }
    case '@testOrders/SEARCH_BY_SKU_ERROR':
      return {
        ...state,
        ...payload,
        searching: false,
      };
    case '@testOrders/CREATE_TEST_ORDER_ERROR':
    case '@testOrders/CREATE_TEST_ORDER_SUCCESS':
    case '@testOrders/FEAT_SKU_PRICE_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@testOrders/CLEAN': {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

function* searchBySKU({ payload: { skuId } }: FetchTestOrderAction) {
  try {
    const {
      testOrders: { selectedSKUs },
    } = store.getState();

    const findBySKU = selectedSKUs.find((sku: TestOrder) => sku.sku === skuId);
    if (findBySKU) {
      yield put({
        type: '@testOrders/SEARCH_BY_SKU_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `SKU já adicionada`,
          type: 'error',
          open: true,
        },
      });
      return;
    }

    const { data } = yield call(api.get, `${REACT_APP_CATALOG_API_URL}/skus/sku-sellers/${skuId}`);

    const newSku: TestOrder = {
      sku: data.skuSeller,
      quantity: 1,
      unitPrice: 0,
      totalDiscount: 0,
      totalUnities: 99,
      priceFetching: true,
    };

    yield put({
      type: '@testOrders/SEARCH_BY_SKU_SUCCESS',
      payload: {
        newSku,
      },
    });

    yield put({
      type: '@testOrders/FEAT_SKU_PRICE',
      payload: {
        skuMarketplaceId: data.id,
        sku: newSku,
      },
    });
  } catch (error) {
    yield put({
      type: '@testOrders/SEARCH_BY_SKU_ERROR',
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `SKU inválido`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* featSkuPrice({ payload: { skuMarketplaceId, sku } }: FetchSkuPriceAction) {
  try {
    const { data } = yield call(api.get, `${REACT_APP_CATALOG_API_URL}/skus/${skuMarketplaceId}/prices`);

    const newSku: TestOrder = sku;
    newSku.unitPrice = data.priceSale;
    newSku.priceFetching = false;

    yield put({
      type: '@testOrders/FEAT_SKU_PRICE_SUCCESS',
      payload: {
        newSku,
      },
    });
  } catch (error) {
    yield put({
      type: '@testOrders/FEAT_SKU_PRICE_ERROR',
      payload: {
        priceFetching: false,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Valor não registrado`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* postTestOrder() {
  try {
    const {
      testOrders: { selectedSKUs },
      brands: { sellerId },
    } = store.getState();

    const items = selectedSKUs.map((sku: TestOrder) => {
      sku.unitPrice = sku.unitPrice * 100;
      return sku;
    });

    const { data } = yield call(api.post, `${REACT_APP_ORDER_SALE_API_URL}/lite-accounts/orders`, {
      sellerId: sellerId,
      packages: [{ items }],
    });

    yield put({
      type: '@testOrders/CREATE_TEST_ORDER_SUCCESS',
      payload: {
        success: true,
        orderCode: data.orderCode,
      },
    });

    yield put({
      type: '@orders/FETCH',
      payload: {
        pageIndex: 0,
        pageSize: 8,
        StartDate: moment().format('YYYY-MM-DD'),
        EndDate: moment().format('YYYY-MM-DD'),
      },
    });
  } catch (error) {
    yield put({
      type: '@testOrders/CREATE_TEST_ORDER_ERROR',
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Erro ao criar pedido tente novamente.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* saga() {
  yield takeLatest('@testOrders/SEARCH_BY_SKU', searchBySKU);
  yield takeLatest('@testOrders/FEAT_SKU_PRICE', featSkuPrice);
  yield takeLatest('@testOrders/CREATE_ORDER_TEST', postTestOrder);
}

export { reducer as testOrdersReducer, saga as testOrdersSaga };
