import React from 'react';
import { NotificationsOutlined } from '@material-ui/icons';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import * as St from './ModalHeader.styles';
import { ModalTitle, ModalText } from './fontStyles';

interface Props {
  title: string;
  description: string;
  tooltipTitle?: string;
  onPress: Function;
}

const ModalHeader: React.FC<Props> = ({ title, description, tooltipTitle, onPress }) => {
  return (
    <St.Container>
      <St.Row>
        <ModalTitle>{title}</ModalTitle>
        {tooltipTitle && (
          <St.TooltipContainer>
            <St.TooltipTitle title={tooltipTitle} placement="bottom-start" arrow>
              <HelpOutlineOutlinedIcon />
            </St.TooltipTitle>
          </St.TooltipContainer>
        )}
        <St.NotificationButton data-testid="historyTicketsButton" aria-label="historyTickets" onClick={() => onPress()}>
          <NotificationsOutlined style={{ color: 'gray' }} />
        </St.NotificationButton>
      </St.Row>
      <ModalText>{description}</ModalText>
    </St.Container>
  );
};

export default ModalHeader;
