import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@rchlo/storybook/core/Button';
import { BrandsState } from '../../../@types/brands';
import { StyleModal, Container, Header } from '../FirstSteps.styles';
import { Footer, Player } from './Tutorial.styles';

interface Props {
  goOutFirstStep: Function;
}

export default function Tutorial({ goOutFirstStep }: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [started, setStarted] = useState(false);

  const { userId } = useSelector<{ brands: BrandsState }>((state) => state.brands) as BrandsState;

  const url = process.env.REACT_APP_BLOB_FIRST_STEPS as string;

  const title = 'Bem-vindo, confira o tutorial com as funcionalidades do nosso portal';

  const videoStarted = () => setStarted(true);

  const handleClose = () => {
    const firstAccessAt = moment().format();
    if (started) {
      localStorage.setItem('firstStepsStarted', 'true');
      dispatch({
        type: '@firstSteps/STARTED',
        payload: {
          userId,
          firstAccessAt,
        },
      });
    }
    setOpen(false);
    goOutFirstStep();
  };

  return (
    <StyleModal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-first-steps"
      aria-describedby="modal-video-with-first-steps"
    >
      <Container>
        <Header data-testid={`firstStepsTitle-${title}`}>{title}</Header>
        <Player data-testid="firstStepsPlayer" videoId={url} onPlay={() => videoStarted()} />
        <Footer>
          <Button
            data-testid="buttonClose"
            backgroundColor="#525252"
            label="Fechar"
            primary
            textColor="#FFF"
            style={{
              fontWeight: 'normal',
              width: '102px',
              height: '36px',
            }}
            onClick={handleClose}
          />
        </Footer>
      </Container>
    </StyleModal>
  );
}
