import { format } from 'date-fns';
import { ChatItem } from '@rchlo/storybook/core/Chat';
import { Comment, Ticket, TicketStatus } from '../../@types/sac';
import { dispatchGtagEvent } from '../../shared/utils/dispatchGtagEvent';
import { toKebabeCaseGtag } from '../../shared/utils/toKebabCaseGtag';

interface Resp {
  id: string;
  title: string;
  color: string;
  bg: string;
}

interface Section {
  title: string;
  items?: ChatItem[];
}

export enum tagEventActionEnum {
  INTERACTION = 'interacao',
  CLICK = 'clique',
}

export const menuBreadcrumb = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'SAC',
    url: '/',
  },
  {
    label: 'Central de Atendimento',
    url: '/sac/central-de-atendimento',
  },
];

export const getStatus = (ticket: Ticket, statusList: TicketStatus[]): Resp => {
  const data = statusList.find((item: TicketStatus) => item.id.toString() === ticket.customStatusId);

  if (data) return { id: data.id, title: data.name, color: data.color, bg: data.bg };
  return { id: ticket.customStatusId, title: ticket.status, color: '#525252', bg: 'rgba(82, 82, 82, 0.18)' };
};

export const typeInfo = [
  { id: 'order', title: 'Pedido' },
  { id: 'ticket', title: 'Ticket' },
  { id: 'document', title: 'CPF do cliente' },
];

export const getCommentsSection = (data: Comment[]): Section[] => {
  const groups = data.reduce<{
    [key: string]: any;
  }>((groups, comment) => {
    const date = comment.createdAt.split('T')[0];

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(comment);
    return groups;
  }, {});

  return Object.keys(groups).map((date) => {
    return {
      title: format(new Date(groups[date][0].createdAt), 'dd/MM/yyyy'),
      items: groups[date].map((item: Comment) => ({
        data: item,
        isLeft: item.isCorporate,
        date: item.createdAt,
      })),
    };
  });
};

export const GTAG = (action: string, label: string) => {
  dispatchGtagEvent({
    event: 'event',
    eventCategory: 'pds:sac:central-de-atendimento',
    eventAction: action,
    eventLabel: toKebabeCaseGtag(label),
  });
};

export const acceptFileType = [
  '.png',
  'image/png',
  '.jpg',
  'image/jpg',
  '.jpeg',
  'image/jpeg',
  '.pdf',
  'application/pdf',
  '.txt',
  'text/plain',
  '.csv',
  'text/csv',
  '.doc',
  'application/msword',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls',
  'application/vnd.ms-excel',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ods',
  'application/vnd.oasis.opendocument.spreadsheet',
  '.odt',
  'application/vnd.oasis.opendocument.text',
];
