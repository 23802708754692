import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';

export const Container = styled.div`
  height: 80%;
`;

export const StyleModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  color: #ffffff;
`;
