import React from 'react';
import { Grid, TextField as MuiTextField } from '@material-ui/core';
import { ISchema } from '../../../../@types/sac';
import * as St from '../CreateTicketForm.styles';
import { cpfMask } from '../utils';

export interface Props extends ISchema {
  name: string;
  value: string | File[] | undefined;
}

export const TextField = (props: Props): JSX.Element => {
  const { name, fullWidth, ...rest } = props;

  return (
    <Grid item lg={fullWidth ? 12 : 6} sm={fullWidth ? 12 : 6}>
      <MuiTextField {...rest} name={name} data-testid={`input-${name}`} fullWidth />
    </Grid>
  );
};

export const CPFTextField = (props: Props): JSX.Element => {
  const { name, fullWidth, value, ...rest } = props;
  const document = cpfMask(value?.toString());

  return (
    <Grid item lg={fullWidth ? 12 : 6} sm={fullWidth ? 12 : 6}>
      <MuiTextField
        {...rest}
        inputProps={{ maxLength: 14 }}
        name={name}
        data-testid={`input-${name}`}
        value={document}
        fullWidth
      />
    </Grid>
  );
};

export const DescriptionField = (props: Props): JSX.Element => {
  const { name, label, ...rest } = props;

  return (
    <Grid item lg={12} sm={12}>
      <St.TextLabel>{label}</St.TextLabel>
      <MuiTextField
        {...rest}
        name={name}
        data-testid={`input-${name}`}
        type="text"
        fullWidth
        variant="outlined"
        multiline
        rows={15}
      />
    </Grid>
  );
};

export const SelectField = (props: Props): JSX.Element => {
  const classes = St.useStyles();
  const { name, selectLabel, label, options, fullWidth, ...rest } = props;

  return (
    <Grid item lg={fullWidth ? 12 : 6} sm={fullWidth ? 12 : 6}>
      <St.TextLabel>{label}</St.TextLabel>
      <St.StyledSelect
        {...rest}
        label={selectLabel}
        data-testid={`select-${name}`}
        name={name}
        options={options}
        disabled={!options.length}
        inputProps={{ className: classes.input }}
      />
    </Grid>
  );
};
