import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@rchlo/storybook/core/Select';

export const ModalHeader = styled.div`
  flex-direction: 'row';
  display: flex;
`;

export const ModalTitle = styled.span`
  font-size: 17px;
  font-weight: 400px;
`;

export const CloseButton = styled(IconButton)`
  cursor: pointer;
  align-self: center;
  margin-left: auto;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 400px;
`;

export const ClearButton = styled(IconButton)`
  cursor: pointer;
  align-self: center;
  margin-left: auto;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 400px;
`;

export const StyledSelect = styled(Select)`
  background-color: #fff;
  border: 0px !important;
  border-bottom: 1px solid #808080 !important;
  &:focus {
    background-color: #fff !important;
  }
`;

export const TextLabel = styled.label`
  color: #808080;
  padding-bottom: 4px;
  font-size: 11px;
  font-weight: 300;
`;

export const useStyles = makeStyles(() => ({
  input: {
    paddingLeft: 0,
  },
}));

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0 0 0 12px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 15px 0 15px;
`;

export const UpdateText = styled.div`
  margin-left: 5px;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.75;
  color: #fff;
`;

export const FileUpload = styled.input`
  display: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;
