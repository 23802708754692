import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorMessages, IError, Pagination } from '../../../@types';
import {
  FinancialConciliationReport,
  ConciliationReportForm,
  ConciliationReportFetch,
  PaginationConciliationReportResponse,
} from '../../../@types/financial';
import { api } from '../../../services/api';

const { REACT_APP_FINANCIAL_API_URL, REACT_APP_REPORTS_URL } = process.env;

export type FinancialConciliationState = Pagination<FinancialConciliationReport> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  updating: boolean;
  items?: Array<FinancialConciliationReport>;
  requestSentSuccess: boolean;
  isSubmitting: boolean;
};

export type ConciliationReportAction<T> = Action<string> & {
  payload: T;
};

export type RequestConciliationReport = ConciliationReportAction<ConciliationReportForm>;
export type ListConciliationReport = ConciliationReportAction<ConciliationReportFetch>;

const initialState: FinancialConciliationState = {
  fetching: false,
  success: false,
  errorMessages: [],
  updating: false,
  items: [],
  pageSize: 4,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  pageIndex: 0,
  requestSentSuccess: false,
  isSubmitting: false,
};

const conciliationReducer: Reducer<FinancialConciliationState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@conciliation/FETCH':
      return { ...state, ...payload, fetching: true };
    case '@conciliation/FETCH_SUCCESS':
      return { ...state, ...payload };
    case '@conciliation/FETCH_ERROR':
      return { ...state, ...payload };
    case '@conciliation/SEND_REPORT_REQUEST':
      return { ...state, ...payload, isSubmitting: true };
    case '@conciliation/REPORT_REQUEST_SENT_SUCCESS':
      return { ...state, ...payload, isSubmitting: false, requestSentSuccess: true };
    case '@conciliation/RESET_FORM_SUCCES_ALERT':
      return { ...state, requestSentSuccess: false };
    default:
      return state;
  }
};

function* fetchConciliationReport({ payload: { sellerId, pageIndex, pageSize = 8 } }: ListConciliationReport) {
  try {
    const response: PaginationConciliationReportResponse = yield call(
      api.get,
      `${REACT_APP_FINANCIAL_API_URL}/conciliations`,
      {
        params: {
          sellerId,
          pageIndex,
          pageSize,
        },
      },
    );

    yield put({
      type: '@conciliation/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response?.data,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@conciliation/FETCH_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        errorMessages: [
          {
            notFoundHelperText: 'Não existem relatórios para listar',
          },
        ],
      },
    });
  }
}

function* sendRequestConciliationReport({ payload: { hasFtNewConciliation, callback, ...payload } }: any) {
  let message = 'Relatório solicitado com sucesso!';
  let type = 'success';
  try {
    if (hasFtNewConciliation) {
      yield call(api.post, `${REACT_APP_REPORTS_URL}/reports`, payload);
    } else {
      yield call(api.post, `${REACT_APP_FINANCIAL_API_URL}/conciliations`, payload);
    }
    yield put({
      type: '@conciliation/REPORT_REQUEST_SENT_SUCCESS',
    });
    callback && callback('relatorio-gerado-com-sucesso');
  } catch (error) {
    const e = error as IError;

    callback && callback('erro-ao-gerar-o-relatorio:tente-novamente');

    message = 'Ocorreu um erro ao processar sua solicitação!';
    type = 'error';
    yield put({
      type: '@conciliation/FETCH_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        requestSentSuccess: false,
      },
    });
  } finally {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message,
        type,
        open: true,
      },
    });
  }
}

function* conciliationSaga() {
  yield takeLatest('@conciliation/FETCH', fetchConciliationReport);
  yield takeLatest('@conciliation/SEND_REPORT_REQUEST', sendRequestConciliationReport);
}

export { conciliationReducer, conciliationSaga };
