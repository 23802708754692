import styled from 'styled-components';

export const ModalTitle = styled.span`
  font-size: 17px;
  color: #252525;
  margin-bottom: 4px;
`;

export const ModalText = styled.p`
  font-size: 14px;
  color: #808080;
`;

export const ModalTextBold = styled.span`
  font-size: 14px;
  color: #252525;
  font-weight: 500;
`;

export const LinkHelper = styled.a`
  font-size: 14px;
  color: #ed6926;
  font-weight: 500;
`;

export const HelpTextBold = styled.span`
  color: #252525;
  font-size: 14px;
  font-weight: 500;
`;
export const HelpText = styled.span`
  color: #252525;
  font-size: 14px;
`;

export const LinkHelperUnderline = styled.a`
  font-size: 14px;
  color: #ed6926;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const HelperContainer = styled.div`
  margin: 20px 0px;
`;
