import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { Page403 as Page403SB, Title, ErrorPage } from '@rchlo/storybook/core';
import { logoutUser } from '../..';
import useLayout from '../../hooks/useLayout';
import MKTPTheme from '../../shared/theme';
import { Container, Title as StyleTitle, Footer, MainStyled } from './Page403.styles';

const menuBreadcrumb = [
  {
    label: 'Home',
    url: '/',
  },
];

const Page403: React.FC<{ publicMode?: boolean }> = ({ publicMode = false }) => {
  const { Layout, history } = useLayout();
  const Wrapper = !publicMode ? Layout : Fragment;
  return (
    <Wrapper>
      <MainStyled>
        <Page403SB
          direction="column"
          justify="space-around"
          alignItems="stretch"
          publicMode={publicMode}
          headPublicMode={
            <Container>
              <img src="/static/media/img/marca.svg" alt="Riachelo" />
              <StyleTitle>market</StyleTitle>
              <StyleTitle style={{ fontWeight: 'bold' }}>place</StyleTitle>
            </Container>
          }
          headDefault={<Title history={history} title="Erro" breadcrumb={menuBreadcrumb} />}
          content={
            <>
              <Grid item xs={12}>
                <ErrorPage
                  errorMessage={
                    <img width="144px" alt="icone" height="144px" src="/static/media/img/admin_panel_settings.svg" />
                  }
                  title="Acesso não autorizado"
                  configMarginTop={publicMode ? '10px' : '50px'}
                  configMarginBottom="50px"
                  logout={publicMode ? logoutUser : undefined}
                  description={
                    <span style={{ textAlign: 'center' }}>
                      Para maiores informações consulte o <br />
                      administrador da conta.
                    </span>
                  }
                  backgroundIcon={MKTPTheme.palette.primary.main}
                  colorIcon={MKTPTheme.palette.primary.contrastText}
                  backgroundButton={MKTPTheme.palette.primary.main}
                  colorButton={MKTPTheme.palette.primary.contrastText}
                  labelButton={publicMode ? 'Sair' : 'Início'}
                  widthButtonLogout="90px"
                  heightButtonLogout="36px"
                  widthButtonHome="124px"
                  heightButtonHome="36px"
                />
              </Grid>
            </>
          }
        />
      </MainStyled>
      {publicMode && (
        <Footer>
          Riachuelo © {new Date().getFullYear()}
          <img src="/static/media/img/rchlo.svg" alt="Logo RCHLO" />
        </Footer>
      )}
    </Wrapper>
  );
};

export default Page403;
