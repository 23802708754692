import { format } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { DateRange } from '@material-ui/pickers';
import FKingTable from '@rchlo/storybook/core/FKingTable';
import { Columns } from '@rchlo/storybook/core/FKingTable/FKingTable.types';
import { SACState, Ticket, TicketStatus } from '../../../../@types/sac';
import { SellerState } from '../../../../store/ducks/seller';
import { ColumnText, StatusText } from '../../../SAC/CallCenter.styles';
import { GTAG, tagEventActionEnum, getStatus } from '../../../SAC/utils';
import Filter from './Filter';
import TicketDetailsModal from './TicketDetailsModal';
import * as St from './TicketHistoryModal.styles';

export interface Props {
  open: boolean;
  closeModal: Function;
  onSupportClick: Function;
}

const TicketHistoryModal: React.FC<Props> = ({ open, closeModal, onSupportClick }) => {
  const dispatch = useDispatch();
  const [ticketId, setTickedId] = useState('');

  const { seller_id, zendeskOrganizationId } = useSelector<{ seller: SellerState }, SellerState>(
    (state) => state.seller,
  );

  const {
    items,
    fetching,
    pageIndex,
    pageSize,
    hasNextPage,
    hasPreviousPage,
    indexFrom,
    totalCount,
    totalPages,
    errorMessages: [errorMessage],
    statusList,
    currentTicket,
  } = useSelector<{ sac: SACState }>((state) => state.sac) as SACState;

  const columns: Columns = [
    {
      label: 'Ticket',
      key: 'ticket',
      WrapperComponent: ({ ticket }) => (
        <ColumnText
          onClick={() => {
            GTAG(`${tagEventActionEnum.CLICK}:historico-de-tickets:integracao`, `ticket:${ticket}`);
            setTickedId(ticket);
          }}
          data-testid={`ticket`}
          txtColor="#ED6926"
          bold
          style={{ cursor: 'pointer' }}
        >
          {ticket}
        </ColumnText>
      ),
    },
    {
      label: 'Status',
      key: 'status',
      WrapperComponent: (ticket) => {
        const { color, title, bg } = getStatus(ticket as Ticket, statusList);

        return (
          <StatusText data-testid={`status`} txtColor={color} bg={bg}>
            {title}
          </StatusText>
        );
      },
    },
    {
      label: 'Assunto',
      key: 'subject',
      WrapperComponent: ({ subject }) => <ColumnText data-testid={`subject`}>{subject}</ColumnText>,
    },
    {
      label: 'Abertura',
      key: 'openingDate',
      WrapperComponent: ({ openingDate }) => (
        <ColumnText data-testid={`openingDate`}>{format(new Date(openingDate), 'dd/MM/yyyy')}</ColumnText>
      ),
    },
  ];

  const [{ status, pageNumber, selectedDates }, setState] = useState<{
    status?: string;
    pageNumber: number;
    selectedDates: DateRange<string>;
  }>({
    pageNumber: pageIndex,
    selectedDates: [
      moment(new Date().setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
    status: undefined,
  });

  useEffect(() => {
    open && dispatch({ type: '@sac/GET_TICKETS_STATUS' });
  }, [open]);

  useEffect(() => {
    if (ticketId) {
      dispatch({
        type: '@sac/GET_TICKET_INFO',
        payload: { zendeskOrganizationId, ticketId },
      });
    }
  }, [ticketId]);

  useEffect(() => {
    const [startDate, endDate] = selectedDates;

    if (zendeskOrganizationId) {
      dispatch({
        type: '@sac/GET_TICKETS',
        payload: {
          zendeskOrganizationId,
          pageIndex: pageNumber,
          pageSize: 5,
          startDate,
          endDate,
          myTickets: false,
          status,
        },
      });
    } else {
      dispatch({
        type: '@seller/FETCH',
        payload: {
          seller_id,
        },
      });
    }
  }, [dispatch, pageNumber, selectedDates[0], selectedDates[1], history, status]);

  const handleOnPaginationChange = (_?: any, page?: number) => {
    setState((prevState) => ({ ...prevState, pageNumber: page ? page + 1 : 1 }));
  };

  const handleFilterStatusOnChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => void = ({ target: { value } }) => {
    const statusId = value as string;
    const status = statusList.find((item: TicketStatus) => item.id === statusId);
    GTAG(`${tagEventActionEnum.INTERACTION}:filtro:status:integracao`, status?.name || statusId);
    setState((prevState) => ({ ...prevState, status: statusId, pageNumber: 1 }));
  };

  const handleFilterDateOnChange = (date: DateRange<unknown>) => {
    const [startDate, endDate] = date;

    const StartDate = moment(startDate as Date).format('YYYY-MM-DD');
    const EndDate = moment(endDate as Date).format('YYYY-MM-DD');

    setState((prevState) => ({ ...prevState, selectedDates: [StartDate, EndDate], pageNumber: 1 }));
  };

  return (
    <St.Container>
      {!currentTicket?.ticket && (
        <FKingTable
          style={{ width: '680px', minHeight: '700px' }}
          title="Histórico de tickets"
          columns={columns}
          items={items}
          loading={fetching}
          notFoundHelperText={errorMessage?.notFoundHelperText}
          cardActions={{
            header: (
              <St.CloseButton data-testid="closeButton" aria-label="close" onClick={() => closeModal()}>
                <Close style={{ color: '#000' }} />
              </St.CloseButton>
            ),
            subHeader: (
              <Filter
                selectedDates={selectedDates}
                status={status}
                onSupportClick={onSupportClick}
                handleStatusOnChange={handleFilterStatusOnChange}
                handleDateOnChange={handleFilterDateOnChange}
              />
            ),
          }}
          pagination={{
            pageIndex: pageNumber - 1,
            pageSize,
            hasNextPage,
            hasPreviousPage,
            indexFrom,
            totalCount,
            totalPages,
          }}
          onPaginationChange={handleOnPaginationChange}
        />
      )}
      {currentTicket?.ticket && (
        <div style={{ maxHeight: 600, maxWidth: 600 }}>
          <TicketDetailsModal setTickedId={setTickedId} ticketId={ticketId} data={currentTicket} loading={fetching} />
        </div>
      )}
    </St.Container>
  );
};

export default TicketHistoryModal;
