import React from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';
import {
  LocalShipping,
  MonetizationOn,
  Settings,
  ShoppingBasket,
  Storefront,
  HowToReg,
  Dashboard,
} from '@material-ui/icons';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { UserPermission } from '../../../@types/auth';
import { ReactComponent as FactCheck } from './assets/fact_check.svg';
import { ReactComponent as SupportIcon } from './assets/support.svg';

const { REACT_APP_RCHLO_ZENDESK_HELPER_URL, REACT_APP_RCHLO_ZENDESK_SIGN_IN_URL } = process.env;

const CustomSupportIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <SupportIcon />
  </SvgIcon>
);

const FactCheckIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <FactCheck />
  </SvgIcon>
);

export interface SubMenuProps {
  label: string;
  url: string;
  requiredUserPermission: UserPermission;
  preloadPage?: string;
  match?: boolean;
  openNewTab?: boolean;
}

export interface MenuProps {
  label: string;
  url?: string;
  icon: React.FC;
  requiredUserPermission: UserPermission;
  submenu?: SubMenuProps[];
  openNewTab?: boolean;
}

interface Props {
  featureToggleUpdate: boolean;
  featureToggleSAC: boolean;
  featureToggleActionCenter: boolean;
}

const allMenuOptions = ({ featureToggleUpdate, featureToggleSAC, featureToggleActionCenter }: Props): MenuProps[] => [
  {
    label: 'Dashboard',
    icon: Dashboard,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-seller',
      role: 'seller-view',
    },
    submenu: [
      {
        label: 'Acompanhe a evolução',
        url: '/dashboard',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-seller',
          role: 'seller-view',
        },
        match: true,
      },
    ],
  },
  {
    label: 'Produtos',
    icon: ShoppingBasket,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-catalog', role: 'product-view' },
    submenu: [
      {
        label: 'Gestão de produtos',
        url: '/produtos',
        preloadPage: 'Products/Products',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-catalog', role: 'product-view' },
        match: true,
      },
    ],
  },
  {
    label: 'Pedidos',
    icon: LocalMallIcon,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-order-sale', role: 'order-view' },
    submenu: [
      {
        preloadPage: 'Orders/Orders',
        label: 'Gestão de pedidos',
        url: '/pedidos',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-order-sale', role: 'order-view' },
      },
      {
        preloadPage: 'Orders/Update/Update',
        label: 'Atualização em lote',
        url: '/pedidos-atualizacao-em-lote',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-order-sale',
          role: 'order-view',
          businessParams: featureToggleUpdate,
        },
      },
    ],
  },
  {
    label: 'Pedidos',
    icon: LocalMallIcon,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-order-sale', role: 'lite-account-order-manage' },
    submenu: [
      {
        preloadPage: 'LiteAccount/Orders/Orders',
        label: 'Gestão de pedidos',
        url: '/pedidos-conta-lite',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-order-sale', role: 'lite-account-order-manage' },
      },
    ],
  },
  {
    label: 'Frete',
    icon: LocalShipping,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-freight-management', role: 'provider-view' },
    submenu: [
      {
        preloadPage: 'Provider/ShippingProvider',
        label: 'Provedor de frete',
        url: '/provedor-de-frete',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-freight-management', role: 'provider-view' },
      },
    ],
  },
  {
    label: 'Central do Seller',
    url: '/central-do-seller',
    icon: Storefront,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-seller', role: 'seller-contract-view' },
    submenu: [
      {
        preloadPage: 'SellerCentral/Contracts/Contracts',
        label: 'Contratos',
        url: '/central-do-seller/contratos',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-seller', role: 'seller-contract-view' },
      },
    ],
  },
  {
    label: 'Financeiro',
    url: '/financeiro',
    icon: MonetizationOn,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-commission', role: 'commission-view' },
    submenu: [
      {
        preloadPage: 'Financial/Conciliation',
        label: 'Relatório de Conciliação',
        url: '/financeiro/conciliacao',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-financial', role: 'financial-view' },
      },
      {
        preloadPage: 'Financial/Commission',
        label: 'Comissionamento',
        url: '/financeiro/comissionamento',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-commission', role: 'commission-view' },
      },
    ],
  },
  {
    label: 'Configurações',
    icon: Settings,
    requiredUserPermission: { api: 'mktp-mktp-odis-api-user-management', role: 'user-view' },
    submenu: [
      {
        preloadPage: 'UserSettings/PlatformValues',
        label: 'Parâmetros de integração',
        url: '/configuracoes/parametros-de-integracao',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-seller', role: 'platform-value-manage' },
        match: true,
      },
      {
        preloadPage: 'UserSettings/IntegrationSettings',
        label: 'Configurações de integração',
        url: '/configuracoes/configuracoes-de-integracao',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-user-management', role: 'client-secret-view' },
        match: true,
      },
      {
        preloadPage: 'UserSettings/Webhook',
        label: 'Notificações',
        url: '/configuracoes/webhook-de-notificacoes',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-seller', role: 'seller-view' },
        match: true,
      },
      {
        preloadPage: 'UserSettings/Users',
        label: 'Usuários',
        url: '/configuracoes/usuarios',
        requiredUserPermission: { api: 'mktp-mktp-odis-api-user-management', role: 'user-view' },
        match: true,
      },
    ],
  },
  {
    label: 'Suporte',
    url: '/suporte',
    icon: CustomSupportIcon,
    requiredUserPermission: { noExtraPermissionNeeded: true },
    submenu: [
      {
        label: 'Dúvidas frequentes',
        url: REACT_APP_RCHLO_ZENDESK_HELPER_URL as string,
        openNewTab: true,
        requiredUserPermission: { noExtraPermissionNeeded: true },
      },
    ],
  },
  {
    label: 'SAC',
    url: REACT_APP_RCHLO_ZENDESK_SIGN_IN_URL,
    openNewTab: true,
    icon: HowToReg,
    requiredUserPermission: { noExtraPermissionNeeded: true },
    submenu: [
      {
        preloadPage: 'SAC/CallCenter',
        label: 'Central de Atendimento',
        url: '/sac/central-de-atendimento',
        requiredUserPermission: {
          api: 'mktp-api-zendesk',
          role: 'ticket-view',
          businessParams: featureToggleSAC,
        },
      },
    ],
  },
  {
    label: 'Central de Ações',
    icon: FactCheckIcon,
    requiredUserPermission: {
      api: 'mktp-mktp-odis-api-seller',
      role: 'seller-view',
      businessParams: featureToggleActionCenter,
    },
    submenu: [
      {
        label: 'Downloads',
        url: '/central-acoes/downloads',
        requiredUserPermission: {
          api: 'mktp-mktp-odis-api-seller',
          role: 'seller-view',
          businessParams: featureToggleActionCenter,
        },
      },
    ],
  },
];

export { allMenuOptions };
