import { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BusinessParamsAction, BusinessParamsResponse, BusinessParamsState } from '../../../@types/businessParams';
import { api } from '../../../services/api';
import { store } from '../../index';

const { REACT_APP_BUSINESS_PARAMS_API_URL } = process.env;

const initialState: BusinessParamsState = {
  fetching: false,
  featureToggleUpdate: false,
  featureToggleSAC: true,
  featureToggleNewIntegration: true,
  featureToggleDashboard: true,
  featureToggleActionCenter: false,
};

const businessParamsReducer: Reducer<BusinessParamsState, BusinessParamsAction<any>> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@businessParams/VERIFY_BUSINESS_PARAM_NEW_INTEGRATION':
      return {
        ...state,
        fetching: true,
      };
    case '@businessParams/VERIFY_BUSINESS_PARAM_SUCCESS':
    case '@businessParams/VERIFY_BUSINESS_PARAM_ERROR':
      return { ...state, ...payload, fetching: false };
    default:
      return state;
  }
};

function* fetchBusinessParamsUpdate() {
  try {
    const {
      seller: { sellerId },
    } = store.getState();

    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableOrderUpdateStatus/rules-exceptions?context-values=${sellerId}`,
    );

    const defaultValue = data.defaultValue === 'true';
    const featureToggleUpdate = data.isActive ? defaultValue || data.ruleExceptions.length > 0 : false;

    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        featureToggleUpdate,
      },
    });
  } catch (error) {
    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        featureToggleUpdate: initialState.featureToggleUpdate,
      },
    });
  }
}

function* fetchBusinessParamsSac() {
  try {
    const {
      seller: { sellerId },
    } = store.getState();

    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableSac/rules-exceptions?context-values=${sellerId}`,
    );

    const defaultValue = data.defaultValue === 'true';
    const featureToggleSAC = data.isActive ? defaultValue || data.ruleExceptions.length > 0 : false;

    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        featureToggleSAC,
      },
    });
  } catch (error) {
    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        featureToggleSAC: initialState.featureToggleSAC,
      },
    });
  }
}

function* fetchBusinessParamsNewIntegration() {
  try {
    const {
      seller: { sellerId },
    } = store.getState();

    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableNewIntegration/rules-exceptions?context-values=${sellerId}`,
    );

    const defaultValue = data.defaultValue === 'true';
    const featureToggleNewIntegration = data.isActive ? defaultValue || data.ruleExceptions.length > 0 : false;

    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        featureToggleNewIntegration,
      },
    });
  } catch (error) {
    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        featureToggleNewIntegration: initialState.featureToggleNewIntegration,
      },
    });
  }
}

function* fetchBusinessParamsEnableDashboard() {
  try {
    const {
      seller: { sellerId },
    } = store.getState();

    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableDashboard/rules-exceptions?context-values=${sellerId}`,
    );

    const defaultValue = data.defaultValue === 'true';
    const featureToggleDashboard = data.isActive ? defaultValue || data.ruleExceptions.length > 0 : false;

    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        featureToggleDashboard,
      },
    });
  } catch (error) {
    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* fetchBusinessParamsEnableActionCenter() {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableActionCenter`,
    );

    const defaultValue = data.defaultValue === 'true';
    const featureToggleActionCenter = data.isActive ? defaultValue || data.ruleExceptions.length > 0 : false;
    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        featureToggleActionCenter,
      },
    });
  } catch (error) {
    yield put({
      type: '@businessParams/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        featureToggleActionCenter: initialState?.featureToggleActionCenter,
      },
    });
  }
}

function* businessParamsSaga() {
  yield takeLatest('@businessParams/VERIFY_BUSINESS_PARAM_UPDATE', fetchBusinessParamsUpdate);
  yield takeLatest('@businessParams/VERIFY_BUSINESS_PARAM_SAC', fetchBusinessParamsSac);
  yield takeLatest('@businessParams/VERIFY_BUSINESS_PARAM_NEW_INTEGRATION', fetchBusinessParamsNewIntegration);
  yield takeLatest('@businessParams/VERIFY_BUSINESS_PARAM_ENABLE_DASHBOARD', fetchBusinessParamsEnableDashboard);
  yield takeLatest('@businessParams/VERIFY_BUSINESS_PARAM_ENABLE_ACTION_CENTER', fetchBusinessParamsEnableActionCenter);
}

export { businessParamsReducer, businessParamsSaga };
