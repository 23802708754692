import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { WebhookState } from '../../../../@types/webhook';
import { Container, Header, StyleModal } from '../../FirstSteps.styles';
import { HelpTextBold, HelpText, LinkHelperUnderline, HelperContainer } from '../components/fontStyles';
import { ArrowLeftIcon, BackButton } from './BackButton';
import ModalHeader from './ModalHeader';
import WarningLabel from './WarningLabel';
import { ModalContainerBody } from './shared-components';

interface Props {
  sellerId: string;
  backStep: Function;
  onSupportClick: Function;
  onTicketHistoryClick: Function;
}

interface ProviderItemValidate {
  url?: string;
}

const initialValues = {
  url: '',
};

const WebhookModal: React.FC<Props> = ({ sellerId, backStep, onSupportClick, onTicketHistoryClick }) => {
  const dispatch = useDispatch();
  const title = 'Bem-vindo, para continuar confira as informações';

  const { error, fetching, url, webhookId } = useSelector<{ webhook: WebhookState }, WebhookState>(({ webhook }) => ({
    ...webhook,
  }));

  const values = { url } || initialValues;

  useEffect(() => {
    dispatch({
      type: '@webhook/FETCH_URL',
      payload: { sellerId },
    });
  }, []);

  const finishOnboarding = () =>
    dispatch({
      type: '@onboarding/FINISH_ONBOARDING',
      payload: {
        sellerId,
      },
    });

  const handleSubmit = ({ url }: ProviderItemValidate) => {
    if (webhookId || !url) finishOnboarding();
    else {
      dispatch({
        type: '@webhook/SEND_URL',
        payload: {
          sellerId,
          url,
          callback: () => finishOnboarding(),
        },
      });
    }
  };

  return (
    <StyleModal
      disableAutoFocus
      disableEnforceFocus
      open={true}
      aria-labelledby="modal-first-steps-setup"
      aria-describedby="modal-setup-with-first-steps"
    >
      <Container>
        <Header data-testid={`firstStepsSetupTitle-${title}`}>{title}</Header>
        <Formik enableReinitialize initialValues={values} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, touched }: FormikProps<ProviderItemValidate>) => (
            <Form>
              <ModalContainerBody>
                <ModalHeader
                  title="Configurações de integração"
                  description="Insira abaixo o Webhook de notificações através de uma URL válida"
                  tooltipTitle="Acesse estas informações em Configurações > Notificações"
                  onPress={onTicketHistoryClick}
                />
                {fetching && (
                  <Grid container justifyContent="center">
                    <CircularProgress />
                  </Grid>
                )}
                {!fetching && (
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        name="url"
                        label="URL"
                        placeholder="Adicionar Webhook de notificação"
                        fullWidth
                        value={values.url}
                        disabled={!!webhookId}
                        onChange={handleChange}
                        error={(Boolean(errors.url) && touched.url) || error}
                        helperText={errors.url && touched.url ? errors.url : null}
                      />
                    </Grid>
                  </Grid>
                )}
                <HelperContainer>
                  <Grid item md={12}>
                    {error && <WarningLabel message="Insira Webhook válido para continuar" />}
                  </Grid>
                </HelperContainer>
                <HelperContainer>
                  <HelpTextBold>Precisa de ajuda? </HelpTextBold>
                  <HelpText>Entre em contato com o nosso </HelpText>
                  <LinkHelperUnderline onClick={() => onSupportClick()}>Suporte.</LinkHelperUnderline>
                </HelperContainer>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <BackButton onClick={() => backStep()} color="default" type="button" startIcon={<ArrowLeftIcon />}>
                    Voltar
                  </BackButton>
                  <Button variant="contained" color="primary" type="submit" style={{ marginBottom: 20, marginTop: 20 }}>
                    Concluir
                  </Button>
                </Box>
              </ModalContainerBody>
            </Form>
          )}
        </Formik>
      </Container>
    </StyleModal>
  );
};

export default WebhookModal;
