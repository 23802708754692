import React from 'react';
import { Button, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import { ModalTitle, ModalText, ModalTextBold } from './fontStyles';

export interface Props {
  open: boolean;
  setOpen: Function;
}

const InformationModal: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogContent>
        <ModalTitle>O que é uma integradora?</ModalTitle>
      </DialogContent>
      <DialogContent>
        <ModalText>
          Integradora é uma plataforma que facilita gestão do lojista sobre seus produtos, pedidos e cálculo de frete em
          vários e diversos canais de venda.
        </ModalText>
      </DialogContent>
      <DialogContent>
        <ModalTextBold>Como posso contratar uma integradora? </ModalTextBold>
        <ModalText>
          Caso queira contratar uma integradora, você deverá pesquisar no mercado a plataforma que mais se adeque as
          suas necessidades operacionais antes de contratá-la.
        </ModalText>
      </DialogContent>
      <DialogActions
        style={{
          padding: '20px',
        }}
      >
        <Button
          data-testid={`confirmModalCancelButton`}
          onClick={() => setOpen(false)}
          variant="contained"
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformationModal;
