import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorMessages, IError } from '../../../@types';
import type { Pagination } from '../../../@types';
import {
  CommissionFetch,
  SKUCampaignFetch,
  FinancialCommissionResponse,
  SKUCommissionItem,
  PaginationSKUCommissionResponse,
  CommissionValueResponse,
  SKUCampaignResponse,
} from '../../../@types/financial';
import { api } from '../../../services/api';

const { REACT_APP_COMMISSION_API_URL, REACT_APP_COMMISSION_API_URL_V2 } = process.env;

export type FinancialCommissionState = Pagination<SKUCommissionItem> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  fetchingSKU: boolean;
  SKUErrorMessages: ErrorMessages;
  data: Array<FinancialCommissionResponse>;
  hasConfirmationPending: boolean;
  fileUrl?: string;
};

export type FinancialCommissionAction<T> = Action<string> & {
  payload: T;
};

export type ListCommissionValue = FinancialCommissionAction<CommissionFetch>;
export type SKUCampaignValue = FinancialCommissionAction<SKUCampaignFetch>;
export type FetchCampaignsFileAction = FinancialCommissionAction<CommissionFetch>;

const initialState: FinancialCommissionState = {
  fetching: false,
  success: false,
  errorMessages: [],
  fetchingSKU: false,
  SKUErrorMessages: [],
  data: [],
  items: [],
  hasConfirmationPending: false,
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  fileUrl: '',
};

const itemLoadingHandle = (items: Array<SKUCommissionItem>, campaignId: string, showLoading: boolean) => {
  const campaigns = items.map((item) => {
    if (item.id === campaignId) {
      item.loading = showLoading;
    }
    return item;
  });
  return campaigns;
};

const getConfirmationPending = (items: SKUCommissionItem[]) => {
  return !!items?.find((item: SKUCommissionItem) => !item.confirmedAt);
};

const commissionReducer: Reducer<FinancialCommissionState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@commission/FETCH':
      return { ...state, ...payload, fetching: true };
    case '@commission/FETCH_SKU':
      return { ...state, ...payload, fetchingSKU: true };
    case '@commission/CONFIRM_SKU_CAMPAIGN': {
      const items = itemLoadingHandle(state.items, payload.campaignId, true);

      return { ...state, ...payload, items };
    }
    case '@commission/FETCH_SUCCESS':
    case '@commission/FETCH_ERROR':
    case '@commission/FETCH_SKU_ERROR':
      return { ...state, ...payload };
    case '@commission/FETCH_SKU_SUCCESS':
      return { ...state, ...payload, hasConfirmationPending: getConfirmationPending(payload.items) };
    case '@commission/CONFIRM_SKU_CAMPAIGN_SUCCESS': {
      const sItems = state.items.map((item) => {
        if (item.id === payload.data.id) {
          item = payload.data;
        }
        return item;
      });
      delete payload.data;

      return { ...state, ...payload, items: sItems, hasConfirmationPending: getConfirmationPending(sItems) };
    }
    case '@commission/FETCH_VIGENT_SKU_COMMISSION_SUCCESS':
    case '@commission/FETCH_VIGENT_SKU_COMMISSION_ERROR':
      return { ...state, ...payload };
    case '@commission/CONFIRM_SKU_CAMPAIGN_ERROR': {
      const eItems = itemLoadingHandle(state.items, payload.campaignId, false);
      return { ...state, ...payload, items: eItems };
    }
    default:
      return state;
  }
};

function* fetchCommissionValue({ payload: { sellerId } }: ListCommissionValue) {
  try {
    const response: CommissionValueResponse = yield call(
      api.get,
      `${REACT_APP_COMMISSION_API_URL_V2}/commission/seller`,
      {
        params: {
          contextType: 'Seller',
          contextId: sellerId,
        },
      },
    );

    yield put({
      type: '@commission/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        data: [response?.data],
      },
    });
  } catch (error) {
    const e = error as IError;
    yield put({
      type: '@commission/FETCH_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        errorMessages: [
          {
            notFoundHelperText: 'Ocorreu um erro ao exibir comissão!',
          },
        ],
        fetching: false,
      },
    });
  }
}

function* fetchSKUCommission({ payload: { sellerId, pageIndex = 0, pageSize = 20 } }: ListCommissionValue) {
  try {
    const response: PaginationSKUCommissionResponse = yield call(
      api.get,
      `${REACT_APP_COMMISSION_API_URL}/campaigns?sellerId=${sellerId}&page-index=${pageIndex}&page-size=${pageSize}`,
    );

    yield put({
      type: '@commission/FETCH_SKU_SUCCESS',
      payload: {
        SKUErrorMessages: [],
        fetchingSKU: false,
        ...response?.data,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@commission/FETCH_SKU_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        SKUErrorMessages: [
          {
            notFoundHelperText: 'Não existem comissões por SKU',
          },
        ],
        fetchingSKU: false,
      },
    });
  }
}

function* confirmSKUCampaign({ payload: { campaignId } }: SKUCampaignValue) {
  try {
    const response: SKUCampaignResponse = yield call(
      api.put,
      `${REACT_APP_COMMISSION_API_URL}/campaigns/${campaignId}/useracceptance`,
    );

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Opção salva com sucesso!`,
        type: 'success',
        open: true,
      },
    });

    yield put({
      type: '@commission/CONFIRM_SKU_CAMPAIGN_SUCCESS',
      payload: {
        SKUErrorMessages: [],
        data: response?.data,
      },
    });
  } catch (error) {
    const e = error as IError;
    yield put({
      type: '@commission/CONFIRM_SKU_CAMPAIGN_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        error: true,
        campaignId,
      },
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Ocorreu um erro ao processar sua solicitação, por favor tente novamente.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchSellerCampaignsFile({ payload }: FetchCampaignsFileAction) {
  if (payload) {
    try {
      const response: BlobPart = yield call(
        api.get,
        `${REACT_APP_COMMISSION_API_URL}/commission/current-commissions?sellerId=${payload.sellerId}&contextType=sku`,
        {
          responseType: 'blob',
        },
      );
      if (response) {
        const blob = new Blob([response]);
        const url = URL.createObjectURL(blob);
        yield put({
          type: '@commission/FETCH_VIGENT_SKU_COMMISSION_SUCCESS',
          payload: {
            fileUrl: url,
          },
        });
      }
    } catch (error) {
      yield put({
        type: '@commission/FETCH_VIGENT_SKU_COMMISSION_ERROR',
        payload: {
          loading: false,
          fileUrl: '',
        },
      });
    }
  }
}

function* commissionSaga() {
  yield takeLatest('@commission/FETCH', fetchCommissionValue);
  yield takeLatest('@commission/FETCH_SKU', fetchSKUCommission);
  yield takeLatest('@commission/CONFIRM_SKU_CAMPAIGN', confirmSKUCampaign);
  yield takeLatest('@commission/FETCH_SKU_COMMISSION_VIGENT', fetchSellerCampaignsFile);
}

export { commissionReducer, commissionSaga };
