import React from 'react';
import styled from 'styled-components';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const WarningLabelRoot = styled.div`
  background-color: #fdedeb;
  color: #b30511;
  height: 32px;
  width: 100%;
  display: flex;
  padding: '8px 16px';
  margin: 0 auto;
`;

export type WarningLabelProps = {
  message: string;
};

const WarningLabel: React.FC<WarningLabelProps> = ({ message }) => (
  <WarningLabelRoot>
    <div style={{ marginRight: 12, marginLeft: 10, marginTop: 4 }}>
      <ErrorOutlineIcon />
    </div>
    <div style={{ marginTop: 6, fontSize: 12 }}>
      <span>{message}</span>
    </div>
  </WarningLabelRoot>
);

export default WarningLabel;
