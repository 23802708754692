import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createStore from './createStore';
import { rootReducer, rootSaga } from './ducks';

const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  collapsed: true,
});

const middlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const { store, persistor } = createStore(rootReducer, middlewares);

sagaMiddleware.run(rootSaga);

export { store, persistor };
