import React from 'react';
import { useDispatch } from 'react-redux';
import { FileCopyOutlined } from '@material-ui/icons';

export type CopyAdornmentButtonProps = {
  value: string;
};

const CopyAdornmentButton: React.FC<CopyAdornmentButtonProps> = ({ value }) => {
  const dispatch = useDispatch();

  const showMessage = () => {
    dispatch({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Texto copiado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  };

  return (
    <div
      onClick={() => {
        showMessage();
        const textField = document.createElement('textarea');
        textField.innerText = value;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
      }}
      style={{ cursor: 'pointer' }}
    >
      <FileCopyOutlined style={{ color: '#808080' }} />
    </div>
  );
};

export default CopyAdornmentButton;
