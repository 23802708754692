import { applyMiddleware, createStore, Middleware, Reducer } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  whitelist: ['brands', 'sac'],
  storage,
};

export default (reducers: Reducer, middlewares: Middleware[]) => {
  const enhancer = applyMiddleware(...middlewares);
  const persistedReducer = persistReducer(persistConfig, reducers);

  const store = createStore(persistedReducer, enhancer);

  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
};
