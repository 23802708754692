import type { Reducer } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { store } from '../..';
import { DataResponse, PaginationResponse, IError } from '../../../@types';
import { IntegrationPlatformOnboarding } from '../../../@types/onboarding';
import {
  PlatformValuesState,
  FetchPlatformValuesAction,
  UpdatePlatformValueAction,
  IPlatform,
  PlatformValues,
  FetchPlatformList,
  FetchSellerIntegrationPlatformAction,
  IntegrationPlatformOnboardingAction,
  Steps,
} from '../../../@types/platformValues';
import {
  PlatformValueRequest,
  PlatformValueResponse,
  PlatformValuesResponse,
  IErrorMessage,
} from '../../../@types/users';
import { api } from '../../../services/api';

const { REACT_APP_SELLER_API_URL, REACT_APP_API_BASEPATH_URL } = process.env;

const toPlatformValues = (items: Array<{ id: string; key: string; value: string }>): PlatformValues =>
  items?.reduce<PlatformValues>((acc, { value, key }) => ({ ...acc, [key]: value }), {});

const genericError = {
  message: 'Ocorreu um erro ao processar sua solicitação, confira abaixo mais informações.',
  status: 'genericError',
};

const initialState: PlatformValuesState = {
  fetching: false,
  success: false,
  errorMessages: [],
  updating: false,
  values: {},
  integrationPlatformList: [],
  onboardFinished: false,
  isSubmitting: false,
  integrationDataCompleted: false,
  hasNextPage: false,
  pageIndex: 0,
  selectedPlatform: { name: '' },
  fetchingSelect: false,
  nextStep: Steps.SETUP,
  isVtexOrAnymarket: false,
};

const platformValuesReducer: Reducer<PlatformValuesState, FetchPlatformValuesAction | UpdatePlatformValueAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@platformValues/UPDATE_INTEGRATION_PLATFORM':
    case '@platformValues/VALIDATE_VTEX_INTEGRATION_PLATFORM':
      return { ...state, ...payload, isSubmitting: true };
    case '@platformValues/FETCH_LIST':
    case '@platformValues/FETCH':
      return { ...state, ...payload, fetching: true };
    case '@platformValues/UPDATE':
      return {
        ...state,
        ...payload,
        values: { ...toPlatformValues((payload as PlatformValueRequest).body) },
        updating: true,
      };
    case '@platformValues/VALIDATE_VTEX_INTEGRATION_PLATFORM_ERROR':
    case '@platformValues/FETCH_INTEGRATION_PLATFORMS_LIST_SUCCESS':
      return { ...state, ...payload, fetchingSelect: false };
    case '@platformValues/FINISH_ONBOARDING_SUCCESS':
    case '@platformValues/UPDATE_SUCCESS':
      return { ...state, ...payload, fetching: false };
    case '@platformValues/FETCH_SELLER_INTEGRATION_PLATFORM_SUCCESS':
    case '@platformValues/FETCH_SUCCESS':
    case '@platformValues/FETCH_ERROR':
    case '@platformValues/FINISH_ONBOARDING_ERROR':
    case '@platformValues/UPDATE_PLATFORM_NAME_ONBOARDING_ERROR':
    case '@platformValues/UPDATE_PLATFORM_VALUE_ONBOARDING_ERROR':
    case '@platformValues/UPDATE_ERROR':
    case '@platformValues/SET_NEXT_STEP':
      return { ...state, ...payload };
    default:
      return state;
  }
};

function* fetchPlatformValues({ payload: { id, pageIndex = 0, pageSize = 5 } }: FetchPlatformValuesAction) {
  try {
    const response: PlatformValuesResponse = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/sellers/${id}/platform-values`,
      {
        params: {
          pageSize,
          pageIndex,
        },
      },
    );

    yield put({
      type: '@platformValues/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        isSubmitting: false,
        fetchingSelect: false,
        values: { ...toPlatformValues(response?.data?.items) },
      },
    });
  } catch (error) {
    const e = error as IError;

    if (e?.status === 404) {
      yield put({
        type: '@platformValues/FETCH_ERROR',
        payload: {
          ...initialState,
          fetching: false,
          isSubmitting: false,
          fetchingSelect: false,
          values: {
            'account-name': '',
            'affiliate-id': '',
            'application-key': '',
            'application-token': '',
            'sales-channel': '',
          },
          errorMessages: e?.data?.erroMessages || [
            {
              message: e.data.message,
              code: '404',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@platformValues/FETCH_ERROR',
        payload: {
          ...initialState,
          fetching: false,
          isSubmitting: false,
          fetchingSelect: false,
          values: {
            'account-name': '',
            'affiliate-id': '',
            'application-key': '',
            'application-token': '',
            'sales-channel': '',
          },
          ...e.data,
        },
      });
    }
  }
}

function* updatePlatformValues({ payload: { id, body } }: UpdatePlatformValueAction) {
  let message;
  try {
    const vtexFieldsValues = {
      accountName: body?.[2].value,
      applicationKey: body?.[3].value,
      applicationToken: body?.[4].value,
    };

    yield call(api.post, `${REACT_APP_SELLER_API_URL}/integrations/vtex`, vtexFieldsValues);

    const response: PlatformValueResponse = yield call(
      api.patch,
      `${REACT_APP_SELLER_API_URL}/sellers/${id}/platform-values`,
      [...body],
    );

    const { values } = yield select();

    const { data, success, errorMessages } = response;

    yield put({
      type: '@platformValues/UPDATE_SUCCESS',
      payload: {
        success,
        errorMessages,
        updating: false,
        values: { ...values, ...toPlatformValues(data) },
      },
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Dados alterados com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    const e = error as IError;

    const errors = {
      401: 'Chave vtex inválida!',
      403: 'Chave vtex inválida!',
      404: 'Account name inválido!',
      500: 'Serviço de validação vtex indisponível. Tente novamente mais tarde!',
      1000: 'Insucesso na validação dos dados vtex! Tente novamente mais tarde ou entre em contato com o suporte da VTEX',
      1001: 'Entre em contato com o suporte VTEX, informando a mensagem: InvalidCredencials: AppKey e AppToken inválidas!',
      1002: 'Entre em contato com o suporte VTEX, informando a mensagem: NotHavePermission: AppKey e AppToken sem permissão!',
      1003: 'Vtex store inativa! Tente novamente mais tarde ou entre em contato com o suporte da VTEX',
      1004: 'Account name inválido! Em caso de dúvidas, entre em contato com o suporte da VTEX',
    };

    message = errors[e.data.errorMessages[0].code as keyof IErrorMessage] || errors[1000];

    yield put({
      type: '@platformValues/UPDATE_ERROR',
      payload: {
        ...initialState,
        errorMessages: [
          {
            status: e.status,
            message,
          },
        ],
        fetching: false,
      },
    });
  } finally {
    if (message) {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message,
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* fetchIntegrationPlatformsList({ payload }: FetchPlatformList) {
  const {
    platformValues: { integrationPlatformList },
  } = store.getState();

  const params = {
    'page-index': payload?.pageIndex || 0,
    'page-size': 10,
    isActive: true,
  };

  try {
    const response: PaginationResponse<IPlatform[]> = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/integration-platforms`,
      { params },
    );

    yield put({
      type: '@platformValues/FETCH_INTEGRATION_PLATFORMS_LIST_SUCCESS',
      payload: {
        integrationPlatformList: integrationPlatformList.concat(response.data.items),
        hasNextPage: response.data.hasNextPage,
        pageIndex: response.data.pageIndex + 1,
        fetching: false,
        isSubmitting: false,
        fetchingSelect: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@platformValues/FETCH_INTEGRATION_PLATFORMS_LIST_ERROR',
      payload: {
        errorMessages: 'Algo deu errado. Por favor, tente novamente.',
        fetching: false,
        isSubmitting: false,
        fetchingSelect: false,
      },
    });
  }
}

function* fetchSellerIntegrationPlatform({ payload: { sellerId } }: FetchSellerIntegrationPlatformAction) {
  try {
    const response: DataResponse<{ id: string; name: string }> = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/integration-platforms`,
    );
    const integrationPlatformName = response.data.name;
    const isVtexOrAnymarket =
      integrationPlatformName === 'VTEX' || integrationPlatformName.toUpperCase() === 'ANYMARKET';
    if (isVtexOrAnymarket) {
      yield call(fetchPlatformValuesOnboarding, { payload: { sellerId, integrationPlatformName } });
    }
    yield put({
      type: '@platformValues/FETCH_SELLER_INTEGRATION_PLATFORM_SUCCESS',
      payload: {
        selectedPlatform: { id: response.data.id, name: integrationPlatformName },
        fetching: false,
        isSubmitting: false,
        fetchingSelect: false,
        isVtexOrAnymarket,
      },
    });
  } catch (error) {
    yield put({
      type: '@brands/FETCH_SELLER_INTEGRATION_PLATFORM_ERROR',
      payload: {
        ...initialState,
        fetching: false,
        isSubmitting: false,
        fetchingSelect: false,
      },
    });
  }
}

// TO DO Método será ajustado na segunda PBI (WIP)
function* validateVtexIntegrationPlatform(payload: IntegrationPlatformOnboardingAction) {
  const { accountName, salesChannel, affiliateId, applicationKey, applicationToken } =
    payload.payload.integrationPlatformValues;
  try {
    yield call(api.post, `${REACT_APP_SELLER_API_URL}/integrations/vtex/validate`, {
      accountName,
      salesChannel,
      affiliateId,
      applicationKey,
      applicationToken,
    });
    yield put({
      type: '@platformValues/VALIDATE_VTEX_INTEGRATION_PLATFORM_SUCCESS',
    });
    yield call(updatePlatformNameOnboarding, payload);
  } catch (error) {
    const e = error as IError;

    const errors = {
      accountName: 'Nome da conta inválido',
      affiliateId: 'Id do afiliado inválido',
      salesChannel: 'Política comercial inválida',
      application: 'AppKey ou AppToken inválidas!',
    };
    let errorMessage = genericError;

    if (e.data.errorMessages?.[0]?.message?.match('SearchURIEndpoint is invalid!')) {
      errorMessage = {
        message: 'A integração não pode ser processada, endpoint de notificação inválido no painel VTEX',
        status: 'searchUrlEndpoint',
      };
    }

    if (e.data.errorMessages?.[0]?.message?.match('AccountName')) {
      errorMessage = { message: errors.accountName, status: 'accountName' };
    }

    if (e.data.errorMessages?.[0]?.message?.match('ApplicationKey')) {
      errorMessage = { message: errors.application, status: 'application' };
    }

    if (e.data.errorMessages?.[0]?.message?.match('AffiliateId')) {
      errorMessage = { message: errors.affiliateId, status: 'affiliateId' };
    }

    if (e.data.errorMessages?.[0]?.message?.match('SalesChannel')) {
      errorMessage = { message: errors.salesChannel, status: 'salesChannel' };
    }

    yield put({
      type: '@platformValues/VALIDATE_VTEX_INTEGRATION_PLATFORM_ERROR',
      payload: {
        errorMessages: [errorMessage],
        fetching: false,
        isSubmitting: false,
      },
    });
  }
}
// TO DO Quando o patch voltar a funcionar, deixar a request com o axios
function* updatePlatformNameOnboarding(payload: IntegrationPlatformOnboardingAction) {
  const {
    businessParams: { featureToggleNewIntegration },
  } = store.getState();

  try {
    const { sellerId, integrationPlatformName } = payload.payload;
    const isVtexOrAnymarket =
      integrationPlatformName?.toUpperCase() === 'VTEX' || integrationPlatformName?.toUpperCase() === 'ANYMARKET';
    const token = sessionStorage.getItem('mktp_token') || '';

    yield call(fetch, `${REACT_APP_API_BASEPATH_URL}/seller/v1-rchlo/sellers/${sellerId}/integration-platforms`, {
      method: 'PATCH',
      body: JSON.stringify({
        integrationPlatformName,
      }),
      headers: {
        'content-type': 'application/json',
        authorization: 'bearer ' + token,
      },
    });
    if (isVtexOrAnymarket) {
      yield call(updatePlatformValueOnboarding, payload);
    } else {
      if (featureToggleNewIntegration) {
        yield put({
          type: '@platformValues/SET_NEXT_STEP',
          payload: { nextStep: Steps.INFO, isSubmitting: false },
        });
      } else {
        yield call(finishOnboarding, payload);
      }
    }
  } catch (error) {
    yield put({
      type: '@platformValues/UPDATE_PLATFORM_NAME_ONBOARDING_ERROR',
      payload: {
        fetching: false,
        isSubmitting: false,
        errorMessages: [genericError],
      },
    });
  }
}
// TO DO Quando o patch voltar a funcionar, deixar a request com o axios
function* updatePlatformValueOnboarding(payload: IntegrationPlatformOnboardingAction) {
  let platformValues = {};
  const { integrationPlatformName, sellerId } = payload.payload;
  if (integrationPlatformName === 'VTEX') {
    const { accountName, salesChannel, affiliateId, applicationKey, applicationToken } =
      payload.payload.integrationPlatformValues;

    platformValues = {
      accountName,
      salesChannel,
      affiliateId,
      applicationKey,
      applicationToken,
    };
  } else if (integrationPlatformName.toUpperCase() === 'ANYMARKET') {
    platformValues = {
      oi: payload.payload.integrationPlatformValues.oi,
    };
  }

  try {
    yield call(
      api.put,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/platform-values/${integrationPlatformName.toLowerCase()}`,
      platformValues,
    );
    yield call(finishOnboarding, payload);
    yield put({
      type: '@platformValues/FETCH_SELLER_INTEGRATION_PLATFORM_SUCCESS',
      payload: {
        integrationDataCompleted: true,
        fetching: false,
        isSubmitting: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@platformValues/UPDATE_PLATFORM_VALUE_ONBOARDING_ERROR',
      payload: {
        fetching: false,
        isSubmitting: false,
        errorMessages: [genericError],
      },
    });
  }
}

function* finishOnboarding(payload: IntegrationPlatformOnboardingAction) {
  const { sellerId, integrationPlatformName } = payload.payload;
  const finishOnboardingPayload = {
    hasSetupCompleted: true,
  };
  const isVtex = integrationPlatformName === 'VTEX';
  const token = sessionStorage.getItem('mktp_token') || '';

  try {
    yield call(fetch, `${REACT_APP_API_BASEPATH_URL}/seller/v1-rchlo/sellers/${sellerId}/onboardings`, {
      method: 'PATCH',
      body: JSON.stringify(
        isVtex ? { ...finishOnboardingPayload, hasVtexFirstLoadTriggered: true } : finishOnboardingPayload,
      ),
      headers: {
        'content-type': 'application/json',
        authorization: 'bearer ' + token,
      },
    });
    yield put({
      type: '@platformValues/FINISH_ONBOARDING_SUCCESS',
      payload: {
        integrationDataCompleted: true,
        fetching: false,
        isSubmitting: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@platformValues/FINISH_ONBOARDING_ERROR',
      payload: {
        fetching: false,
        isSubmitting: false,
        errorMessages: [genericError],
      },
    });
  }
}

function* fetchPlatformValuesOnboarding({ payload: { sellerId, integrationPlatformName } }: any) {
  try {
    const response: DataResponse<IntegrationPlatformOnboarding> = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/platform-values/${integrationPlatformName.toLowerCase()}`,
    );

    yield put({
      type: '@platformValues/FETCH_SELLER_INTEGRATION_PLATFORM_SUCCESS',
      payload: {
        formikInputValues: { ...response?.data, integrationPlatformName },
        integrationPlatformName,
        fetching: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@platformValues/FETCH_ERROR',
      payload: {
        fetching: false,
      },
    });
  }
}

function* platformValuesSaga() {
  yield takeLatest('@platformValues/FETCH', fetchPlatformValues);
  yield takeLatest('@platformValues/UPDATE', updatePlatformValues);
  yield takeLatest('@platformValues/FETCH_LIST', fetchIntegrationPlatformsList);
  yield takeLatest('@platformValues/FETCH_SELLER_PLATFORM', fetchSellerIntegrationPlatform);
  yield takeLatest('@platformValues/VALIDATE_VTEX_INTEGRATION_PLATFORM', validateVtexIntegrationPlatform);
  yield takeLatest('@platformValues/FETCH_PLATFORM_VALUE_ONBOARDING', fetchPlatformValuesOnboarding);
  yield takeLatest('@platformValues/UPDATE_INTEGRATION_PLATFORM', updatePlatformNameOnboarding);
}

export { platformValuesReducer, platformValuesSaga };
