/* eslint-disable no-console */
import { AuthClientTokens, AuthClientEvent } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { clarity } from 'react-microsoft-clarity';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LayoutState } from '../src/@types/layout';
import BackDropComponent from '../src/shared/components/BackDropComponent';
import Snackbar from '../src/shared/components/Snackbar';
import { AuthProvider } from './contexts/AuthProvider';
import Pages from './pages';
import { store, persistor } from './store';

moment.locale(process.env.REACT_APP_LOCALE ?? 'pt-br');
const {
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_AUTH_URL,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_API_CLARITY_ID,
  REACT_APP_PROD,
} = process.env;

export const logoutUser = () => {
  sessionStorage.removeItem('mktp_token');
  sessionStorage.removeItem('mktp_resourceAccess');
};

const handleTokens = ({ token }: AuthClientTokens) => {
  sessionStorage.setItem('mktp_token', token ?? '');
};
const clearSessionStorage = () => sessionStorage.removeItem('mktp_token');

const Comunication = () => {
  const { message, type, fullName, open, backDropOpen, arrayMessage } = useSelector<{ layout: LayoutState }>(
    (state) => state.layout,
  ) as LayoutState;

  if (backDropOpen) return <BackDropComponent />;
  if (message !== '')
    return <Snackbar message={message} arrayMessage={arrayMessage} type={type} fullName={fullName} open={open} />;
  else return <div></div>;
};

const App = () => {
  useEffect(() => {
    REACT_APP_PROD === 'true' && clarity.init(`${REACT_APP_API_CLARITY_ID}`);
  }, []);

  return (
    <AuthProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Pages />
          <Comunication />
        </PersistGate>
      </Provider>
    </AuthProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
