import { createTheme } from '@material-ui/core';
import createSpacing from '@material-ui/core/styles/createSpacing';

export const baseStyle: any = {
  unstable_strictMode: true,
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '6px',
      },
      elevation1: {
        boxShadow: '0px 2px 5px #00000042',
      },
    },
    MuiCardHeader: {
      root: {
        flexDirection: 'row-reverse',
        padding: '17px 24px',
      },
    },
    MuiCardContent: {
      root: {
        padding: '3px 24px',
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'red',
      },
    },
  },
  spacing: createSpacing([0, 3.75, 7.5, 15, 30, 60, 120, 240, 480, 960, 1920]),
  palette: {
    text: {
      primary: '#252525',
      secondary: '#808080',
    },
    primary: {
      contrastText: '#fff',
      main: '#ED6926',
    },
    secondary: {
      main: '#525252',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: {
      fontSize: '32px',
      lineHeight: '20px',
      fontWeight: 300,
    },
    h2: {
      fontSize: '17px',
      lineHeight: '30px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '23px',
      lineHeight: '30px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '22px',
      lineHeight: '30px',
      fontWeight: 300,
    },
    h5: {
      fontSize: '18px',
      lineHeight: '23px',
      fontWeight: 500,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '30px',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '17px',
      lineHeight: '30px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#808080',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 300,
    },
  },
  shape: {
    borderRadius: 6,
  },
};

export const MKTPTheme = createTheme(baseStyle);

export default MKTPTheme;
