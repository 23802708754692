import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Steps } from '../../../../@types/platformValues';
import { ProviderState } from '../../../../store/ducks/providers/providers.duck';
import { Container, Header, StyleModal } from '../../FirstSteps.styles';
import { HelpTextBold, HelpText, LinkHelperUnderline, HelperContainer } from '../components/fontStyles';
import { BackButton, ArrowLeftIcon } from './BackButton';
import ModalHeader from './ModalHeader';
import WarningLabel from './WarningLabel';
import { ModalContainerBody } from './shared-components';

interface Props {
  sellerId: string;
  backStep: Function;
  onSupportClick: Function;
  onTicketHistoryClick: Function;
}

interface ProviderItemValidate {
  name: string;
  url: string;
}

const initialValues = {
  name: '',
  url: '',
};

const FreightProviderModal: React.FC<Props> = ({ sellerId, backStep, onSupportClick, onTicketHistoryClick }) => {
  const dispatch = useDispatch();
  const title = 'Bem-vindo, para continuar confira as informações';
  const { error, fetching, items } = useSelector<{ providers: ProviderState }>(
    (state) => state.providers,
  ) as ProviderState;
  const values = items.length > 0 ? items?.[0] : initialValues;
  const isDisabled = items.length > 0 ? true : false;

  useEffect(() => {
    dispatch({
      type: '@providers/FETCH',
      payload: {
        pageIndex: 0,
        pageSize: 1,
      },
    });
  }, []);

  const handleSubmit = ({ name, url }: ProviderItemValidate) => {
    if (!isDisabled && url) {
      dispatch({
        type: '@providers/CREATE_PROVIDER',
        payload: {
          name,
          url,
          sellerId,
          isOnboarding: true,
        },
      });
    } else {
      dispatch({
        type: '@platformValues/SET_NEXT_STEP',
        payload: { nextStep: Steps.WEBHOOK, isSubmitting: false },
      });
    }
  };

  return (
    <StyleModal
      disableAutoFocus
      disableEnforceFocus
      open={true}
      aria-labelledby="modal-first-steps-setup"
      aria-describedby="modal-setup-with-first-steps"
    >
      <Container>
        <Header data-testid={`firstStepsSetupTitle-${title}`}>{title}</Header>
        <Formik enableReinitialize initialValues={values} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, touched }: FormikProps<ProviderItemValidate>) => (
            <Form>
              <ModalContainerBody>
                <ModalHeader
                  title="Provedor de frete"
                  description="Insira abaixo os dados do seu provedor de frete e URL válida"
                  tooltipTitle="Acesse estas informações em Frete > Provedor de Frete"
                  onPress={onTicketHistoryClick}
                />

                {fetching && (
                  <Grid container justifyContent="center">
                    <CircularProgress />
                  </Grid>
                )}
                {!fetching && (
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        name="name"
                        label="Nome"
                        fullWidth
                        value={values.name}
                        inputProps={{ maxLength: 150 }}
                        disabled={isDisabled}
                        onChange={handleChange}
                        error={Boolean(errors.name) && touched.name}
                        helperText={errors.name && touched.name ? errors.name : null}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        name="url"
                        label="URL"
                        fullWidth
                        value={values.url}
                        inputProps={{ maxLength: 150 }}
                        disabled={isDisabled}
                        onChange={handleChange}
                        error={(Boolean(errors.url) && touched.url) || error}
                        helperText={errors.url && touched.url ? errors.url : null}
                      />
                    </Grid>
                  </Grid>
                )}
                <HelperContainer>
                  <Grid item md={12}>
                    {error && (
                      <WarningLabel message="Ocorreu um erro. Verifique a URL ou informe um SKU válido e tente novamente" />
                    )}
                  </Grid>
                </HelperContainer>
                <HelperContainer>
                  <HelpTextBold>Precisa de ajuda? </HelpTextBold>
                  <HelpText>Entre em contato com o nosso </HelpText>
                  <LinkHelperUnderline onClick={() => onSupportClick()}>Suporte.</LinkHelperUnderline>
                </HelperContainer>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <BackButton onClick={() => backStep()} color="default" type="button" startIcon={<ArrowLeftIcon />}>
                    Voltar
                  </BackButton>
                  <Button variant="contained" color="primary" type="submit" style={{ marginBottom: 20, marginTop: 20 }}>
                    Continuar
                  </Button>
                </Box>
              </ModalContainerBody>
            </Form>
          )}
        </Formik>
      </Container>
    </StyleModal>
  );
};

export default FreightProviderModal;
