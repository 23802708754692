import React, { lazy } from 'react';
import { UserPermission } from '../@types/auth';

const importPageByPath = (path: string) => lazy(async () => await import(`./${path}`));

/*

  @publicAccess - O acesso às rotas, por padrão, é sempre restrita! Caso queira deixar alguma visualização de página pública, basta adicionar a propriedade publicAccess como true.

  @requiredUserPermissions - É obrigatório a informação do campo requiredUserPermissions para o sistema verificar a role que o usuário precisa possuir para acessar a rota (aqui utilizamos a role que vem no resourceAccess dele). Caso ele não precise ter nenhuma role específica, veja a flag a seguir.

  @noExtraPermissionNeeded - Caso você não precise verificar as roles do usuário para liberar o acesso à rota, deixe essa flag como true.

 */

interface Route {
  path: string;
  component: React.FC;
  exact?: boolean;
  publicAccess?: boolean;
  requiredUserPermissions: UserPermission;
}

interface Props {
  featureToggleUpdate: boolean;
  featureToggleSAC: boolean;
}

const routes = ({ featureToggleUpdate, featureToggleSAC }: Props): Route[] => [
  {
    path: '/dashboard',
    component: importPageByPath('Dashboard/Dashboard'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: 'seller-view',
    },
    exact: true,
  },
  {
    path: '/produtos/sku/:skuId',
    component: importPageByPath('Products/Sku'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-catalog', role: 'product-view' },
    exact: true,
  },
  {
    path: '/produtos',
    component: importPageByPath('Products/Products'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-catalog', role: 'product-view' },
    exact: true,
  },
  {
    path: '/produtos/:id',
    component: importPageByPath('Products/Product'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-catalog', role: 'product-view' },
  },
  {
    path: '/pedidos/:id',
    component: importPageByPath('OrderDetails/OrderDetails'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-order-sale', role: 'order-view' },
  },
  {
    path: '/pedidos',
    component: importPageByPath('Orders/Orders'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-order-sale', role: 'order-view' },
    exact: true,
  },
  {
    path: '/pedidos-atualizacao-em-lote',
    component: importPageByPath('Orders/Update/Update'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-order-sale',
      role: 'order-view',
      businessParams: featureToggleUpdate,
    },
  },
  {
    path: '/pedidos-conta-lite',
    component: importPageByPath('LiteAccount/Orders/Orders'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-order-sale', role: 'lite-account-order-manage' },
    exact: true,
  },
  {
    path: '/termos-de-aceite',
    component: importPageByPath('AcceptTerms/AcceptTerms'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
  },
  {
    path: '/configuracoes/usuarios',
    component: importPageByPath('UserSettings/Users/Users'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-user-management', role: 'user-view' },
  },
  {
    path: '/configuracoes/usuario/adicionar',
    component: importPageByPath('UserSettings/Users/UserAdd'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-user-management', role: 'user-manage' },
  },
  {
    path: '/configuracoes/usuario/editar/:id',
    component: importPageByPath('UserSettings/Users/UserEdit'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-user-management', role: 'user-manage' },
  },
  {
    path: '/configuracoes/parametros-de-integracao',
    component: importPageByPath('UserSettings/PlatformValues'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-seller', role: 'platform-value-manage' },
    exact: true,
  },
  {
    path: '/configuracoes/configuracoes-de-integracao',
    component: importPageByPath('UserSettings/IntegrationSettings'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-user-management', role: 'client-secret-view' },
    exact: true,
  },
  {
    path: '/configuracoes/webhook-de-notificacoes',
    component: importPageByPath('UserSettings/Webhook'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-seller', role: 'seller-view' },
    exact: true,
  },
  {
    path: '/configuracoes/webhook-de-notificacoes/adicionar',
    component: importPageByPath('UserSettings/Webhook/WebhookAdd'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-seller', role: 'webhook-manage' },
    exact: true,
  },
  {
    path: '/configuracoes/webhook-de-notificacoes/editar',
    component: importPageByPath('UserSettings/Webhook/WebhookEdit'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-seller', role: 'webhook-manage' },
    exact: true,
  },
  {
    path: '/central-do-seller',
    component: importPageByPath('SellerCentral/SellerCentral'),
    exact: true,
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-seller', role: 'seller-contract-view' },
  },
  {
    path: '/central-do-seller/contratos',
    component: importPageByPath('SellerCentral/Contracts/Contracts'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-seller', role: 'seller-contract-view' },
  },
  {
    path: '/provedor-de-frete',
    component: importPageByPath('Provider/ShippingProvider'),
    exact: true,
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-freight-management', role: 'provider-view' },
  },
  {
    path: '/provedor-de-frete/adicionar',
    component: importPageByPath('Provider/ProviderEdit'),
    exact: true,
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-freight-management', role: 'provider-manage' },
  },
  {
    path: '/provedor-de-frete/editar/:id',
    component: importPageByPath('Provider/ProviderEdit'),
    exact: true,
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-freight-management', role: 'provider-manage' },
  },
  {
    path: '/financeiro/conciliacao',
    component: importPageByPath('Financial/Conciliation'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-financial', role: 'financial-view' },
  },
  {
    path: '/financeiro/comissionamento',
    component: importPageByPath('Financial/Commission'),
    requiredUserPermissions: { api: 'mktp-mktp-odis-api-commission', role: 'commission-view' },
  },
  {
    path: '/sac/central-de-atendimento',
    component: importPageByPath('SAC/CallCenter'),
    requiredUserPermissions: {
      api: 'mktp-api-zendesk',
      role: 'ticket-view',
      businessParams: featureToggleSAC,
    },
  },
  {
    path: '/central-de-atendimento/ticket/:id',
    component: importPageByPath('SAC/TicketDetails/TicketDetails'),
    requiredUserPermissions: {
      api: 'mktp-api-zendesk',
      role: 'ticket-view',
    },
  },
  {
    path: '/central-acoes/downloads',
    component: importPageByPath('ActionCenter/Downloads'),
    requiredUserPermissions: {
      api: 'mktp-mktp-odis-api-seller',
      role: 'seller-view',
    },
  },
  {
    path: '/',
    component: importPageByPath('Home/Home'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    exact: true,
  },
  {
    path: '/error',
    component: importPageByPath('Page500/Page500'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    publicAccess: true,
  },
  {
    path: '/acesso-negado',
    component: importPageByPath('Page403/Page403'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    publicAccess: true,
  },
  {
    path: '*',
    component: importPageByPath('Page404/Page404'),
    requiredUserPermissions: { noExtraPermissionNeeded: true },
    publicAccess: true,
  },
];

export default routes;
