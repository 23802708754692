import { Reducer } from 'react';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { IError } from '../../../@types';
import { ExportAction, OrdersAction, OrdersUpdateState, SendFileAction } from '../../../@types/ordersUpdate';
import { api } from '../../../services/api';

const { REACT_APP_ORDER_SALE_API_URL, REACT_APP_CORE_SERVICE } = process.env;

const initialState: OrdersUpdateState = {
  fetching: false,
};

const ordersUpdateReducer: Reducer<OrdersUpdateState, OrdersAction<any | ExportAction | SendFileAction>> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@ordersUpdate/EXPORT':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* ordersUpdateExport({ payload: { callback, ...payload } }: ExportAction) {
  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Solicitando planilha...',
      type: 'loading',
      open: true,
    },
  });
  try {
    yield call(api.post, `${REACT_APP_ORDER_SALE_API_URL}/orders/exports`, payload);

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Planilha foi solicitada e será enviada por e-mail',
        type: 'success',
        open: true,
      },
    });
    callback && callback('planilha-exportada-com-sucesso');
  } catch (error) {
    const e = error as IError;

    if (e?.status === 409) {
      callback && callback('erro-ao-exportar-planilha-tente-novamente');
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Selecione um período ou status diferente para exportar novamente.',
          type: 'warning',
          open: true,
        },
      });
    } else {
      callback && callback('erro-ao-exportar-planilha');
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Ocorreu um erro ao tentar enviar a solicitação!',
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* sendContent({ payload: { callback, ...payload } }: SendFileAction) {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileType', payload.fileType);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    transformRequest: (formData: FormData) => formData,
  };

  yield put({
    type: '@layout/FETCH_SNACKBAR_MESSAGE',
    payload: {
      message: 'Realizando upload, isso pode demorar um pouco. Confira o resultado aqui mais tarde.',
      type: 'loading',
      open: true,
    },
  });

  try {
    yield call(api.post, `${REACT_APP_CORE_SERVICE}/files/uploads`, formData, config);
    callback && callback('status-atualizado-com-sucesso');
  } catch (error) {
    callback && callback('erro-ao-atualizar-status');
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Operação não efetuada! Tamanho máximo de arquivo permitido: 5MB!',
        type: 'error',
        open: true,
      },
    });
  }
}

function* ordersUpdateSaga() {
  yield takeLatest('@ordersUpdate/EXPORT', ordersUpdateExport);
  yield takeEvery('@ordersUpdate/SEND_FILE', sendContent);
}

export { ordersUpdateReducer, ordersUpdateSaga };
