/* eslint-disable no-console */
import React from 'react';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

interface Rule {
  text: string;
  hasError: boolean;
  hasSuccess: boolean;
}

interface PasswordTextFeedbackProps {
  item: Rule;
  hasMatchPasswordError: boolean;
}

interface PasswordFeedbackProps {
  rules: Rule[];
  hasMatchPasswordError: boolean;
}

const checkColor = (hasError: boolean, hasSuccess: boolean, hasMatchPasswordError: boolean) => {
  if (hasMatchPasswordError) {
    return '#808080';
  }
  if (hasError) {
    return 'red';
  }
  if (hasSuccess) {
    return 'green';
  }
  return '';
};

const PasswordTextFeedback: React.FC<PasswordTextFeedbackProps> = ({ item, hasMatchPasswordError }) => {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {item.hasSuccess && !hasMatchPasswordError && <DoneIcon style={{ color: 'green' }} />}
      {item.hasError && !hasMatchPasswordError && <CloseIcon style={{ color: 'red' }} />}
      <span style={{ fontSize: 14, color: checkColor(item.hasError, item.hasSuccess, hasMatchPasswordError) }}>
        {item.text}
      </span>
    </Box>
  );
};

const PasswordFeedback: React.FC<PasswordFeedbackProps | any> = ({ rules, hasMatchPasswordError }) => {
  return (
    <Box style={{ background: '#fafafa', borderRadius: 4, padding: 20, marginTop: 20 }}>
      <p>Senha deverá seguir os seguintes critérios:</p>
      {rules?.map((item: Rule, key: string) => (
        <PasswordTextFeedback item={item} key={key} hasMatchPasswordError={hasMatchPasswordError} />
      ))}
    </Box>
  );
};

export default PasswordFeedback;
