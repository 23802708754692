import { Reducer } from 'react';
import type { Action } from 'redux';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { IError, PaginationResponse } from '../../../@types';
import { NotificationState, Notification, ReadNotificationsRequest } from '../../../@types/notifications';
import { api } from '../../../services/api';

const API_SERVICE = `${process.env.REACT_APP_NOTIFICATION_API_URL}`;

export type NotificationsActionsType = {
  type: string;
  payload: {
    items: Notification[];
  };
};

const initialState: NotificationState = {
  items: [],
  hasNewNotifications: false,
};

export type NotificationsAction<T> = Action<string> & {
  payload: T;
};

export type readNotificationsAction = NotificationsAction<ReadNotificationsRequest>;

const notificationsReducer: Reducer<NotificationState, NotificationsActionsType> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@notifications/FETCH_NOTIFICATIONS': {
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    }
    case '@notifications/READ_NOTIFICATIONS': {
      return {
        ...state,
        ...payload,
      };
    }
    case '@notifications/READ_NOTIFICATIONS_SUCCESS':
    case '@notifications/READ_NOTIFICATIONS_ERROR':
    case '@notifications/FETCH_NOTIFICATIONS_SUCCESS':
    case '@notifications/FETCH_NOTIFICATIONS_ERROR':
    case '@notifications/ADD_NOTIFICATION_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};
function* listNotifications() {
  try {
    const response: PaginationResponse<{ readConfirmation: boolean }> = yield call(
      api.get,
      `${API_SERVICE}/notifications?page-index=0&page-size=15`,
    );

    yield put({
      type: '@notifications/FETCH_NOTIFICATIONS_SUCCESS',
      payload: {
        ...response.data,
        hasNewNotifications:
          response.data.items.filter((item: { readConfirmation: boolean }) => item.readConfirmation === false)
            .length !== 0,
        success: response.success,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@notifications/FETCH_NOTIFICATIONS_ERROR',
      payload: {
        items: [],
        errorMessages: e.errorMessages,
        success: e.success,
      },
    });
  } finally {
    if (sessionStorage.getItem('mktp_integration_notification') === 'true') {
      yield put({
        type: '@notifications/ADD_NOTIFICATION',
      });
    }
  }
}

function* readNotifications({ payload: { notificationsIds } }: readNotificationsAction) {
  try {
    const notifications = notificationsIds.filter((item) => {
      item === '-1' && sessionStorage.setItem('mktp_integration_notification', 'false');

      return item !== '-1';
    });

    if (notifications.length) {
      yield call(api.post, `${API_SERVICE}/notifications/read-confirmations`, {
        notifications,
      });
    }

    yield put({
      type: '@notifications/READ_NOTIFICATIONS_SUCCESS',
      payload: {
        hasNewNotifications: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@notifications/READ_NOTIFICATIONS_ERROR',
    });
  }
}

function* addNotification() {
  const { notifications } = yield select();

  yield put({
    type: '@notifications/ADD_NOTIFICATION_SUCCESS',
    payload: {
      items: [
        {
          id: '-1',
          title: 'Integração em andamento!',
          description: 'Pode demorar algumas horas. Você pode conferir o resultado através da Gestão de Produtos.',
          notificationType: 'Onboarding',
          urlRedirect: '/produtos',
          origin: 'Gestão de produtos',
          createdAt: new Date(),
        },
        ...notifications.items,
      ],
    },
  });
}

function* notificationsSaga() {
  yield takeLatest('@notifications/FETCH_NOTIFICATIONS', listNotifications);
  yield takeLatest('@notifications/READ_NOTIFICATIONS', readNotifications);
  yield takeLatest('@notifications/ADD_NOTIFICATION', addNotification);
}

export { notificationsReducer, notificationsSaga };
