import YouTube from 'react-youtube';
import styled from 'styled-components';
import { device } from '../../../shared/utils/device';

export const Footer = styled.div`
  display: flex;
  margin-top: 27px;
  justify-content: flex-end;
`;

export const Player = styled(YouTube)`
  width: 693px;
  height: 389px;

  @media (${device.laptop}) {
    width: 693px;
    height: 389px;
  }

  @media (${device.tablet}) and (max-width: 1023px) {
    width: 100%;
    min-width: 500px;
    height: 389px;
  }

  @media (max-width: 767px) {
    width: 316px !important;
    height: 180px !important;
  }
`;
