import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorMessages, IError } from '../../../@types';
import { IntegrationSettingsResponse, IntegrationSettingsRequest } from '../../../@types/users';
import { api } from '../../../services/api';

const { REACT_APP_USER_MANAGEMENT_API_URL } = process.env;

export type IntegrationSettingsState = {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  clientId: string;
  secret: string;
  error: boolean;
};

export type IntegrationSettingsActions<T> = Action<string> & {
  payload: T;
};

export type FetchIntegrationSettingsAction = IntegrationSettingsActions<IntegrationSettingsRequest>;

const initialState: IntegrationSettingsState = {
  fetching: false,
  success: false,
  errorMessages: [],
  clientId: '',
  secret: '',
  error: false,
};

const integrationSettingsReducer: Reducer<IntegrationSettingsState, FetchIntegrationSettingsAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@integrationSettings/FETCH':
    case '@platformValues/FETCH':
      return { ...state, ...payload, fetching: true };
    case '@platformValues/FETCH_SUCCESS':
      return { ...state, ...payload };
    case '@platformValues/FETCH_ERROR':
      return { ...state, ...payload };
    default:
      return state;
  }
};

function* fetchIntegrationSettings({ payload: { sellerId } }: FetchIntegrationSettingsAction) {
  try {
    const response: IntegrationSettingsResponse = yield call(
      api.get,
      `${REACT_APP_USER_MANAGEMENT_API_URL}/clients/${sellerId}/secrets`,
    );

    yield put({
      type: '@platformValues/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        clientId: response.data.clientId,
        secret: response.data.secret,
        error: false,
      },
    });
  } catch (error) {
    const e = error as IError;

    if (e?.status === 404) {
      yield put({
        type: '@platformValues/FETCH_ERROR',
        payload: {
          ...initialState,
          error: true,
          errorMessages: e?.data?.erroMessages || [
            {
              message: 'Client não encontrado',
              code: '404',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@platformValues/FETCH_ERROR',
        payload: {
          ...initialState,
          error: true,
          errorMessages: e?.data?.erroMessages || [
            {
              message: 'Serviço indisponível! Tente novamente mais tarde!',
            },
          ],
        },
      });
    }
  }
}

function* integrationSettingsSaga() {
  yield takeLatest('@integrationSettings/FETCH', fetchIntegrationSettings);
}

export { integrationSettingsReducer, integrationSettingsSaga };
