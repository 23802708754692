import { UserPermission } from '../../@types/auth';

const verifyPermissionToManage = (
  userPermission: UserPermission,
  isLiteAccount?: boolean,
  path?: string,
  isVtexOrAnymarket?: boolean,
) => {
  const resourceAccess = JSON.parse(sessionStorage.getItem('mktp_resourceAccess') || '{}');
  const { noExtraPermissionNeeded, api, role, businessParams } = userPermission;
  // Show OrdersAccountLite and OrderDetails
  if (isLiteAccount && role === 'order-view' && path !== '/pedidos/:id') {
    return false;
  }

  // Don't show settings parameter for Sellers Vtex or Anymarket
  if (isVtexOrAnymarket && path === '/configuracoes/configuracoes-de-integracao') {
    return false;
  }

  // Don't show SellerCentral/Contracts/Contracts
  if (isLiteAccount && role === 'seller-contract-view') {
    return false;
  }

  // Don't show UserSettings/PlatformValues
  if (isLiteAccount && role === 'platform-value-manage') {
    return false;
  }

  // Don't show Financial/Conciliation and Financial/Commission
  if (isLiteAccount && role === 'commission-view') {
    return false;
  }

  // Don't show OrdersAccountLite
  if (!isLiteAccount && role === 'lite-account-order-manage') {
    return false;
  }

  if (noExtraPermissionNeeded) {
    return true;
  }

  if (!api || !role) return false;

  return typeof businessParams === 'boolean'
    ? businessParams && resourceAccess[api]?.roles?.includes(role)
    : resourceAccess[api]?.roles?.includes(role);
};

export default verifyPermissionToManage;
