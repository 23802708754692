import type { Reducer } from 'react';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { BusinessParamsResponse } from '../../../@types/businessParams';
import {
  DeliveryFailureState,
  DeliveryFailureResponse,
  DeliveryFailureRequest,
  DeliveryFailureConfirmationRequest,
  DeliveryFailureFeatureToggleRequest,
} from '../../../@types/deliveryFailure';
import { api } from '../../../services/api';

const { REACT_APP_ORDER_SALE_API_URL, REACT_APP_BUSINESS_PARAMS_API_URL } = process.env;

const initialState: DeliveryFailureState = {
  fetching: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  reason: [],
  featureToggleDeliveryFailure: false,
};

const reducer: Reducer<DeliveryFailureState, DeliveryFailureRequest | DeliveryFailureConfirmationRequest> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@deliveryFailure/CONFIRM_FAILURE_REASONS':
      return {
        ...state,
        fetching: true,
      };
    case '@deliveryFailure/GET_FAILURE_REASONS_SUCCESS': {
      const newItems = [...state.items, ...payload.items];
      return {
        ...state,
        ...payload,
        items: newItems,
      };
    }

    case '@deliveryFailure/GET_FAILURE_REASONS_ERROR':
    case '@deliveryFailure/CONFIRM_FAILURE_REASONS_SUCCESS':
    case '@deliveryFailure/CONFIRM_FAILURE_REASONS_ERROR':
    case '@deliveryFailure/FEATURE_TOGGLE_SUCCESS':
    case '@deliveryFailure/FEATURE_TOGGLE_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* getFailureReasons({ payload: { pageIndex = 0, pageSize = 10 } }: DeliveryFailureRequest) {
  try {
    const response: DeliveryFailureResponse = yield call(
      api.get,
      `${REACT_APP_ORDER_SALE_API_URL}/deliveries-failure`,
      {
        params: {
          'page-index': pageIndex,
          'page-size': pageSize,
        },
      },
    );

    if (response.success) {
      yield put({
        type: '@deliveryFailure/GET_FAILURE_REASONS_SUCCESS',
        payload: {
          errorMessages: response.errorMessages,
          fetching: false,
          ...response.data,
          pageIndex: response.data.pageIndex + 1,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@deliveryFailure/GET_FAILURE_REASONS_ERROR',
      payload: {
        fetching: false,
      },
    });
  }
}

function* confirmFailureReasons({ payload: { reason, id } }: DeliveryFailureConfirmationRequest) {
  try {
    const response: DeliveryFailureResponse = yield call(api.patch, `${REACT_APP_ORDER_SALE_API_URL}/packages/${id}`, {
      deliveryFailureId: reason,
    });

    if (response.success) {
      yield put({
        type: '@deliveryFailure/CONFIRM_FAILURE_REASONS_SUCCESS',
        payload: {
          success: true,
        },
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: 'Status atualizado com sucesso!',
          type: 'success',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@deliveryFailure/CONFIRM_FAILURE_REASONS_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* featureToggleDeliveryFail({ payload: { sellerId } }: DeliveryFailureFeatureToggleRequest) {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableDeliveryFailed/rules-exceptions?context-values=${sellerId}`,
    );
    const featureToggleDeliveryFailure = data?.ruleExceptions?.length
      ? data.ruleExceptions[0].value === 'true'
      : data?.defaultValue === 'true';
    yield put({
      type: '@deliveryFailure/FEATURE_TOGGLE_SUCCESS',
      payload: { featureToggleDeliveryFailure },
    });
  } catch (error) {
    yield put({
      type: '@deliveryFailure/FEATURE_TOGGLE_ERROR',
      payload: { featureToggleDeliveryFailure: false },
    });
  }
}

function* saga() {
  yield takeLatest('@deliveryFailure/GET_FAILURE_REASONS', getFailureReasons);
  yield takeEvery('@deliveryFailure/CONFIRM_FAILURE_REASONS', confirmFailureReasons);
  yield takeEvery('@deliveryFailure/GET_FEATURE_TOGGLE', featureToggleDeliveryFail);
}

export { reducer as deliveryFailureReducer, saga as deliveryFailureSaga };
