import type { Reducer } from 'react';
import type { Action } from 'redux';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import type { ErrorMessages, Pagination, IError, DataResponse } from '../../../@types';
import { BusinessParamsResponse } from '../../../@types/businessParams';
import type {
  FetchExportsAction,
  GetOrderByIdRequest,
  HandleUpdateReturn,
  Order,
  OrderCtxIDResponse,
  OrdersExports,
  OrdersExportsResponse,
  OrdersRequest,
  SendFileAction,
  UpdateQuantityPatch,
  OrderResponse,
  CancelOrderRequest,
  PaginationOrderResponse,
  CancelOrderFeatureToggleRequest,
  OrderDashboard,
  OrderDashboardResponse,
} from '../../../@types/orders';
import type { PackagesByOrderIdRequest, PackagesResponse, SKUDetailsResponse } from '../../../@types/packages';
import type { PaymentsByOrderIdRequest, PaymentsResponse } from '../../../@types/payments';
import { api } from '../../../services/api';
import isValidFilter from '../../../shared/utils/isValidFilter';
import { store } from '../../index';
import { Packages, SKUDetails } from './../../../@types/packages.d';

const {
  REACT_APP_ORDER_SALE_API_URL,
  REACT_APP_CATALOG_API_URL,
  REACT_APP_CORE_SERVICE,
  REACT_APP_BUSINESS_PARAMS_API_URL,
  REACT_APP_DATABRICKS_URL,
  REACT_APP_REPORTS_URL,
} = process.env;

const API_SERVICE = REACT_APP_ORDER_SALE_API_URL;
const CATALOG_API_SERVICE = REACT_APP_CATALOG_API_URL;
const CORE_SERVICE = REACT_APP_CORE_SERVICE;

export type OrdersState = Pagination<Order | OrdersExports> & {
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  selectedOrder?: Order;
  update?: HandleUpdateReturn;
  featureToggleEnableCancelOrder: boolean;
  errorMessagesDevolution?: any;
  isMessageDevolutionError?: boolean;
  countDevolutionRequests: number;
  ordersDashboard?: OrderDashboard;
  requestSentSuccess?: boolean;
  featureToggleEnableOrderReport: boolean;
  featureToggleEnableOrderStatusUpdateReport: boolean;
  featureToggleEnableExportProductsReport: boolean;
  filePath?: Blob;
};

export type PackagesByOrderIdState = OrdersState & {
  orderId: string;
};

export type UpdatePaymentRequest = {
  orderCode: string;
  sellerId: string;
  isApproval: boolean;
};

export type OrdersAction<T> = Action<string> & {
  payload: T;
};

export type FetchOrderAction = OrdersAction<OrdersRequest>;

export type FetchPackagesByOrderIdAction = OrdersAction<PackagesByOrderIdRequest>;

export type FetchPaymentsByOrderIdAction = OrdersAction<PaymentsByOrderIdRequest>;

export type FetchExportsByRowKeyAction = OrdersAction<{ rowKey: string }>;

export type GetOrderByIdAction = OrdersAction<GetOrderByIdRequest>;

export type GetOrderByContextAction = OrdersAction<{ order: string }>;

export type UpdateQuantityAction = OrdersAction<UpdateQuantityPatch>;

export type UpdatePaymentOrderLiteAccount = OrdersAction<UpdatePaymentRequest>;

const initialState: OrdersState = {
  fetching: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  selectedOrder: undefined,
  featureToggleEnableCancelOrder: false,
  featureToggleEnableOrderReport: false,
  featureToggleEnableOrderStatusUpdateReport: false,
  featureToggleEnableExportProductsReport: false,
  isMessageDevolutionError: false,
  countDevolutionRequests: 0,
  errorMessagesDevolution: [],
  ordersDashboard: {
    consolidatedOrders: [],
    executionDate: '',
    overdueQuantity: 0,
    sellerId: '',
  },
  requestSentSuccess: false,
  filePath: undefined,
};

const reducer: Reducer<
  OrdersState | PackagesByOrderIdState,
  OrdersAction<FetchOrderAction | FetchPackagesByOrderIdAction | UpdateQuantityPatch | FetchExportsAction>
> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@order/REQUEST_INIT':
      return {
        ...state,
        fetching: true,
      };
    case '@orders/FETCH':
    case '@order/FETCH_PACKAGES_BY_ORDER_ID':
    case '@order/GET_BY_ID':
    case '@order/FETCH_PAYMENTS_BY_ORDER_ID':
    case '@sellers/SEND_FILE':
    case '@order/ORDERS_EXPORTS':
    case '@order/GET_BY_CONTEXT':
    case '@order/FETCH_DASHBOARD_ORDERS':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@order/ORDERS_EXPORTS_BY_ROWKEY_SUCCESS':
    case '@order/ORDERS_EXPORTS_BY_ROWKEY_ERROR':
      return {
        ...state,
        ...payload,
      };
    case '@order/CANCEL_SUCCESS':
    case '@order/CANCEL_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@order/REPORT_REQUEST_SENT_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
        requestSentSuccess: true,
      };
    case '@orders/FETCH_INITIAL':
    case '@orders/FETCH_SUCCESS':
    case '@order/FETCH_PACKAGES_BY_ORDER_ID_SUCCESS':
    case '@order/GET_BY_ID_SUCCESS':
    case '@order/FETCH_PAYMENTS_BY_ORDER_ID_SUCCESS':
    case '@order/ORDERS_EXPORTS_SUCCESS':
    case '@order/FEATURE_TOGGLE_CANCEL_ORDER_SUCCESS':
    case '@order/FETCH_DASHBOARD_ORDERS_SUCCESS':
    case '@order/FEATURE_TOGGLE_ENABLE_ORDER_REPORT_SUCCESS':
    case '@order/FT_ENABLE_ORDER_STATUS_UPDATE_REPORT_SUCCESS':
    case '@order/FT_ENABLE_EXPORT_PRODUCTS_REPORT_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: true,
      };
    case '@orders/FETCH_ERROR':
    case '@order/FETCH_PACKAGES_BY_ORDER_ID_ERROR':
    case '@order/GET_BY_ID_ERROR':
    case '@order/FETCH_PAYMENTS_BY_ORDER_ID_ERROR':
    case '@order/ORDERS_EXPORTS_ERROR':
    case '@order/GET_BY_CONTEXT_ERROR':
    case '@order/FEATURE_TOGGLE_CANCEL_ORDER_ERROR':
    case '@order/FETCH_DASHBOARD_ORDERS_ERROR':
    case '@order/REPORT_REQUEST_SENT_ERROR':
    case '@order/FT_ENABLE_ORDER_STATUS_UPDATE_REPORT_ERROR':
    case '@order/FT_ENABLE_EXPORT_PRODUCTS_REPORT_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        success: false,
      };
    case '@order/UPDATE_QUANTITY':
      return {
        ...state,
        ...payload,
        success: false,
      };
    case '@order/UPDATE_QUANTITY_SUCCESS':
      return {
        ...state,
        ...payload,
        success: true,
        countDevolutionRequests: state.countDevolutionRequests + 1,
      };
    case '@order/UPDATE_QUANTITY_ERROR':
      return {
        ...state,
        countDevolutionRequests: state.countDevolutionRequests + 1,
        isMessageDevolutionError: true,
        errorMessagesDevolution: [...state.errorMessagesDevolution, [payload.errorMessagesDevolution]],
      };
    case '@order/CLEAN_REPORT_DATA':
      return {
        ...state,
        ...payload,
        requestSentSuccess: false,
      };
    case '@order/CLEAR':
      return {
        ...initialState,
      };
    case '@order/CLEAN_DEVOLUTIONS_ORDERS':
      return {
        ...state,
        countDevolutionRequests: 0,
        isMessageDevolutionError: false,
        errorMessagesDevolution: [],
      };
    default:
      return state;
  }
};

function* fetchOrders({
  payload: { pageIndex = 0, pageSize = 20, search, StartDate, EndDate, status },
}: FetchOrderAction) {
  const result = isValidFilter({
    search,
    status,
  });

  try {
    const payload: PaginationOrderResponse = yield call(api.get, `${API_SERVICE}/portals/orders`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
        search,
        'start-date': StartDate,
        'end-date': EndDate,
        status,
      },
    });

    yield put({
      type: '@orders/FETCH_SUCCESS',
      payload: {
        success: payload.success,
        errorMessages: payload.errorMessages,
        fetching: false,
        ...payload.data,
      },
    });

    yield put({
      type: '@orders/FETCH_INITIAL',
      payload: {
        initial: false,
      },
    });
  } catch (error) {
    const e = error as IError;

    if (e.status === 404) {
      const [errorMessage] = e.data.errorMessages;
      yield put({
        type: '@orders/FETCH_ERROR',
        payload: {
          items: [],
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: !result
                ? 'Não existem pedidos para listar.'
                : 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@orders/FETCH_ERROR',
        payload: {
          items: [],
          ...e.data,
        },
      });
    }
  }
}

function* updateQuantity({ payload: { id, packageId, quantityReturned } }: UpdateQuantityAction) {
  try {
    const response: DataResponse<{}> = yield call(api.patch, `${API_SERVICE}/packages/${packageId}/items/${id}`, {
      quantityReturned,
    });

    yield put({
      type: '@order/UPDATE_QUANTITY_SUCCESS',
      payload: {
        update: {
          success: response.success,
          error: false,
        },
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Devolução concluida com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (err: any) {
    const errorMessage = err?.data?.errorMessages?.[0]?.message;
    if (errorMessage.includes('SKU')) {
      yield put({
        type: '@order/UPDATE_QUANTITY_ERROR',
        payload: {
          errorMessagesDevolution: err?.data?.errorMessages?.[0]?.message,
        },
      });
    } else {
      yield put({
        type: '@order/UPDATE_QUANTITY_ERROR',
        payload: {
          update: {
            success: false,
            error: true,
          },
        },
      });
    }
  }
}

function* getOrderById({ payload: { id, sellerId, fromTicket } }: GetOrderByIdAction) {
  try {
    const payload: OrderResponse = yield call(api.get, `${API_SERVICE}/orders/${id}`);

    let packages = [];
    let payments: any[] = [];

    if (fromTicket) {
      const packagesResponse: PackagesResponse = yield call(api.get, `${API_SERVICE}/orders/${id}/packages`, {
        params: {
          'page-index': 0,
          'page-size': 20,
        },
      });
      packages = packagesResponse?.data?.items ?? [];
    } else {
      const {
        packagesResponse,
        paymentsResponse,
      }: { packagesResponse: PackagesResponse; paymentsResponse: PaymentsResponse } = yield all({
        packagesResponse: call(api.get, `${API_SERVICE}/orders/${id}/packages`, {
          params: {
            'page-index': 0,
            'page-size': 20,
          },
        }),
        paymentsResponse: call(api.get, `${API_SERVICE}/orders/${id}/payments`, {
          params: {
            'page-index': 0,
            'page-size': 20,
          },
        }),
      });
      packages = packagesResponse?.data?.items ?? [];
      payments = paymentsResponse?.data?.items ?? [];
    }

    const aux: any = packages;
    const packs: Packages = aux;

    const skus = packs.map((pack) => pack.packageItem?.map((item) => item.sku)).toString();

    try {
      const skusDetails: SKUDetailsResponse = yield call(
        api.get,
        `${CATALOG_API_SERVICE}/portals/skus?seller-id=${sellerId}&skus=${skus}`,
      );

      packs.forEach((pack) => {
        pack.packageItem?.forEach((item) => {
          item.skuDetails = skusDetails.data.find((element: SKUDetails) => element.skuSeller === item.sku);
        });
      });
    } catch (error) {
      // bloco catch vazio para ignorar erros
      // caso a consulta de detalhes dos SKUs retorne algum erro,
      // permitindo a exibição da tela mesmo assim
    }

    yield put({
      type: '@order/GET_BY_ID_SUCCESS',
      payload: {
        success: payload.success,
        errorMessages: payload.errorMessages,
        selectedOrder: { ...payload.data, packages, payments },
        fetching: false,
      },
    });
  } catch (error) {
    const e = error as IError;

    const [errorMessage] = e.data.errorMessages;
    if (e.status === 404) {
      yield put({
        type: '@order/GET_BY_ID_ERROR',
        payload: {
          ...initialState,
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: 'Não existem pedidos de venda para listar.',
            },
          ],
        },
      });
    } else {
      yield put({
        type: '@order/GET_BY_ID_ERROR',
        payload: {
          ...initialState,
          ...e.data,
        },
      });
    }
  }
}

function* fetchPaymentsByOrderId({
  payload: { pageIndex = 0, pageSize = 100, orderId },
}: FetchPaymentsByOrderIdAction) {
  const { orders } = yield select();
  const [order] = orders.items.filter(({ id }: { id: string }) => id === orderId);

  try {
    const payload: PaginationOrderResponse = yield call(api.get, `${API_SERVICE}/orders/${orderId}/payments`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
      },
    });

    const items = [
      ...orders.items.filter(({ id }: { id: string }) => id !== orderId),
      { ...order, payments: [...payload.data.items] },
    ];

    yield put({
      type: '@order/FETCH_PAYMENTS_BY_ORDER_ID_SUCCESS',
      payload: {
        success: payload.success,
        errorMessages: payload.errorMessages,
        fetching: false,
        items,
      },
    });
  } catch (error) {
    const e = error as IError;

    const [errorMessage] = e.data.errorMessages;
    if (e.status === 404) {
      yield put({
        type: '@order/FETCH_PAYMENTS_BY_ORDER_ID_ERROR',
        payload: {
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: 'Não existem pedidos de venda para listar.',
            },
          ],
          items: [],
          fetching: false,
        },
      });
    } else {
      yield put({
        type: '@order/FETCH_PAYMENTS_BY_ORDER_ID_ERROR',
        payload: {
          ...e.data,
          items: [],
          fetching: false,
        },
      });
    }
  }
}

function* fetchPackagesByOrderId({
  payload: { pageIndex = 0, pageSize = 100, orderId, PackagesStatus },
}: FetchPackagesByOrderIdAction) {
  const { orders } = yield select();
  const [order] = orders.items.filter(({ id }: { id: string }) => id === orderId);

  try {
    const payload: PackagesResponse = yield call(api.get, `${API_SERVICE}/orders/${orderId}/packages`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
        PackagesStatus,
      },
    });

    const items = [
      ...orders.items.filter(({ id }: { id: string }) => id !== orderId),
      { ...order, packages: [...payload.data.items] },
    ];

    yield put({
      type: '@order/FETCH_PACKAGES_BY_ORDER_ID_SUCCESS',
      payload: {
        success: payload.success,
        errorMessages: payload.errorMessages,
        fetching: false,
        items,
      },
    });
  } catch (error) {
    const e = error as IError;

    const [errorMessage] = e.data.errorMessages;
    if (e?.status === 404) {
      yield put({
        type: '@order/FETCH_PACKAGES_BY_ORDER_ID_ERROR',
        payload: {
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: 'Não existem pedidos de venda para listar.',
            },
          ],
          items: [],
          fetching: false,
        },
      });
    } else {
      yield put({
        type: '@order/FETCH_PACKAGES_BY_ORDER_ID_ERROR',
        payload: {
          ...e.data,
          items: [],
          fetching: false,
        },
      });
    }
  }
}

function* sendContent({ payload }: SendFileAction) {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('fileType', payload.fileType);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    transformRequest: (formData: FormData) => formData,
  };

  try {
    yield call(api.post, `${CORE_SERVICE}/files/uploads`, formData, config);

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Planilha enviada com sucesso! Acompanhe a atualização de status na listagem de pedidos!',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Operação não efetuada! Tamanho máximo de arquivo permitido: 5MB!',
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateOrderPayment({ payload }: UpdatePaymentOrderLiteAccount) {
  const { sellerId, isApproval, orderCode } = payload;
  const {
    orders: { items },
  } = store.getState();

  try {
    yield call(api.patch, `${API_SERVICE}/lite-accounts/orders/${orderCode}/payments`, {
      sellerId,
      isApproval,
    });
    items.map((order: { orderCode: string; status: string }) => {
      if (order.orderCode === orderCode) {
        order.status = isApproval ? 'Aprovado' : 'Cancelado';
      }
      return order;
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: isApproval ? 'Pagamento do pedido aprovado com sucesso!' : 'Pagamento do pedido recusado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchOrdersExports({ payload: { pageIndex = 0, pageSize = 20 } }: FetchExportsAction) {
  try {
    const {
      seller: { sellerId },
    } = store.getState();

    const response: OrdersExportsResponse = yield call(api.get, `${API_SERVICE}/orders/exports?sellerId=${sellerId}`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
      },
    });

    const items = response?.data.items.map((item: OrdersExports) => {
      item.id = item.uploadDate;
      return item;
    });

    yield put({
      type: '@order/ORDERS_EXPORTS_SUCCESS',
      payload: {
        success: response.success,
        fetching: false,
        ...response?.data,
        items,
      },
    });
  } catch (error) {
    yield put({
      type: '@order/ORDERS_EXPORTS_ERROR',
      payload: {
        ...initialState,
        errorMessages: [
          {
            notFoundHelperText: 'Não existem informações para listar.',
          },
        ],
      },
    });
  }
}

function* fetchOrdersExportsByRowKey({ payload }: FetchExportsByRowKeyAction) {
  if (payload) {
    try {
      const response: Blob = yield call(() =>
        api.get(`${API_SERVICE}/orders/exports/${payload.rowKey}`, {
          responseType: 'blob',
        }),
      );
      if (response) {
        yield put({
          type: '@order/ORDERS_EXPORTS_BY_ROWKEY_SUCCESS',
          payload: {
            filePath: response,
          },
        });
      }
    } catch (error) {
      yield put({
        type: '@order/ORDERS_EXPORTS_BY_ROWKEY_ERROR',
        payload: {
          filePath: undefined,
        },
      });
    }
  }
}

function* getOrderByContext({ payload: { order } }: GetOrderByContextAction) {
  try {
    const orderCtxResp: OrderCtxIDResponse = yield call(
      api.get,
      `${REACT_APP_ORDER_SALE_API_URL}/context/orders/${order}`,
    );
    if (orderCtxResp.success) {
      const { idOrderMktp, sellerId } = orderCtxResp.data;
      yield put({
        type: '@order/GET_BY_ID',
        payload: {
          id: idOrderMktp,
          sellerId,
          fromTicket: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@order/GET_BY_CONTEXT_ERROR',
      payload: {
        fetching: false,
      },
    });
  }
}

function* featureToggleCancelOrder({ payload: { sellerId } }: CancelOrderFeatureToggleRequest) {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableCancelOrder/rules-exceptions?context-values=${sellerId}`,
    );
    const featureToggleEnableCancelOrder = data?.ruleExceptions?.length
      ? data.ruleExceptions[0]?.value === 'true' && data.ruleExceptions[0]?.isActive
      : data?.defaultValue === 'true' && data.isActive;
    yield put({
      type: '@order/FEATURE_TOGGLE_CANCEL_ORDER_SUCCESS',
      payload: { featureToggleEnableCancelOrder },
    });
  } catch (error) {
    yield put({
      type: '@order/FEATURE_TOGGLE_CANCEL_ORDER_ERROR',
      payload: { featureToggleEnableCancelOrder: false },
    });
  }
}

function* cancelOrder({ payload: { sellerId, ...payload } }: CancelOrderRequest) {
  try {
    yield put({ type: '@order/REQUEST_INIT' });
    yield call(api.patch, `${REACT_APP_ORDER_SALE_API_URL}/cancellations`, payload);
    yield put({
      type: '@order/GET_BY_ID',
      payload: {
        id: payload.id,
        sellerId,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Status atualizado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  } catch {
    yield put({ type: '@order/CANCEL_ERROR' });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        fetching: false,
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente.',
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchDashboardOrders() {
  try {
    const response: OrderDashboardResponse = yield call(api.get, `${REACT_APP_DATABRICKS_URL}/dashboard`);
    yield put({
      type: '@order/FETCH_DASHBOARD_ORDERS_SUCCESS',
      payload: {
        ordersDashboard: response?.data,
      },
    });
  } catch (error) {
    const e = error as IError;
    if (e.status === 404) {
      yield put({
        type: '@order/FETCH_DASHBOARD_ORDERS_SUCCESS',
      });
    } else {
      yield put({ type: '@order/FETCH_DASHBOARD_ORDERS_ERROR' });
    }
  }
}

function* sendOrdersReport({ payload: { ...payload } }: any) {
  let message = 'Relatório solicitado com sucesso!';
  let type = 'success';
  try {
    yield call(api.post, `${REACT_APP_REPORTS_URL}/reports`, payload);
    yield put({
      type: '@order/REPORT_REQUEST_SENT_SUCCESS',
    });
  } catch (error) {
    const e = error as IError;
    message = 'Ocorreu um erro ao processar sua solicitação!';
    type = 'error';
    yield put({
      type: '@order/REPORT_REQUEST_SENT_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        requestSentSuccess: false,
      },
    });
  } finally {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message,
        type,
        open: true,
      },
    });
  }
}

function* fetchFeatureToggleEnableOrderReport({ payload: { sellerId } }: CancelOrderFeatureToggleRequest) {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableOrdersReport/rules-exceptions?context-values=${sellerId}`,
    );
    const featureToggleEnableOrderReport = data?.ruleExceptions?.length
      ? data.ruleExceptions[0]?.value === 'true' && data.ruleExceptions[0]?.isActive
      : data?.defaultValue === 'true' && data.isActive;
    yield put({
      type: '@order/FEATURE_TOGGLE_ENABLE_ORDER_REPORT_SUCCESS',
      payload: { featureToggleEnableOrderReport },
    });
  } catch (error) {
    yield put({
      type: '@order/FEATURE_TOGGLE_ENABLE_ORDER_REPORT_ERROR',
      payload: { featureToggleEnableOrderReport: false },
    });
  }
}

function* fetchFeatureToggleEnableOrderStatusUpdateReport({ payload: { sellerId } }: CancelOrderFeatureToggleRequest) {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableOrdersReport/rules-exceptions?context-values=${sellerId}`,
    );
    const featureToggleEnableOrderStatusUpdateReport = data?.ruleExceptions?.length
      ? data.ruleExceptions[0]?.value === 'true' && data.ruleExceptions[0]?.isActive
      : data?.defaultValue === 'true' && data.isActive;
    yield put({
      type: '@order/FT_ENABLE_ORDER_STATUS_UPDATE_REPORT_SUCCESS',
      payload: { featureToggleEnableOrderStatusUpdateReport },
    });
  } catch (error) {
    yield put({
      type: '@order/FT_ENABLE_ORDER_STATUS_UPDATE_REPORT_ERROR',
      payload: { featureToggleEnableOrderStatusUpdateReport: false },
    });
  }
}

function* fetchFeatureToggleEnableExportProductsReport({ payload: { sellerId } }: CancelOrderFeatureToggleRequest) {
  try {
    const { data }: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/FeatureToggle_EnableExportProductsReport/rules-exceptions?context-values=${sellerId}`,
    );
    const featureToggleEnableExportProductsReport = data?.ruleExceptions?.length
      ? data.ruleExceptions[0]?.value === 'true' && data.ruleExceptions[0]?.isActive
      : data?.defaultValue === 'true' && data.isActive;
    yield put({
      type: '@order/FT_ENABLE_EXPORT_PRODUCTS_REPORT_SUCCESS',
      payload: { featureToggleEnableExportProductsReport },
    });
  } catch (error) {
    yield put({
      type: '@order/FT_ENABLE_EXPORT_PRODUCTS_REPORT_ERROR',
      payload: { featureToggleEnableExportProductsReport: false },
    });
  }
}

function* saga() {
  yield takeLatest('@orders/FETCH', fetchOrders);
  yield takeLatest('@order/GET_BY_ID', getOrderById);
  yield takeEvery('@order/UPDATE_QUANTITY', updateQuantity);
  yield takeLatest('@order/FETCH_PACKAGES_BY_ORDER_ID', fetchPackagesByOrderId);
  yield takeLatest('@order/FETCH_PAYMENTS_BY_ORDER_ID', fetchPaymentsByOrderId);
  yield takeEvery('@order/SEND_FILE', sendContent);
  yield takeEvery('@order/UPDATE_ORDER_PAYMENT_ACCOUNT_LITE', updateOrderPayment);
  yield takeLatest('@order/ORDERS_EXPORTS', fetchOrdersExports);
  yield takeLatest('@order/ORDERS_EXPORTS_BY_ROWKEY', fetchOrdersExportsByRowKey);
  yield takeLatest('@order/GET_BY_CONTEXT', getOrderByContext);
  yield takeLatest('@order/FEATURE_TOGGLE_CANCEL_ORDER', featureToggleCancelOrder);
  yield takeLatest('@order/CANCEL', cancelOrder);
  yield takeLatest('@order/FETCH_DASHBOARD_ORDERS', fetchDashboardOrders);
  yield takeLatest('@order/SEND_REPORT_REQUEST', sendOrdersReport);
  yield takeLatest('@order/FEATURE_TOGGLE_ENABLE_ORDER_REPORT', fetchFeatureToggleEnableOrderReport);
  yield takeLatest('@order/FT_ENABLE_ORDER_STATUS_UPDATE_REPORT', fetchFeatureToggleEnableOrderStatusUpdateReport);
  yield takeLatest('@order/FT_ENABLE_EXPORT_PRODUCTS_REPORT', fetchFeatureToggleEnableExportProductsReport);
}

export { reducer as ordersReducer, saga as ordersSaga };
