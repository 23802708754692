import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { IntegrationPlatformOnboarding } from '../../../@types/onboarding';
import { PlatformValuesState } from '../../../@types/platformValues';
import { Container, Header, StyleModal } from '../FirstSteps.styles';
import IntegrationPlatform from './components/IntegrationPlatform';
import ModalHeader from './components/ModalHeader';
import PlatformChoice from './components/PlatformChoice';
import { ModalContainerBody } from './components/shared-components';
import { validationSchema } from './components/validations';

interface Props {
  sellerId: string;
  onSupportClick: Function;
  onTicketHistoryClick: Function;
}

const initialValues = {
  integrationPlatformName: '',
  salesChannel: '',
  affiliateId: '',
  accountName: '',
  applicationKey: '',
  applicationToken: '',
  oi: '',
  confirmPlatform: false,
};

const SetupIntegration: React.FC<Props> = ({ sellerId, onSupportClick, onTicketHistoryClick }) => {
  const dispatch = useDispatch();
  const title = 'Bem-vindo, para continuar confira as informações';
  const [integrationPlatformValues, setIntegrationPlatformValues] = useState(initialValues);
  const { errorMessages, formikInputValues, fetching, isSubmitting } = useSelector<
    {
      platformValues: PlatformValuesState;
    },
    PlatformValuesState
  >(({ platformValues }) => ({ ...platformValues }));

  useEffect(() => {
    dispatch({
      type: '@platformValues/FETCH_LIST',
    });
    dispatch({
      type: '@platformValues/FETCH_SELLER_PLATFORM',
      payload: {
        sellerId,
      },
    });
  }, []);

  useEffect(() => {
    formikInputValues?.integrationPlatformName && setIntegrationPlatformValues(formikInputValues);
  }, [formikInputValues]);

  const handleSubmit = (values: IntegrationPlatformOnboarding) => {
    const defaultPayload = {
      integrationPlatformValues: values,
      sellerId,
      integrationPlatformName: values.integrationPlatformName,
    };
    if (values.integrationPlatformName === 'VTEX') {
      dispatch({
        type: '@platformValues/VALIDATE_VTEX_INTEGRATION_PLATFORM',
        payload: defaultPayload,
      });
    } else {
      dispatch({
        type: '@platformValues/UPDATE_INTEGRATION_PLATFORM',
        payload: defaultPayload,
      });
    }
  };

  return (
    <StyleModal
      disableAutoFocus
      disableEnforceFocus
      open={true}
      aria-labelledby="modal-first-steps-setup"
      aria-describedby="modal-setup-with-first-steps"
    >
      <Container>
        <Header data-testid={`firstStepsSetupTitle-${title}`}>{title}</Header>
        <Formik
          enableReinitialize
          initialValues={integrationPlatformValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<IntegrationPlatformOnboarding>) => (
            <Form>
              <ModalContainerBody>
                <ModalHeader
                  title="Integração"
                  description="Habilite a sua integradora em nossa plataforma"
                  onPress={onTicketHistoryClick}
                />
                {fetching && (
                  <Grid container justify="center">
                    <CircularProgress />
                  </Grid>
                )}
                {!fetching && <PlatformChoice formik={props} />}
                <IntegrationPlatform
                  formik={props}
                  errorMessage={errorMessages}
                  formikInputValues={formikInputValues || initialValues}
                  onSupportClick={onSupportClick}
                />
                <Box display="flex" flexDirection="row-reverse">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginBottom: 20, marginTop: 20 }}
                    disabled={isSubmitting ? isSubmitting : fetching}
                    endIcon={isSubmitting && <CircularProgress size={'22px'} />}
                  >
                    Continuar
                  </Button>
                </Box>
              </ModalContainerBody>
            </Form>
          )}
        </Formik>
      </Container>
    </StyleModal>
  );
};

export default SetupIntegration;
