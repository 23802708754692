/* eslint-disable no-console */
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { CssBaseline, LinearProgress, MuiThemeProvider } from '@material-ui/core';
import { BrandsState } from '../@types/brands';
import { BusinessParamsState } from '../@types/businessParams';
import { OnboardingState } from '../@types/onboarding';
import { PlatformValuesState } from '../@types/platformValues';
import { Contract } from '../@types/seller';
import { useAuth } from '../contexts/AuthProvider';
import AlertDialog from '../shared/components/AlertDialog';
import Login from '../shared/components/Login/Login';
import { MKTPTheme, baseStyle } from '../shared/theme';
import verifyPermissionToManage from '../shared/utils/verifyPermissionToManage';
import { SellerState, MAIN_CONTRACT } from '../store/ducks/seller/seller.duck';
import { ChangePassword } from './ChangePassword';
import FirstSteps from './FirstSteps';
import Page403 from './Page403/Page403';
import PrivateRoute from './PrivateRoute';
import routes from './routes';

interface SellerProps {
  contractCheckEnded?: boolean;
  featureToggleOptin?: boolean;
  acceptanceTerm?: Array<Contract>;
  sellerFetching?: boolean;
}

const renderPages = (
  featureToggleUpdate: boolean,
  featureToggleSAC: boolean,
  isLiteAccount: boolean,
  isVtexOrAnymarket: boolean,
) =>
  routes({ featureToggleUpdate, featureToggleSAC })
    .filter((item) => {
      return verifyPermissionToManage(item.requiredUserPermissions, isLiteAccount, item.path, isVtexOrAnymarket);
    })
    .map((page) => {
      const RenderRouter = page.publicAccess ? Route : PrivateRoute;
      return <RenderRouter key={page.path} {...page} />;
    });

const Pages = () => {
  const { user, authenticated, resourceAccess, inputsError, handleRecoveryPassword } = useAuth();
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const [shouldRenderFirstStep, setShouldRenderFirstStep] = useState(false);
  const [onboardingEnabled, setOnboardingEnabled] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<string>('');

  const { firstAccessAt, fetching, isLiteAccount } = useSelector<{ brands: BrandsState }, BrandsState>(
    (state) => state.brands,
  );
  const {
    hasSetupCompleted,
    featureToggleOnboarding,
    fetching: onboardingFetching,
  } = useSelector<{ onboarding: OnboardingState }, OnboardingState>((state) => state.onboarding);
  const { contractCheckEnded, featureToggleOptin, acceptanceTerm, sellerFetching } = useSelector<
    { seller: SellerState },
    SellerProps
  >(({ seller }) => {
    return {
      contractCheckEnded: seller.contractCheckEnded,
      featureToggleOptin: seller.featureToggleOptin,
      acceptanceTerm: seller.acceptanceTerm,
      sellerFetching: seller.fetching,
    };
  }, shallowEqual);

  const { featureToggleUpdate, featureToggleSAC } = useSelector<
    { businessParams: BusinessParamsState },
    BusinessParamsState
  >((state) => state.businessParams);

  const { isVtexOrAnymarket } = useSelector<
    {
      platformValues: PlatformValuesState;
    },
    PlatformValuesState
  >(({ platformValues }) => ({ ...platformValues }));

  const activateFirstSteps = process.env.REACT_APP_ACTIVE_FIRST_STEPS === 'true';

  useEffect(() => {
    if (user.seller_id) {
      resourceAccess && sessionStorage.setItem('mktp_resourceAccess', JSON.stringify(resourceAccess));

      if (user?.seller_id) {
        dispatch({
          type: '@brands/FETCH_BRANDS',
          payload: {
            sellerIdToken: user.seller_id,
          },
        });

        dispatch({
          type: '@deliveryFailure/GET_FEATURE_TOGGLE',
          payload: {
            sellerId: user.seller_id,
          },
        });

        dispatch({
          type: '@order/FEATURE_TOGGLE_CANCEL_ORDER',
          payload: {
            sellerId: user.seller_id,
          },
        });
        dispatch({
          type: '@platformValues/FETCH_SELLER_PLATFORM',
          payload: {
            sellerId: user.seller_id,
          },
        });
        dispatch({
          type: '@seller/FETCH',
          payload: { seller_id: user.seller_id },
        });
        dispatch({
          type: '@businessParams/VERIFY_BUSINESS_PARAM_ENABLE_ACTION_CENTER',
          payload: {
            sellerId: user.seller_id,
          },
        });
        dispatch({ type: '@order/FEATURE_TOGGLE_ENABLE_ORDER_REPORT', payload: { sellerId: user?.seller_id } });
        dispatch({ type: '@order/FT_ENABLE_ORDER_STATUS_UPDATE_REPORT', payload: { sellerId: user?.seller_id } });
      }

      if (!user?.seller_id?.endsWith('-lite')) {
        dispatch({
          type: '@seller/FETCH_BUSINESS_PARAM',
          payload: { ruleId: 'FeatureToggle_EnableOptin', sellerId: user?.seller_id },
        });

        const isOnboardingAvailable = verifyPermissionToManage({
          api: 'mktp-mktp-odis-api-seller',
          role: 'onboarding',
        });
        setOnboardingEnabled(isOnboardingAvailable);

        if (isOnboardingAvailable) {
          dispatch({
            type: '@onboarding/VERIFY_BUSINESS_PARAM',
            payload: { ruleId: 'FeatureToggle_EnableIntegrationGuideAtOnboarding', sellerId: user?.seller_id },
          });
        }
      }

      dispatch({ type: '@businessParams/VERIFY_BUSINESS_PARAM_UPDATE' });
      dispatch({ type: '@businessParams/VERIFY_BUSINESS_PARAM_SAC' });
    }
  }, [user.seller_id]);

  useEffect(() => {
    const firstStepsOpen = !(activateFirstSteps && firstAccessAt);
    const getFirstStepsStarted = localStorage.getItem('firstStepsStarted');
    const shouldRenderTutorial = firstAccessAt === undefined && firstStepsOpen && !getFirstStepsStarted;
    const shouldRenderSetup = onboardingEnabled && featureToggleOnboarding && !hasSetupCompleted;

    setShouldRenderFirstStep(shouldRenderSetup || shouldRenderTutorial);
  }, [firstAccessAt, featureToggleOnboarding, hasSetupCompleted]);

  const renderNextPage = () => {
    if (currentPath === '/reset-senha') {
      return <ChangePassword />;
    }
    if (!authenticated) {
      return <Login inputsError={inputsError} handleRecoveryPassword={handleRecoveryPassword} />;
    }

    if (user?.is_seller_user_active === false || user?.is_corporate === 'true') return <Page403 publicMode />;

    if (fetching || onboardingFetching || sellerFetching) return <LinearProgress />;

    const termAccepted = acceptanceTerm?.find((term: Contract) => term.type === MAIN_CONTRACT && term.acceptedBy);
    if (featureToggleOptin && !termAccepted) {
      if (!contractCheckEnded) return <LinearProgress />;
    } else if (user && shouldRenderFirstStep) {
      return <FirstSteps setShouldRenderFirstStep={setShouldRenderFirstStep} sellerId={user?.seller_id} />;
    }
    return <Switch>{renderPages(featureToggleUpdate, featureToggleSAC, isLiteAccount, isVtexOrAnymarket)}</Switch>;
  };

  const getConfirm = (message: string, callback: (ok: boolean) => void) => {
    setConfirmMessage(message);
    setShowConfirmModal(true);
    callback(false);
  };

  // if (!initialized) return null;

  return (
    <MuiThemeProvider theme={MKTPTheme}>
      <ThemeProvider theme={baseStyle}>
        <CssBaseline />
        <BrowserRouter getUserConfirmation={getConfirm}>
          <Suspense fallback={<LinearProgress />}>{renderNextPage()}</Suspense>
        </BrowserRouter>
        <AlertDialog
          data-testid="promptAlertDialog"
          open={showConfirmModal}
          title="Atenção"
          subtitle={confirmMessage}
          handleConfirm={() => setShowConfirmModal(false)}
          agreeTitle="Voltar"
        />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Pages;
