declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
  }
}

export const dispatchGtagEvent = (event: GtagEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};
