import { Reducer } from 'react';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  BrandsActionsType,
  BrandsState,
  FetchBrandsAction,
  FetchIntegrationPlatformAction,
  IntegrationPlatformResponse,
  UpdateBrandsAction,
  ListBrandsResponse,
} from '../../../@types/brands';
import { api } from '../../../services/api';

const { REACT_APP_USER_MANAGEMENT_API_URL, REACT_APP_SELLER_API_URL } = process.env;

const initialState: BrandsState = {
  fetching: false,
  userId: '',
  sellers: [],
  selected: '',
  firstAccessAt: '',
  integrationPlatformName: '',
  isVTEXOrAnymarket: false,
  brandChanged: false,
  isLiteAccount: false,
  email: '',
};

const verifyVTEXOrAnymarket = (integrationPlatformName: string) => {
  const platformValue = integrationPlatformName.toUpperCase();
  return platformValue === 'VTEX' || platformValue === 'ANYMARKET';
};

const checkIsLiteAccount = (sellerId: string) => {
  return sellerId.includes('-lite');
};

const brandsReducer: Reducer<BrandsState, BrandsActionsType> = (state = initialState, { payload, type }) => {
  switch (type) {
    case '@brands/FETCH_BRANDS':
    case '@brands/FETCH_INTEGRATION_PLATFORM':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@brands/UPDATE_BRAND': {
      return {
        ...state,
        ...payload,
      };
    }
    case '@brands/FETCH_BRANDS_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@brands/FETCH_BRANDS_ERROR':
    case '@brands/UPDATE_BRAND_SUCCESS':
      return {
        ...state,
        ...payload,
        brandChanged: true,
      };
    case '@brands/UPDATE_BRAND_ERROR':
    case '@brands/FETCH_INTEGRATION_PLATFORM_SUCCESS':
    case '@brands/FETCH_INTEGRATION_PLATFORM_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* listBrands({ payload: { sellerIdToken } }: FetchBrandsAction) {
  try {
    const { brands } = yield select();
    const response: ListBrandsResponse = yield call(api.get, `${REACT_APP_USER_MANAGEMENT_API_URL}/me`);

    yield put({
      type: '@brands/FETCH_BRANDS_SUCCESS',
      payload: {
        userId: response.data.id,
        sellers: response.data.sellers,
        selected: brands.sellerId ?? sellerIdToken,
        firstAccessAt: response.data.firstAccessAt,
        fetching: false,
        isLiteAccount: checkIsLiteAccount(brands.sellerId ?? sellerIdToken),
        email: response.data.email,
      },
    });
  } catch (error) {
    yield put({
      type: '@brands/FETCH_BRANDS_ERROR',
      payload: {
        selected: sellerIdToken,
        sellers: [sellerIdToken],
        fetching: false,
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Ocorreu um erro ao buscar as marcas`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateBrand({ payload: { sellerId, userId, callback } }: UpdateBrandsAction) {
  try {
    yield call(api.post, `${REACT_APP_USER_MANAGEMENT_API_URL}/users/${userId}/sellers/${sellerId}`);

    yield put({
      type: '@brands/UPDATE_BRAND_SUCCESS',
      payload: {
        selected: sellerId,
      },
    });

    callback();
  } catch (error) {
    yield put({
      type: '@brands/UPDATE_BRAND_ERROR',
    });

    yield put({
      type: '@layout/FETCH_BACK_DROP',
      payload: {
        backDropOpen: false,
      },
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Ocorreu um erro ao selecionar a marca`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* fetchIntegrationPlatformList({ payload: { sellerId } }: FetchIntegrationPlatformAction) {
  try {
    const response: IntegrationPlatformResponse = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/integration-platforms`,
    );
    const integrationPlatformName = response.data.name;

    yield put({
      type: '@brands/FETCH_INTEGRATION_PLATFORM_SUCCESS',
      payload: {
        integrationPlatformName,
        isVTEXOrAnymarket: verifyVTEXOrAnymarket(integrationPlatformName),
        brandChanged: false,
      },
    });
  } catch (error) {
    yield put({
      type: '@brands/FETCH_INTEGRATION_PLATFORM_ERROR',
      payload: {
        ...initialState,
        brandChanged: false,
      },
    });
  }
}

function* brandsSaga() {
  yield takeLatest('@brands/FETCH_BRANDS', listBrands);
  yield takeLatest('@brands/UPDATE_BRAND', updateBrand);
  yield takeLatest('@brands/FETCH_INTEGRATION_PLATFORM', fetchIntegrationPlatformList);
}

export { brandsReducer, brandsSaga };
