/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useState } from 'react';

const { REACT_APP_API_BASEPATH_URL, REACT_APP_USER_MANAGEMENT_API_URL } = process.env;

interface AuthContextType {
  token: any;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  user: any;
  authenticated: boolean;
  resourceAccess: any;
  inputsError: any;
  handleRecoveryPassword: any;
}

export const useAuth = () => useContext(AuthContext);

const AuthContext = createContext<AuthContextType>({
  token: {},
  login: async () => {},
  logout: () => {},
  user: {},
  authenticated: false,
  resourceAccess: {},
  inputsError: {
    username: false,
    password: false,
  },
  handleRecoveryPassword: async () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState<any>({});
  const [user, setUser] = useState({});
  const [resourceAccess, setResourceAccess] = useState({});
  const [inputsError, setInputsError] = useState({
    username: false,
    password: false,
  });

  function decodeJWT(token: string) {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }

  const validateLoginForm = async (username: string, password: string) => {
    let hasSuccess = true;
    const error = {
      username: false,
      password: false,
    };

    if (!username) {
      error.username = true;
      hasSuccess = false;
    }
    if (!password) {
      error.password = true;
      hasSuccess = false;
    }
    setInputsError(error);
    return hasSuccess;
  };

  const validateRecoveryPasswordForm = async (username: string) => {
    let hasSuccess = true;
    const error = {
      username: false,
      password: false,
    };

    if (!username) {
      error.username = true;
      hasSuccess = false;
    }
    setInputsError(error);
    return hasSuccess;
  };

  const handleRecoveryPassword = async (username: string) => {
    const validateHasSuccess = await validateRecoveryPasswordForm(username);
    let userFeedback = {};
    if (!validateHasSuccess) {
      return;
    }
    try {
      const payload: any = {
        email: username,
      };

      const formBody = Object.keys(payload)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]))
        .join('&');

      const responseService = await fetch(
        `${REACT_APP_API_BASEPATH_URL}/${REACT_APP_USER_MANAGEMENT_API_URL}/auth/portal-seller/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        },
      );
      if (responseService.status !== 200) {
        userFeedback = { error: true, message: 'Ocorreu um erro ao tentar recuperar a senha! Tente novamente!' };
      }
      if (responseService.status === 200) {
        userFeedback = { error: false, message: 'Recuperação solicitada com sucesso! Verifique seu e-mail!' };
      }
      return userFeedback;
    } catch (error) {
      console.log('Falha na recuperação:');
      throw error;
    }
  };

  const login = async (username: string, password: string) => {
    const validateHasSuccess = await validateLoginForm(username, password);
    if (!validateHasSuccess) {
      return;
    }
    try {
      const payload: any = {
        username,
        password,
        client_id: 'mktp-mktp-odis-web-portal-seller',
        grant_type: 'password',
      };
      const formBody = Object.keys(payload)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]))
        .join('&');

      const responseService = await fetch(
        `${REACT_APP_API_BASEPATH_URL}/${REACT_APP_USER_MANAGEMENT_API_URL}/auth/openid-connect/token/portal-seller`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        },
      );
      const data = await responseService.json();
      if (data.access_token) {
        setAuthenticated(true);
        setToken(data);
        sessionStorage.setItem('mktp_token', data.access_token);
        sessionStorage.setItem('mktp_refresh_token', data.refresh_token);
        const decodedJWT = decodeJWT(data.access_token);
        setResourceAccess(decodedJWT.resource_access);
        setUser(decodedJWT);
      } else {
        throw new Error(data.message || 'Erro ao fazer login');
      }
    } catch (error) {
      console.log('Falha no login:');
      throw error;
    }
  };

  const getAccessToken = async () => {
    return new Promise((resolve) => {
      const token = sessionStorage.getItem('mktp_token') || '';
      resolve(token ? decodeJWT(token) : token);
    });
  };

  const loadAccess = async () => {
    const token: any = await getAccessToken();
    if (token) {
      setAuthenticated(true);
      setResourceAccess(token?.resource_access);
      setUser(token);
      setToken({
        refresh_token: sessionStorage.getItem('mktp_refresh_token'),
      });
    } else {
      setAuthenticated(false);
    }
  };

  useEffect(() => {
    loadAccess();
  }, []);

  const logout = async () => {
    try {
      const payload: any = {
        RefreshToken: token.refresh_token,
      };
      const formBody = Object.keys(payload)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]))
        .join('&');

      const responseService = await fetch(
        `${REACT_APP_API_BASEPATH_URL}/${REACT_APP_USER_MANAGEMENT_API_URL}/auth/openid-connect/logout`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        },
      );
      await responseService;
      sessionStorage.removeItem('mktp_token');
      sessionStorage.removeItem('mktp_resourceAccess');
      sessionStorage.removeItem('mktp_refresh_token');
      setAuthenticated(false);
      setUser({});
      setToken({});
      setResourceAccess({});
    } catch (error) {
      console.log('rf error finish session', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ token, login, logout, user, authenticated, resourceAccess, inputsError, handleRecoveryPassword }}
    >
      {children}
    </AuthContext.Provider>
  );
};
