import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { LayoutState } from '../../../@types/layout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const SnackbarComponent: React.FC<LayoutState> = ({ message, type, open, arrayMessage }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        open: false,
      },
    });
    dispatch({
      type: '@layout/RESET_SNACKBAR',
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar autoHideDuration={3000} open={open} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity={type}>
          {message}
          <br />
          {arrayMessage?.map((message: string) => (
            <>
              <span style={{ fontSize: 12, marginLeft: 4 }}>- {message}</span>
              <br />
            </>
          ))}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
