import { Reducer } from 'react';
import type { Action } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { DataResponse, DefaultRequest, ErrorMessages, IError, Pagination } from '../../../@types';
import type {
  CreateUserRequest,
  EditUserRequest,
  GetUserByIdRequest,
  toggleUserStatusRequest,
  UserResponse,
  UsersResponse,
  UserGroupResponse,
  User,
} from '../../../@types/users';
import { api } from '../../../services/api';
import { dispatchGtagEvent } from '../../../shared/utils/dispatchGtagEvent';

const API_SERVICE = `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}`;

const msgEmailIsAlreadyInUse = 'Operation not effected. Email already registered.';
const msgUserIdAlreadyInUse = 'Operation not effected. UserIdentityProvider already registered.';

type Messages = {
  message: string;
};

export type UsersState = Pagination<User> & {
  fetching: boolean;
  submitting: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  persisted: boolean;
  selectedUser?: User;
};

export type UsersAction<T> = Action<string> & {
  payload: T;
};

export type ListUsersAction = UsersAction<DefaultRequest>;

export type CreateUserAction = UsersAction<CreateUserRequest>;

export type GetUserByIdAction = UsersAction<GetUserByIdRequest>;

export type EditUserAction = UsersAction<EditUserRequest>;

export type toggleUserStatusAction = UsersAction<toggleUserStatusRequest>;

const initialState: UsersState = {
  fetching: false,
  submitting: false,
  success: false,
  items: [],
  errorMessages: [],
  pageIndex: 0,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
  indexFrom: 0,
  totalCount: 0,
  totalPages: 0,
  persisted: false,
};

const usersReducer: Reducer<UsersState, UsersAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@users/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
        selectedUser: null,
      };
    case '@users/FETCH_SUCCESS':
      return {
        ...state,
        success: payload.success,
        errorMessages: payload.errorMessages,
        ...payload.data,
        fetching: false,
        persisted: false,
        selectedUser: null,
      };
    case '@user/GET_BY_ID_SUCCESS':
    case '@user/TOOGLE_USER_STATUS':
    case '@user/TOOGLE_USER_STATUS_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case '@user/CREATE_SUCCESS':
    case '@user/EDIT_SUCCESS':
      return {
        ...state,
        persisted: true,
        submitting: false,
      };
    case '@user/FORM_CLEAN':
      return {
        ...state,
        fetching: true,
        selectedUser: null,
      };
    case '@user/FORM_SUBMITTING':
      return {
        ...state,
        submitting: true,
      };
    case '@users/FETCH_ERROR':
    case '@user/CREATE_ERROR':
    case '@user/EDIT_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        submitting: false,
        persisted: false,
      };
    default:
      return state;
  }
};

function* listUsers({ payload: { sellerId, pageIndex = 0, pageSize = 20 } }: ListUsersAction) {
  try {
    const responsePayload: UsersResponse = yield call(api.get, `${API_SERVICE}/users/groups`, {
      params: {
        'seller-id': sellerId,
        'page-index': pageIndex,
        'page-size': pageSize,
      },
    });

    if (responsePayload) {
      yield put({
        type: '@users/FETCH_SUCCESS',
        payload: responsePayload,
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao listar os usuários.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    const e = error as IError;

    if (e.status === 404) {
      const [errorMessage] = e.data.errorMessages;
      yield put({
        type: '@users/FETCH_ERROR',
        payload: {
          ...e.data,
          errorMessages: [
            {
              ...errorMessage,
              notFoundHelperText: 'Não existem usuários para listar.',
            },
          ],
          items: [],
          fetching: false,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao listar os usuários.`,
          type: 'error',
          open: true,
        },
      });
    }
  }
}

function* createUser({ payload }: CreateUserAction) {
  yield put({ type: '@user/FORM_SUBMITTING' });

  try {
    const responseCreateUser: UserResponse = yield call(api.post, `${API_SERVICE}/users`, payload);

    if (responseCreateUser) {
      const responseCreateUserGroup: UserGroupResponse = yield call(
        api.post,
        `${API_SERVICE}/users/${responseCreateUser.data.id}/groups/${payload.group}`,
      );

      if (responseCreateUserGroup) {
        const user = responseCreateUser.data;
        const [firstName] = user.fullName.split(' ');

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            errorMessage: `Usuário ${firstName} adicionado com sucesso`,
            typeMessage: 'success',
            openMessage: true,
          },
        });

        yield put({
          type: '@user/CREATE_SUCCESS',
          payload: responseCreateUserGroup,
        });

        dispatchGtagEvent({
          event: 'event',
          eventCategory: `pds:configuracoes:adicionar-usuario`,
          eventAction: `callback:adicionar-usuario`,
          eventLabel: `novo-usuario-cadastrado-com-sucesso`,
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Usuário ${payload.fullName} criado com sucesso!`,
            type: 'success',
            open: true,
          },
        });
      } else {
        yield put({
          type: '@user/CREATE_ERROR',
        });

        dispatchGtagEvent({
          event: 'event',
          eventCategory: `pds:configuracoes:adicionar-usuario`,
          eventAction: `callback:adicionar-usuario`,
          eventLabel: `nao-foi-possivel-cadastrar-esse-usuario`,
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Ocorreu um erro ao criar o grupo do usuário ${payload.fullName}.`,
            type: 'error',
            open: true,
          },
        });
      }
    } else {
      yield put({
        type: '@user/CREATE_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao criar o usuário ${payload.fullName}.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    const e = error as IError;
    let messageErrror;

    if (e?.data?.errorMessages) {
      const { errorMessages } = e.data;

      const [emailIsAlreadyInUse] = errorMessages.filter(
        (e: Messages) => e.message === msgUserIdAlreadyInUse || msgEmailIsAlreadyInUse,
      );

      if (emailIsAlreadyInUse) {
        messageErrror = 'Erro ao adicionar usuário. E-mail já cadastrado.';
      }
    }

    yield put({
      type: '@user/CREATE_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: messageErrror ?? `Ocorreu um erro ao criar o usuário ${payload.fullName}.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* getUserById({ payload: { id } }: GetUserByIdAction) {
  yield put({ type: '@user/FORM_CLEAN' });

  let groupId = '';

  try {
    const payloadUser: UserResponse = yield call(api.get, `${API_SERVICE}/users/${id}`);

    try {
      const payloadUserGroup: UserGroupResponse = yield call(api.get, `${API_SERVICE}/users/${id}/groups`, {
        params: {
          'page-index': 0,
          'page-size': 1,
        },
      });

      if (payloadUserGroup) {
        groupId = payloadUserGroup.data.items[0].groupId;
      }
    } catch (error) {
      // bloco catch vazio para ignorar erros
      // caso o usuário não tenha grupo associado,
      // permitindo a edição do mesmo
    }

    if (payloadUser) {
      yield put({
        type: '@user/GET_BY_ID_SUCCESS',
        payload: {
          success: payloadUser.success,
          errorMessages: payloadUser.errorMessages,
          selectedUser: { ...payloadUser.data, group: { id: groupId } },
          fetching: false,
        },
      });
    } else {
      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao consultar o usuário.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Ocorreu um erro ao consultar o usuário.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* editUser({ payload }: EditUserAction) {
  yield put({ type: '@user/FORM_SUBMITTING' });

  try {
    const responseEditUser: UserResponse = yield call(api.put, `${API_SERVICE}/users/${payload.id}`, payload);

    if (responseEditUser) {
      const responseEditUserGroup: UserGroupResponse = yield call(
        api.post,
        `${API_SERVICE}/users/${responseEditUser.data.id}/groups/${payload.group}`,
      );

      if (responseEditUserGroup) {
        yield put({
          type: '@user/EDIT_SUCCESS',
          payload: responseEditUserGroup,
        });

        dispatchGtagEvent({
          event: 'event',
          eventCategory: `pds:configuracoes:editar-usuario`,
          eventAction: `callback:editar-usuario`,
          eventLabel: `usuario-editado-com-sucesso`,
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Usuário ${payload.fullName} editado com sucesso!`,
            type: 'success',
            open: true,
          },
        });
      } else {
        yield put({
          type: '@user/EDIT_ERROR',
        });

        dispatchGtagEvent({
          event: 'event',
          eventCategory: `pds:configuracoes:editar-usuario`,
          eventAction: `callback:editar-usuario`,
          eventLabel: `nao-foi-possivel-editar-esse-usuario`,
        });

        yield put({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: `Ocorreu um erro ao editar o usuário ${payload.fullName}.`,
            type: 'error',
            open: true,
          },
        });
      }
    } else {
      yield put({
        type: '@user/EDIT_ERROR',
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Ocorreu um erro ao editar o usuário ${payload.fullName}.`,
          type: 'error',
          open: true,
        },
      });
    }
  } catch (error) {
    const e = error as IError;
    let messageErrror;

    if (e?.data?.errorMessages) {
      const { errorMessages } = e.data;

      const [emailIsAlreadyInUse] = errorMessages.filter(
        (e: Messages) => e.message === msgUserIdAlreadyInUse || msgEmailIsAlreadyInUse,
      );

      if (emailIsAlreadyInUse) {
        messageErrror = 'Erro ao editar usuário. E-mail já cadastrado.';
      }
    }

    yield put({
      type: '@user/EDIT_ERROR',
    });

    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: messageErrror ?? `Ocorreu um erro ao editar o usuário ${payload.fullName}.`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* toggleUserStatus({ payload }: toggleUserStatusAction) {
  try {
    const { users } = yield select();
    const { enabled, id, fullName } = payload;

    const response: DataResponse<{}> = yield call(api.patch, `${API_SERVICE}/users/${id}`, { enable: enabled });

    if (response.success) {
      const enabledMessage = enabled ? 'ativado' : 'inativado';

      const newItems = users.items?.map((user: User) => {
        if (user.id === id) {
          return { ...user, enabled };
        } else {
          return user;
        }
      });

      yield put({
        type: '@user/TOOGLE_USER_STATUS_SUCCESS',
        payload: {
          ...users,
          success: response.success,
          errorMessages: response.errorMessages,
          fetching: false,
          items: newItems,
        },
      });

      yield put({
        type: '@layout/FETCH_SNACKBAR_MESSAGE',
        payload: {
          message: `Usuário ${fullName} ${enabledMessage} com sucesso!`,
          type: 'success',
          open: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: `Algo deu errado com sua operação!`,
        type: 'error',
        open: true,
      },
    });
  }
}

function* usersSaga() {
  yield takeLatest('@users/FETCH', listUsers);
  yield takeLatest('@user/CREATE', createUser);
  yield takeLatest('@user/GET_BY_ID', getUserById);
  yield takeLatest('@user/EDIT', editUser);
  yield takeLatest('@user/TOOGLE_USER_STATUS', toggleUserStatus);
}

export { usersReducer, usersSaga };
