import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ObjectSchema } from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Close, DeleteOutline, FolderOpen } from '@material-ui/icons';
import Button from '@rchlo/storybook/core/Button';
import { TicketForm, ISchema, FileUploadPayload, SACState, AddedFilePayload } from '../../../@types/sac';
import { useAuth } from '../../../contexts/AuthProvider';
import WarningLabel from '../../FirstSteps/SetupIntegration/components/WarningLabel';
import { acceptFileType } from '../utils';
import * as St from './CreateTicketForm.styles';
import * as FormElements from './components/FormElements';
import formSchema from './formSchema.json';
import { getFormSchema, getComplementFormSchema, IFormSchema } from './utils';
import getValidationSchema from './validations';

export interface Props {
  open: boolean;
  closeModal: Function;
  isIntegration?: boolean;
}

const initialValues: TicketForm = {
  contactReason: '',
  subReason: '',
  subject: '',
  description: '',
};

const CreateTicketForm: React.FC<Props> = ({ open, closeModal, isIntegration }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addedFiles, setAddedFiles] = useState<AddedFilePayload['addedFiles'] | undefined>();
  const [fileAttachments, setFileAttachment] = useState<FileUploadPayload['fileAttachments'] | undefined>();
  const [fileErrorMessages, setFileErrorMessage] = useState<FileUploadPayload['errorMessages'] | ''>();
  const [currentSchema, setCurrentSchema] = useState<IFormSchema>(formSchema);
  const [validationSchema, setValidationSchema] = useState<ObjectSchema<{}>>(getValidationSchema(formSchema));
  const { user } = useAuth();

  const { fetching, attachments, ticketId, formData, errorsTicketsOrdersSac } = useSelector<{
    sac: SACState;
  }>((state) => state.sac) as SACState;

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    onSubmit: (values) => validateOnSubmit(values),
    validationSchema,
  });

  const validateOnSubmit = (values: TicketForm | any) => {
    clearValidateErrors();
    const valuesUpdated = { ...values };
    if (values?.orderCode || values?.orderCode1) {
      const ordersValues = [
        values?.orderCode,
        values?.orderCode1,
        values?.orderCode2,
        values?.orderCode3,
        values?.orderCode4,
        values?.orderCode5,
      ];
      const ordersValuesFormatted: Array<string> = [];
      ordersValues.map((order: string | undefined, key: number) => {
        if (order) {
          ordersValuesFormatted.push(order.slice(0, -1));
          if (key === 0 && order) {
            valuesUpdated.orderCode = order.slice(0, -1);
          } else {
            valuesUpdated[`orderCode${key}`] = order.slice(0, -1);
          }
        }
      });
      dispatch({
        type: '@sac/VALIDATE_ORDER',
        payload: {
          orders: ordersValuesFormatted,
          sellerId: user.seller_id,
          informations: getComplementFormSchema(valuesUpdated),
          attachments: getAttachmentToken(),
        },
      });
    } else {
      dispatch({
        type: '@sac/POST_TICKETS_PAYLOADS',
        payload: {
          informations: getComplementFormSchema(values),
          attachments: getAttachmentToken(),
        },
      });
    }
  };

  useEffect(() => {
    Object.entries(formData).map((entry) => {
      if (entry[0] === 'fileAttachments') {
        setFileAttachment(entry[1]);
      } else {
        setTimeout(() => {
          formik.setFieldValue(entry[0], entry[1]);
        }, 500);
      }
    });
  }, []);

  useEffect(() => {
    if (ticketId) {
      clearValidateErrors();
      history.push(`/central-de-atendimento/ticket/${ticketId}`);
      window.scrollTo(0, 0);
    }
  }, [ticketId, history]);

  useEffect(() => {
    clearDefaults();
    clearSubfields();
  }, [formik.values.contactReason, formik.values.subReason]);

  useEffect(() => {
    clearDefaults();
    clearQuantityFields();
  }, [formik.values.quantity]);

  useEffect(() => {
    if (addedFiles && attachments.length > 0) {
      const uploadAttachmentFile = addedFiles?.find((attachment) => {
        return (attachment.fileName as string) === attachments[attachments.length - 1].fileName;
      });

      if (uploadAttachmentFile) {
        addUploadFile(uploadAttachmentFile.file);
      }
    }
  }, [addedFiles, attachments]);

  const clearDefaults = () => {
    formik.setFieldValue('subject', '');
    formik.setFieldValue('description', '');
  };

  const clearSubfields = () => {
    const schema = getFormSchema(formik.values);

    Object.keys(schema).forEach((key) => {
      if (
        key !== 'contactReason' &&
        key !== 'subReason' &&
        key !== 'subject' &&
        key !== 'description' &&
        key !== 'attachment'
      ) {
        formik.setFieldValue(key, '');
      }
    });

    setCurrentSchema(schema);
    setValidationSchema(getValidationSchema(schema));
  };

  const clearQuantityFields = () => {
    const schema = getFormSchema(formik.values);

    const totalToRemove = 6 - Number(formik.values.quantity);
    totalToRemove &&
      [...Array(totalToRemove)].forEach((i, index) => {
        const key = `item${index + Number(formik.values.quantity)}`;

        formik.setFieldValue(key, '');
        formik.setFieldValue(key, undefined);
      });

    setCurrentSchema(schema);
    setValidationSchema(getValidationSchema(schema));
  };

  const resetOrderCodesInputs = (formik: any) => {
    formik.setFieldValue('orderCode', '');
    formik.setFieldValue('orderCode1', '');
    formik.setFieldValue('orderCode2', '');
    formik.setFieldValue('orderCode3', '');
    formik.setFieldValue('orderCode4', '');
    formik.setFieldValue('orderCode5', '');
  };

  const getFormElement = (elemName: string, elemSchema: ISchema): JSX.Element => {
    const props = {
      name: elemName,
      type: elemSchema.type,
      label: elemSchema.label,
      selectLabel: elemSchema.selectLabel,
      required: elemSchema.required,
      options: elemSchema.options,
      placeholder: elemSchema.placeholder,
      fullWidth: elemSchema.fullWidth,
      onChange: (e: React.ChangeEvent<any>) => {
        dispatch({ type: '@sac/UPDATE_FORM_DATA', payload: e.target });
        if (elemName === 'quantity' || elemName === 'subReason') {
          resetOrderCodesInputs(formik);
          setTimeout(() => {
            formik.handleChange(e);
          }, 500);
        }
        if (elemName === 'contactReason' && formik.values.contactReason.length) {
          resetOrderCodesInputs(formik);
          setTimeout(() => {
            formik.handleChange(e);
          }, 500);
        } else formik.handleChange(e);
      },
      value: formik.values[elemName as keyof TicketForm],
    };

    const showError =
      Boolean(formik.errors[elemName as keyof TicketForm]) && formik.touched[elemName as keyof TicketForm];
    const helperText =
      formik.errors[elemName as keyof TicketForm] && formik.touched[elemName as keyof TicketForm]
        ? formik.errors[elemName as keyof TicketForm]
        : undefined;

    switch (elemSchema.type) {
      case 'description':
        return <FormElements.DescriptionField {...props} type="text" error={showError} helperText={helperText} />;
      case 'select':
        return <FormElements.SelectField {...props} error={showError} helperText={helperText} />;

      default:
        return elemSchema.label.includes('CPF') ? (
          <FormElements.CPFTextField {...props} error={showError} helperText={helperText} />
        ) : (
          <FormElements.TextField {...props} error={showError} helperText={helperText} />
        );
    }
  };

  const getAttachmentName = () => {
    let name = '';
    fileAttachments?.map((attachment) => {
      name = name.length ? `${name}, ${attachment?.fileName}` : (attachment?.fileName as string);
    });
    return name;
  };

  const getAttachmentToken = () => {
    const tokens: string[] = [];
    attachments?.forEach((attachment) => {
      tokens.push(attachment.token);
    });
    return tokens;
  };

  const clearFileUploadErrorMessages = () => {
    setFileErrorMessage('');
  };

  const onChangeFileUpload = (filesUpload: FileList) => {
    let typeErrorMessages = '';
    let sizeErrorMessage = '';
    let duplicatedErrorMessage = '';
    clearFileUploadErrorMessages();
    setAddedFiles(() => []);

    Object.values(filesUpload).map((fileUpload: File) => {
      const { type, size, name } = fileUpload;

      if (!type || !acceptFileType.includes(type) || !name) {
        typeErrorMessages = 'Formato permitido: .png, .jpg, .jpeg, pdf, doc, docx, xls, xlsx, odt, ods, txt, csv.';
      }

      if (!size || size > 5000000) {
        sizeErrorMessage = 'Tamanho máximo suportado: 5MB.';
      }

      const attachmentFileName = fileAttachments?.find((attachment) => {
        return (attachment.fileName as string) === name;
      });

      if (attachmentFileName) {
        duplicatedErrorMessage = `Arquivo(s) já selecionado(s) previamente.`;
      }

      if (type && size && name && acceptFileType.includes(type) && size <= 5000000 && !attachmentFileName) {
        dispatch({
          type: '@sac/POST_TICKETS_ATTACHMENTS',
          payload: {
            file: fileUpload,
          },
        });

        addFileUploadAttachment(fileUpload);
      }
    });

    if (typeErrorMessages || sizeErrorMessage || duplicatedErrorMessage) {
      setFileErrorMessage(`${typeErrorMessages} ${sizeErrorMessage} ${duplicatedErrorMessage}`);
    }
  };

  const addFileUploadAttachment = (fileUpload: File) => {
    const { name } = fileUpload;

    setAddedFiles((prevState: AddedFilePayload['addedFiles'] | undefined) => {
      if (prevState) {
        return [...prevState, { file: fileUpload, fileName: name }];
      }
      return [{ file: fileUpload, fileName: name }];
    });
  };

  const addUploadFile = (fileUpload: File) => {
    const { name } = fileUpload;

    setFileAttachment((prevState: FileUploadPayload['fileAttachments'] | undefined) => {
      if (prevState) {
        return [...prevState, { file: fileUpload, fileName: name }];
      }
      return [{ file: fileUpload, fileName: name }];
    });
  };

  const onFormikReset = () => {
    if (formik.values.contactReason !== '') {
      dispatch({ type: '@sac/CLEAN_FORM_DATA' });
      formik.handleReset({});
    }
  };

  const renderOrderErros = () => {
    const arrayString: any[] = [];
    errorsTicketsOrdersSac?.map((order: any, key: number) => order && (arrayString[key] = order));
    const ordersString = arrayString.join(', ');
    return (
      <div style={{ paddingTop: 24, paddingBottom: 24 }}>
        <WarningLabel message={`Verifique os pedido(s), pois os mesmo(s) não existe(m): ${ordersString}`} />
      </div>
    );
  };

  const clearValidateErrors = () => {
    dispatch({
      type: '@sac/CLEAR_VALIDATE',
    });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="modal-create-ticket-form"
      style={{ minWidth: 500, height: 'auto', backgroundColor: isIntegration ? '#000' : 'transparent' }}
      onClose={() => clearValidateErrors()}
      fullWidth
    >
      <form onSubmit={formik.handleSubmit}>
        <St.ModalHeader>
          <DialogTitle disableTypography>
            <St.ModalTitle>Abrir Ticket</St.ModalTitle>
          </DialogTitle>
          <St.CloseButton
            data-testid="closeButton"
            aria-label="close"
            onClick={() => {
              closeModal();
              clearValidateErrors();
              onFormikReset();
            }}
          >
            <Close style={{ color: '#000' }} />
          </St.CloseButton>
        </St.ModalHeader>
        <DialogContent style={{ overflow: 'hidden' }}>
          {errorsTicketsOrdersSac?.length > 0 && renderOrderErros()}
          <Grid container spacing={4}>
            {Object.entries(currentSchema).map((entry) => getFormElement(entry[0], entry[1] as ISchema))}
            <Grid item lg={12} sm={12}>
              <TextField
                name="attachment"
                data-testid="attachment"
                label="Anexos"
                type="text"
                fullWidth
                helperText={fileErrorMessages}
                value={getAttachmentName()}
                onChange={formik.handleChange}
                placeholder="Clique para adicionar o arquivo"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <St.ButtonContainer>
                      <St.Label htmlFor="file-input">
                        <FolderOpen style={{ color: '#808080' }} />
                      </St.Label>
                      <St.FileUpload
                        type="file"
                        id="file-input"
                        multiple
                        accept={acceptFileType.join()}
                        onClick={() => clearFileUploadErrorMessages()}
                        onChange={(event) => {
                          const fileUpload = event?.target.files ? event?.target.files : undefined;
                          if (fileUpload) {
                            onChangeFileUpload(fileUpload);
                          }
                        }}
                      />
                      <St.ClearButton
                        data-testid="deleteFileUploadButton"
                        aria-label="delete"
                        onClick={() => {
                          clearFileUploadErrorMessages();
                          setFileAttachment(() => []);
                          setAddedFiles(() => []);
                        }}
                      >
                        <DeleteOutline style={{ color: '#808080' }} />
                      </St.ClearButton>
                    </St.ButtonContainer>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: 16 }}>
            <Button data-testid="cancelButton" onClick={onFormikReset} label="Cancelar" borderColor="#ffffff" />
            <Button
              primary
              label="Enviar"
              data-testid="generateReportSubmitButton"
              type="submit"
              disabled={!formik.isValid || fetching}
              endIcon={fetching && <CircularProgress size={'20px'} style={{ color: 'white' }} />}
            />
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTicketForm;
