import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { actionCenterReducer as actionCenter, actionCenterSaga } from './actionCenter/actionCenter.duck';
import { authReducer as auth } from './auth/auth.duck';
import { brandsReducer as brands, brandsSaga } from './brands';
import { businessParamsReducer as businessParams, businessParamsSaga } from './businessParams/businessParams.duck';
import { deliveryFailureReducer as deliveryFailure, deliveryFailureSaga } from './deliveryFailure';
import { commissionReducer as commission, commissionSaga } from './financial/commission.duck';
import { conciliationReducer as conciliation, conciliationSaga } from './financial/conciliation.duck';
import { firstStepsReducer as firstSteps, firstStepsSaga } from './firstSteps';
import { groupsReducer as groups, groupsSaga } from './groups/groups.duck';
import { layoutReducer as layout } from './layout';
import { notificationsReducer as notifications, notificationsSaga } from './notifications';
import { ordersReducer as orders, ordersSaga, ordersUpdateReducer as ordersUpdate, ordersUpdateSaga } from './orders';
import { packagesReducer as packages, packagesSaga } from './packages';
import {
  productReducer as product,
  productSaga,
  productSkuReducer as productSku,
  productSkuSaga,
  productsReducer as products,
  productsSaga,
} from './products';
import { channelReducer as channel, channelSaga } from './products/channel.duck';
import { skuTotalizerReducer as skuTotalizer, skuTotalizerSaga } from './products/sku-totalizers.duck';
import { providerReducer as providers, providerSaga } from './providers';
import { sacReducer as sac, sacSaga } from './sac/sac.duck';
import {
  sellerReducer as seller,
  sellerSaga,
  platformValuesReducer as platformValues,
  platformValuesSaga,
  webhookReducer as webhook,
  webhookSaga,
} from './seller';
import {
  integrationSettingsReducer as integrationSettings,
  integrationSettingsSaga,
} from './seller/integration-settings.duck';
import { onboardingReducer as onboarding, onboardingSaga } from './seller/onboarding.duck';
import { testOrdersReducer as testOrders, testOrdersSaga } from './testOrders/testOrders.duck';
import { ticketReducer as ticket, ticketSaga } from './ticket';
import { usersReducer as users, usersSaga } from './users/users.duck';

export const rootReducer = combineReducers({
  auth,
  users,
  groups,
  orders,
  packages,
  providers,
  seller,
  products,
  skuTotalizer,
  product,
  layout,
  brands,
  productSku,
  platformValues,
  conciliation,
  notifications,
  commission,
  firstSteps,
  integrationSettings,
  webhook,
  onboarding,
  testOrders,
  ordersUpdate,
  channel,
  businessParams,
  sac,
  ticket,
  deliveryFailure,
  actionCenter,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    usersSaga(),
    groupsSaga(),
    ordersSaga(),
    packagesSaga(),
    sellerSaga(),
    providerSaga(),
    productsSaga(),
    productSaga(),
    productSkuSaga(),
    skuTotalizerSaga(),
    platformValuesSaga(),
    brandsSaga(),
    conciliationSaga(),
    notificationsSaga(),
    commissionSaga(),
    firstStepsSaga(),
    integrationSettingsSaga(),
    webhookSaga(),
    onboardingSaga(),
    testOrdersSaga(),
    ordersUpdateSaga(),
    channelSaga(),
    businessParamsSaga(),
    sacSaga(),
    ticketSaga(),
    deliveryFailureSaga(),
    actionCenterSaga(),
  ]);
}
