import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';

interface Props {
  txtColor?: string;
  size?: string;
  bg?: string;
  bold?: boolean;
  isCorporate?: boolean;
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0px 2px 5px #00000042;
  display: flex;
  padding: 22px 25px;
  border-radius: 5px;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const Title = styled(Typography)<Props>`
  color: ${(props) => props.txtColor || '#252525'};
  font-size: 17px;
  font-weight: 400;
  margin-right: 6px;
  margin-bottom: 6px;
`;

export const Subtitle = styled(Typography)<Props>`
  color: ${(props) => props.txtColor || '#808080'};
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  margin-top: 6px;
`;

export const Status = styled(Typography)<Props>`
  display: inline-block;
  background-color: ${(props) => props.bg || '#fff'};
  color: ${(props) => props.txtColor || '#575757'};
  font-size: 12px;
  padding: 5px 11px;
  border-radius: 2px;
  font-weight: 400;
`;

export const ListItemText = styled(Typography)<Props>`
  color: ${(props) => props.txtColor || '#808080'};
  font-size: ${(props) => props.size || '14px'};
  font-weight: ${(props) => (props.bold ? 500 : 300)};
`;

export const NoItemContainer = styled.div`
  justify-content: center;
  text-align: center;
`;

export const InfoContainer = styled(Grid)`
  padding: 22px;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
  margin-top: 22px;
  margin-bottom: 28px;
`;

export const Text = styled(Typography)<Props>`
  color: ${(props) => props.txtColor || '#575757'};
  fontsize: 12px;
  font-weight: ${(props) => (props.bold ? 'bold' : 400)};
`;

export const ResponseDeadline = styled.div`
  min-width: 100px;
  background: #f4f4f4;
  border-radius: 4px;
  display: flex;
  padding: 6px;
  margin-right: 12px;
  justify-content: center;
`;

export const ChatMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChatMsg = styled(Typography)<Props>`
  color: #252525;
  font-size: 13px;
  font-weight: ${(props) => (props.bold ? 'bold' : 400)};
  margin-bottom: 2px;
`;

export const ChatAttachment = styled.a`
  text-decoration-color: #252525;
  font-size: 13px;
  cursor: pointer;
  color: #252525;
`;
