import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    maxWidth: 600,
    minHeight: 400,
    margin: '120px auto',
    flexDirection: 'column',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: '#f4f4f4',
  },
  header: {
    background: '#ED6926',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0 -4px 6px -4px rgba(0, 0, 0, 0.2)',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  logoContainer: {
    margin: '40px auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    background: 'white',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: 'black',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  textFieldMargin: {
    marginTop: 54,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    padding: 40,
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
