import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backgroundColor: '#252525',
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  }),
);

export default function BackDropComponent() {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
        <div style={{ fontSize: '17px', color: '#FFFFFF', textAlign: 'center', marginTop: '10px' }}>
          Estamos processando sua solicitação... <br></br>A tela irá recarregar
        </div>
      </Backdrop>
    </div>
  );
}
