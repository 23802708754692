import type { Reducer } from 'react';
import type { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { ErrorMessages, IError, PaginationResponse } from '../../../@types';
import type { Packages, PackagesRequest } from '../../../@types/packages';
import { api } from '../../../services/api';
import isValidFilter from '../../../shared/utils/isValidFilter';

const API_SERVICE = process.env.REACT_APP_ORDER_SALE_API_URL;

export type PackagesState = {
  fetching: boolean;
  success: boolean;
  items: Packages;
  errorMessages: ErrorMessages;
  pageSize: number;
  pageIndex: number;
};

export type PackagesAction<T> = Action<string> & {
  payload: T;
};

export type FetchPackagesAction = PackagesAction<PackagesRequest>;

const initialState: PackagesState = {
  fetching: false,
  success: false,
  items: [],
  errorMessages: [],
  pageSize: 0,
  pageIndex: 20,
};

const reducer: Reducer<PackagesState, PackagesAction<any | FetchPackagesAction>> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@@packages/FETCH_SUCCESS':
      return {
        ...state,
        success: payload.success,
        errorMessages: payload.errorMessages,
        ...payload.data,
        fetching: false,
      };
    case '@@packages/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
        items: [],
      };
    default:
      return state;
  }
};

function* fetchPackages({ payload: { pageIndex = 0, pageSize = 20, PackagesStatus } }: FetchPackagesAction) {
  const isValid = isValidFilter({
    PackagesStatus,
  });
  try {
    const payload: PaginationResponse<PackagesState> = yield call(api.get, `${API_SERVICE}/packages`, {
      params: {
        'page-index': pageIndex,
        'page-size': pageSize,
        PackagesStatus,
      },
    });

    if (!payload) {
      throw new Error(
        JSON.stringify({
          success: false,
          errorMessages: [
            {
              message: 'Operation not effected. Order is not registered.',
              notFoundHelperText: !isValid
                ? 'Não existem pacotes para listar.'
                : 'Nenhum resultado encontrado, verifique os dados inseridos e tente novamente.',
            },
          ],
          items: [],
          returnMessageType: 'ClientErrorNotFound',
          fetching: false,
        }),
      );
    }

    yield put({
      type: '@@packages/FETCH_SUCCESS',
      payload: {
        success: payload.success,
        errorMessages: payload.errorMessages,
        fetching: false,
        ...payload.data,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@@packages/FETCH_ERROR',
      payload: e.message && JSON.parse(e.message),
    });
  }
}

function* saga() {
  yield takeLatest('@@packages/FETCH', fetchPackages);
}

export { reducer as packagesReducer, saga as packagesSaga };
