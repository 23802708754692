import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { Contract } from '../../@types/seller';
import { useAuth } from '../../contexts/AuthProvider';
import { SellerState, MAIN_CONTRACT } from '../../store/ducks/seller/seller.duck';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { authenticated } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const { unregisteredContract, featureToggleOptin, acceptanceTerm } = useSelector<
    { seller: SellerState },
    SellerState
  >((state) => state.seller);
  const defaultRoute = '/dashboard';

  useEffect(() => {
    const termAccepted = acceptanceTerm?.find((term: Contract) => term.type === MAIN_CONTRACT && term.acceptedBy);
    const goToAcceptTerms = featureToggleOptin && (!termAccepted || unregisteredContract);
    if (authenticated) {
      dispatch({
        type: '@notifications/FETCH_NOTIFICATIONS',
      });
    }
    if (goToAcceptTerms) {
      dispatch({ type: '@seller/FETCH_GENERAL_CONDITIONS' });
      dispatch({ type: '@seller/FETCH_AFTER_SALES' });
      dispatch({ type: '@seller/FETCH_SERVICE_LEVEL_SLA' });
      dispatch({ type: '@seller/FETCH_PROHIBITED_MARKETING' });
      history.push('/termos-de-aceite');
    } else if (history.location.pathname === '/termos-de-aceite') {
      history.push('/');
    }
  }, [history, authenticated]);

  if (!authenticated) {
    return null;
  }

  if (history.location.pathname === '/') return <Redirect to={defaultRoute} />;

  return <Route {...rest} render={(props) => (Component ? <Component {...props} /> : null)} />;
};

export default PrivateRoute;
