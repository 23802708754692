import type { Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IError } from '../../../@types';
import { BusinessParamsResponse } from '../../../@types/businessParams';
import {
  FetchOnboardingAction,
  OnboardingState,
  OnboardingResponse,
  FetchBusinessParamsOnboardingAction,
  RequestFinishOnboardingAction,
} from '../../../@types/onboarding';
import { api } from '../../../services/api';

const { REACT_APP_SELLER_API_URL, REACT_APP_BUSINESS_PARAMS_API_URL, REACT_APP_API_BASEPATH_URL } = process.env;

const initialState: OnboardingState = {
  fetching: false,
  success: false,
  errorMessages: [],
  hasSetupCompleted: false,
  hasProductPublished: false,
  hasVtexFirstLoadTriggered: false,
  featureToggleOnboarding: false,
  hasFinishedOnboarding: false,
};

const onboardingReducer: Reducer<OnboardingState, FetchOnboardingAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case '@onboarding/FINISH_ONBOARDING_SUCCESS':
      return { ...state, hasSetupCompleted: true, fetching: false };
    case '@onboarding/FINISH_ONBOARDING':
    case '@onboarding/FETCH_ONBOARDING':
      return { ...state, ...payload, fetching: true };
    case '@onboarding/VERIFY_BUSINESS_PARAM':
      return { ...state, ...payload, fetching: true };
    case '@onboarding/FETCH_ONBOARDING_SUCCESS':
    case '@onboarding/FETCH_ONBOARDING_ERROR':
    case '@onboarding/VERIFY_BUSINESS_PARAM_SUCCESS':
    case '@onboarding/VERIFY_BUSINESS_PARAM_ERROR':
    case '@onboarding/FINISH_ONBOARDING_SUCCESSS':
      return { ...state, ...payload };
    case '@onboarding/ONBOARDING_HAS_FINISHED':
      return { ...state, ...payload };
    default:
      return state;
  }
};

function* fetchOnboarding({ payload: { sellerId } }: FetchOnboardingAction) {
  try {
    const response: OnboardingResponse = yield call(
      api.get,
      `${REACT_APP_SELLER_API_URL}/sellers/${sellerId}/onboardings`,
    );

    yield put({
      type: '@onboarding/FETCH_ONBOARDING_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        hasSetupCompleted: response.data.hasSetupCompleted,
        hasProductPublished: response.data.hasProductPublished,
        hasVtexFirstLoadTriggered: response.data.hasVtexFirstLoadTriggered,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@onboarding/FETCH_ONBOARDING_ERROR',
      payload: {
        fetching: false,
        errorMessages: e?.data?.erroMessages || [
          {
            message: 'Serviço indisponível! Tente novamente mais tarde!',
          },
        ],
      },
    });
  }
}

function* fetchBusinessParamsOnboarding({ payload: { ruleId, sellerId } }: FetchBusinessParamsOnboardingAction) {
  try {
    const response: BusinessParamsResponse = yield call(
      api.get,
      `${REACT_APP_BUSINESS_PARAMS_API_URL}/rules/${ruleId}`,
    );

    const defaultValue = response.data.defaultValue === 'true';
    const featureToggleOnboarding = response.data.isActive ? defaultValue : false;
    const doFetchOnboarding = featureToggleOnboarding && sellerId;

    if (doFetchOnboarding) {
      yield put({
        type: '@onboarding/FETCH_ONBOARDING',
        payload: { sellerId },
      });
    }

    yield put({
      type: '@onboarding/VERIFY_BUSINESS_PARAM_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        featureToggleOnboarding,
        fetching: doFetchOnboarding,
      },
    });
  } catch (error) {
    yield put({
      type: '@onboarding/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* finishOnboarding({ payload: { sellerId } }: RequestFinishOnboardingAction) {
  try {
    const token = sessionStorage.getItem('mktp_token') || '';
    const finishOnboardingPayload = {
      hasSetupCompleted: true,
    };

    yield call(fetch, `${REACT_APP_API_BASEPATH_URL}/seller/v1-rchlo/sellers/${sellerId}/onboardings`, {
      method: 'PATCH',
      body: JSON.stringify(finishOnboardingPayload),
      headers: {
        'content-type': 'application/json',
        authorization: 'bearer ' + token,
      },
    });
    yield put({
      type: '@platformValues/FETCH_SELLER_INTEGRATION_PLATFORM_SUCCESS',
    });
    yield put({
      type: '@onboarding/FINISH_ONBOARDING_SUCCESS',
    });
  } catch (error) {
    yield put({
      type: '@onboarding/VERIFY_BUSINESS_PARAM_ERROR',
      payload: {
        ...initialState,
      },
    });
  }
}

function* onboardingSaga() {
  yield takeLatest('@onboarding/FETCH_ONBOARDING', fetchOnboarding);
  yield takeLatest('@onboarding/VERIFY_BUSINESS_PARAM', fetchBusinessParamsOnboarding);
  yield takeLatest('@onboarding/FINISH_ONBOARDING', finishOnboarding);
}

export { onboardingReducer, onboardingSaga };
