import DashboardIcon from '@material-ui/icons/Dashboard';
import Settings from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingBagIcon from '@rchlo/storybook/core/Icons/ShoppingBag';

const options = [
  {
    label: 'Dashboard',
    url: '/',
    icon: DashboardIcon,
  },
  {
    label: 'Produtos',
    url: '/products',
    icon: ShoppingBasketIcon,
  },
  {
    label: 'Pedidos',
    url: '/orders',
    icon: ShoppingBagIcon,
  },
  {
    label: 'Configurações',
    url: '/settings',
    icon: Settings,
  },
];

export { options };
