import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const BackButton = styled(Button)`
  font-size: 13px;
  color: #252525;
  font-family: 'Roboto';
  font-weight: 400;
  text-transform: capitalize;
  margin: 20px 0;
`;

const ArrowLeftIcon = styled(KeyboardArrowLeftIcon)`
  color: #ed6926;
  width: 24px;
  height: 24px;
`;

export { BackButton, ArrowLeftIcon };
