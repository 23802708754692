import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface Props {
  txtColor?: string;
  bold?: boolean;
  bg?: string;
}

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StatusText = styled(Typography)<Props>`
  display: inline-block;
  color: ${(props) => props.txtColor || '#575757'};
  background: ${(props) => props.bg || 'transparent'};
  fontsize: 12px;
  text-align: center;
  font-weight: 400;
  border-radius: 2px;
  padding: 8px;
`;

export const ColumnText = styled(Typography)<Props>`
  color: ${(props) => props.txtColor || '#575757'};
  fontsize: 14px;
  font-weight: ${(props) => (props.bold ? 'bold' : 400)};
`;

export const ResponseDeadline = styled.div`
  background: #f4f4f4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px;
`;
