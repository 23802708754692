import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddCircle from '@material-ui/icons/AddCircle';
import { DateRange } from '@material-ui/pickers';
import Button from '@rchlo/storybook/core/Button';
import DateRangePicker from '@rchlo/storybook/core/DateRangePicker';
import Select from '@rchlo/storybook/core/Select';
import { SACState } from '../../../../../@types/sac';
import { ButtonLabel, ButtonText, ButtonContainer } from './Filter.styles';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      padding: '16px',
      width: '200px',
      marginTop: '1px',
      paddingBottom: '18px',
    },
  }),
);

interface Props {
  status?: string;
  selectedDates: DateRange<string>;
  handleStatusOnChange:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
  handleDateOnChange: (date: DateRange<unknown>) => void;
  onSupportClick: Function;
}

const Filter = ({
  selectedDates,
  status,
  handleStatusOnChange,
  handleDateOnChange,
  onSupportClick,
}: Props): JSX.Element => {
  const classes = useStyles();

  const { statusList } = useSelector<{ sac: SACState }>((state) => state.sac) as SACState;

  const mapOptions = (options: Array<{ id: string; title?: string; name?: string }>) =>
    options.map((item) => ({
      label: item.name || item.title || '',
      value: item.id,
      id: item.id,
    }));

  return (
    <Grid container>
      <Grid container lg={12} spacing={2}>
        <Grid item lg={12} md={12} sm={12}>
          <Typography variant="caption" component="h6">
            Filtros
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            label="Status"
            value={status}
            onChange={handleStatusOnChange}
            id="filter-status"
            options={mapOptions(statusList)}
          />
        </Grid>
        <Grid item xs={4}>
          <DateRangePicker value={selectedDates} onChange={handleDateOnChange} />
        </Grid>
        <ButtonContainer item xs={4}>
          <Button
            label={
              <ButtonLabel
                onClick={() => {
                  onSupportClick();
                }}
              >
                <AddCircle />
                <ButtonText>Abrir Ticket</ButtonText>
              </ButtonLabel>
            }
            textColor="#fff"
            primary
          />
        </ButtonContainer>
      </Grid>
    </Grid>
  );
};

export default Filter;
