import { format } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Chat, { ChatItem } from '@rchlo/storybook/core/Chat/Chat';
import { Attachment, Comment, SACState, Ticket } from '../../../../@types/sac';
import { TicketState } from '../../../../store/ducks/ticket';
import * as St from '../../../SAC/TicketDetails/TicketDetails.styles';
import { getStatus, getCommentsSection, acceptFileType } from '../../../SAC/utils';
import {
  BackIcon,
  TicketDetailsOpenInfo,
  TicketDetailsTitle,
  CloseIcon,
  SubjectText,
  TicketStatusContainer,
} from './shared-components';

type Props = {
  data?: Ticket;
  loading: boolean;
  ticketId?: string;
  setTickedId: Function;
};

const TicketDetailsModal: React.FC<Props> = ({ setTickedId, ticketId, data, loading }: Props) => {
  const dispatch = useDispatch();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { comment, attachments, fetching, fetchingTokens } = useSelector<{
    ticket: TicketState;
  }>((state) => state.ticket) as TicketState;
  const { statusList } = useSelector<{ sac: SACState }>((state) => state.sac) as SACState;

  const onSendClick = () => {
    dispatch({
      type: '@ticket/FETCH_COMMENT_ATTACHMENT',
      payload: { ticketId },
    });
  };

  const getUploadFile = (file: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = file?.target.files ? file?.target.files : '';
    const newFile = Object.values(fileUpload);

    newFile.map((file) => {
      const { type, size } = file as File;

      if (type && size && acceptFileType.includes(type) && size <= 5000000) {
        dispatch({
          type: '@ticket/FETCH_ATTACHMENTS_TOKEN',
          payload: {
            file,
          },
        });
      } else {
        dispatch({
          type: '@layout/FETCH_SNACKBAR_MESSAGE',
          payload: {
            message: 'Formato recomendado:  CSV, XLS, XLSX ou JPG/PNG . Tamanho máximo suportado: 5MB.',
            type: 'error',
            open: true,
          },
        });
      }
    });
  };

  const renderMessage = (item: ChatItem) => {
    const { attachments, body } = item.data as Comment;

    const userName = body.includes(':') ? body.split(':')[0] + ':' : '';

    const message = body.substring(body.indexOf(':') + 1);

    return (
      <St.ChatMsgContainer>
        <St.ChatMsg>
          {userName}
          <St.ChatMsg>{message}</St.ChatMsg>
        </St.ChatMsg>
        {attachments.map(({ url, fileName }: Attachment) => (
          <St.ChatAttachment key={fileName} href={url} target="_blank">
            {fileName}
          </St.ChatAttachment>
        ))}
      </St.ChatMsgContainer>
    );
  };

  const goBack = () => {
    setTickedId('');
    dispatch({
      type: '@sac/CLEAR_CURRENT_TICKET',
    });
  };

  const Component = () => {
    if (data) {
      const { color, title, bg } = getStatus(data, statusList);
      const { ticket, openingDate, subject, sla, comments } = data;
      const sections = getCommentsSection(comments);

      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BackIcon onClick={() => goBack()} />
            <TicketDetailsTitle>{`Ticket ${ticket}`}</TicketDetailsTitle>
            <TicketDetailsOpenInfo>{` Aberta em ${format(
              new Date(openingDate),
              "dd/MM/yyyy 'às' HH:mm",
            )}`}</TicketDetailsOpenInfo>
            <CloseIcon onClick={() => goBack()} />
          </Grid>
          <Grid item xs={12}>
            <SubjectText>Assunto: {subject}</SubjectText>
            <TicketStatusContainer>
              <St.ResponseDeadline>
                <span data-testid="responseDeadline">{sla || '-'}</span>
              </St.ResponseDeadline>
              <St.Status data-testid={`status`} txtColor={color} bg={bg}>
                {title}
              </St.Status>
            </TicketStatusContainer>
          </Grid>
          <Grid item xs={12}>
            <Chat
              sections={sections}
              messageContainer={renderMessage}
              uploadImage={attachments}
              acceptFileType={acceptFileType.join()}
              callbackUploadImage={getUploadFile}
              commentCallback={(comment: string) =>
                dispatch({
                  type: '@ticket/SET_COMMENT',
                  payload: { comment },
                })
              }
              comment={comment}
              onSendClick={onSendClick}
              loading={fetching}
              attachmentLoading={fetchingTokens}
              chatDisabled={data.customStatusId === '1900003759765'}
              sendButtonDisabled={!attachments.length && !comment.length}
              isFullScreen={false}
              onSetFullScreenClick={() => setIsFullScreen(!isFullScreen)}
              enableFullScreen={false}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <St.NoItemContainer>
        <InfoIcon style={{ color: '#ED6926' }} />
        <St.Subtitle txtColor="#ED6926" bold>
          Não foi possível identificar as informações do ticket.
        </St.Subtitle>
      </St.NoItemContainer>
    );
  };

  return (
    <Grid item xs={12}>
      <St.Wrapper>
        {loading ? <CircularProgress size={18} style={{ alignSelf: 'center' }} /> : <Component />}
      </St.Wrapper>
    </Grid>
  );
};

export default TicketDetailsModal;
