import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const ButtonContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ButtonText = styled.span`
  margin: 0 0 0 10px;
  white-space: nowrap;
`;

export const ButtonLabel = styled.div`
  height: 20px;
  display: flex;
  line-height: 2;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
