const isEmpty = (value: any): boolean => {
  switch (value) {
    case '':
    case null:
    case false:
    case typeof value == 'undefined':
      return true;
    default:
      return false;
  }
};

export default isEmpty;
