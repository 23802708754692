/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import React, { useState } from 'react';
import { TextField, Button, Box, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAuth } from '../../../contexts/AuthProvider';

interface LoginProps {
  inputsError: {
    username: boolean;
    password: boolean;
  };
  handleRecoveryPassword: (username: string) => void;
}

export default function Login({ inputsError, handleRecoveryPassword }: LoginProps) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [recoveryFeedback, setRecoveryFeedback] = useState<any>({});
  const [isRecoveryPassword, setIsRecoveryPassword] = useState(false);
  const auth = useAuth();

  const handleLogin = async () => {
    try {
      await auth.login(username, password);
      setHasError(false);
    } catch (error) {
      setHasError(true);
    }
  };

  const submitRecoveryPassword = async () => {
    setRecoveryFeedback({});
    const hasError: any = await handleRecoveryPassword(username);
    setRecoveryFeedback(hasError);
    if (!hasError.error) {
      setIsRecoveryPassword(false);
    }
  };

  if (isRecoveryPassword) {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={recoveryFeedback?.message}
          autoHideDuration={2000}
        >
          <Alert severity={recoveryFeedback.error ? 'error' : 'success'}>{recoveryFeedback.message}</Alert>
        </Snackbar>
        <Box
          style={{
            display: 'flex',
            maxWidth: 600,
            minHeight: 400,
            margin: '120px auto',
            flexDirection: 'column',
            borderRadius: 8,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            background: '#f4f4f4',
          }}
        >
          <Box
            style={{
              background: '#ED6926',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: '0 -4px 6px -4px rgba(0, 0, 0, 0.2)',
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
            }}
          >
            <Box style={{ margin: '40px auto' }}>
              <img
                src="/static/media/img/logo-riachuelo-portal-do-seller.png"
                width="240px"
                height="18px"
                alt="Riachuelo Marketplace"
              />
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', padding: 40, background: 'white' }}>
            <p style={{ fontSize: 20, fontWeight: 600, color: 'black' }}>Esqueci minha senha</p>
            <span style={{ fontSize: 16, fontWeight: 500 }}>Insira seu e-mail abaixo para redefinir sua senha.</span>
            <TextField
              label="E-mail"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={inputsError.username}
              helperText={inputsError.username ? 'Insira seu endereço de email registrado.' : null}
              style={{ marginTop: 54 }}
            />
            <Button
              style={{ marginTop: 54 }}
              variant="contained"
              color="primary"
              onClick={() => submitRecoveryPassword()}
            >
              Recuperar senha
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 100,
            padding: 40,
            background: 'white',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <span>Riachuelo © 2024</span>
          </Box>
          <Box>
            <img src="/static/media/img/rchlo.png" width="56px" height="12px" alt="Riachuelo Marketplace" />
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={recoveryFeedback?.message}
        autoHideDuration={2000}
      >
        <Alert severity={recoveryFeedback.error ? 'error' : 'success'}>{recoveryFeedback.message}</Alert>
      </Snackbar>
      <Box
        style={{
          display: 'flex',
          maxWidth: 600,
          minHeight: 400,
          margin: '120px auto',
          flexDirection: 'column',
          borderRadius: 8,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          background: '#f4f4f4',
        }}
      >
        <Box
          style={{
            background: '#ED6926',
            display: 'flex',
            justifyContent: 'center',
            boxShadow: '0 -4px 6px -4px rgba(0, 0, 0, 0.2)',
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          }}
        >
          <Box style={{ margin: '40px auto' }}>
            <img
              src="/static/media/img/logo-riachuelo-portal-do-seller.png"
              width="240px"
              height="18px"
              alt="Riachuelo Marketplace"
            />
          </Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', padding: 40, background: 'white' }}>
          <TextField
            label="E-mail"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={inputsError.username}
            helperText={inputsError.username ? 'Insira seu endereço de email registrado.' : null}
          />
          <TextField
            label="Senha"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginTop: 40, marginBottom: 40 }}
            error={inputsError.password}
            helperText={inputsError.password ? 'Insira a sua senha' : null}
          />
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Acessar
          </Button>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ paddingTop: 30 }}>
            <span onClick={() => setIsRecoveryPassword(true)} style={{ color: 'black', cursor: 'pointer' }}>
              Esqueci minha senha
            </span>
          </Box>
        </Box>
        {hasError && (
          <Alert variant="filled" severity="error">
            E-mail ou senha inválidos!
          </Alert>
        )}
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 100,
          padding: 40,
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <span>Riachuelo © 2024</span>
        </Box>
        <Box>
          <img src="/static/media/img/rchlo.png" width="56px" height="12px" alt="Riachuelo Marketplace" />
        </Box>
      </Box>
    </>
  );
}
