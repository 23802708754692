/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosResponse } from 'axios';
import normalizeParams from '../shared/utils/normalizeParams';

const { REACT_APP_API_BASEPATH_URL, NODE_ENV } = process.env;

const timeout = NODE_ENV === 'production' ? 8000 : 40000;

const api = axios.create({
  baseURL: REACT_APP_API_BASEPATH_URL,
  timeout,
});

const checkRequestCanceled = (err: AxiosError) => {
  const parsedError = JSON.parse(JSON.stringify(err));
  return parsedError.code === 'ECONNABORTED';
};

const checkNetworkError = (err: AxiosError) => {
  const parsedError = JSON.parse(JSON.stringify(err));
  return parsedError.message === 'Network Error';
};

// const refreshToken = async () => {
//   // const response = await axios.post('', {
//   //   refreshToken: sessionStorage.getItem('mktp_refresh_token'),
//   // });
//   try {
//     const payload: any = {
//       refresh_token: sessionStorage.getItem('mktp_refresh_token'),
//       client_id: 'mktp-mktp-odis-web-portal-seller',
//       grant_type: 'refresh_token',
//     };
//     const formBody = Object.keys(payload)
//       .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]))
//       .join('&');

//     const responseService = await fetch(
//       'https://login-stg.rchlo.io/auth/realms/rchlo-pub/protocol/openid-connect/token',
//       {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: formBody,
//       },
//     );
//     const data = await responseService.json();
//     if (data.access_token) {
//       sessionStorage.setItem('mktp_token', data.access_token);
//       sessionStorage.setItem('mktp_refresh_token', data.refresh_token);
//     } else {
//       throw new Error(data.message || 'Erro ao fazer login');
//     }
//   } catch (error) {
//     console.log('Falha no login:');
//     throw error;
//   }
//   // return response.data.accessToken;
// };

const onRequestSuccess = (response: AxiosResponse) => response.data;

const onRequestError = (err: AxiosError) => {
  const status = err.response ? err.response.status : null;

  if (checkRequestCanceled(err)) {
    // fazer algo se alguma requisição foi cancelada
  }

  if (checkNetworkError(err)) {
    // window.location.href = '/error'
  }

  const logoutUser = () => {
    sessionStorage.removeItem('mktp_token');
    sessionStorage.removeItem('mktp_resourceAccess');
    window.location.href = '/';
  };

  const responseError = Promise.reject<{ status: number; data?: any }>({ status, data: err.response?.data });
  switch (status) {
    case 401:
    case 403:
      if (
        window.location.pathname !== '/acesso-negado' &&
        err.response?.config.url?.indexOf('integrations/vtex') === -1
      ) {
        window.location.href = '/acesso-negado';
      }
      logoutUser();
      return responseError;
    case 500:
      if (
        window.location.pathname !== '/error' &&
        err.response?.config.url?.indexOf('integrations/vtex') === -1 &&
        err.response?.config.url?.indexOf('databricks/v1-rchlo/dashboard') === -1
      ) {
        setTimeout(() => {
          window.location.href = '/error';
        }, 2000);
      }
      return responseError;

    default:
      return responseError;
  }
};

api.interceptors.request.use((config: any) => {
  const token = sessionStorage.getItem('mktp_token');

  config.headers = {
    'Content-Type': 'application/json',
    Authorization: `bearer ${token}`,
  };

  if (config?.params) {
    config.params = { ...normalizeParams(config.params) };
  }

  return config;
});

api.interceptors.response.use(onRequestSuccess, onRequestError);

export { api };
