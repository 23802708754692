import { Reducer } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FirstStepsActionsTypes, FirstStepsState } from '../../../@types/firstSteps';
import { api } from '../../../services/api';

const API_USER = process.env.REACT_APP_USER_MANAGEMENT_API_URL;

const initialState: FirstStepsState = {
  firstStepsStarted: undefined,
};

const firstStepsReducer: Reducer<FirstStepsState, FirstStepsActionsTypes> = (
  state = initialState,
  { payload, type },
) => {
  switch (type) {
    case '@firstSteps/STARTED':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@firstSteps/STARTED_SUCCESS':
      return {
        ...state,
        ...payload,
        firstStepsStarted: true,
        fetching: false,
      };
    case '@firstSteps/STARTED_ERROR': {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};

function* firstStepsStarted({ payload: { userId, firstAccessAt } }: FirstStepsActionsTypes) {
  try {
    yield call(api.patch, `${API_USER}/users/${userId}`, {
      firstAccessAt,
    });

    yield put({
      type: '@firstSteps/STARTED_SUCCESS',
    });
  } catch (error) {
    yield put({
      type: '@firstSteps/STARTED_ERROR',
    });
  }
}

function* firstStepsSaga() {
  yield takeLatest('@firstSteps/STARTED', firstStepsStarted);
}

export { firstStepsReducer, firstStepsSaga };
