import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const Container = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
`;

export const ModalHeader = styled.div`
  flex-direction: row;
  display: flex;
`;

export const CloseButton = styled(IconButton)`
  cursor: pointer;
  font-size: 18px;
  font-weight: 400px;
  align-self: center;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
