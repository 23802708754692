import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { DataResponse, ErrorMessages, IError } from '../../../@types';
import type {
  IProductSKUDimensions,
  IProductSKUPrice,
  IProductSKUStock,
  ProductSKUImages,
  ProductSKURequest,
  ProductSKUVariations,
  SKUStatus,
} from '../../../@types/products';
import { api } from '../../../services/api';

const API_SERVICE = process.env.REACT_APP_CATALOG_API_URL;
export interface IChannel {
  channelId: string;
  id: string;
  value: string;
  urlChannel: string;
}

export type ProductSKUState = {
  error: boolean;
  fetching: boolean;
  success: boolean;
  errorMessages: ErrorMessages;
  productId?: string;
  skuRiachuelo?: string;
  eanCode?: string;
  isbnCode?: string;
  categoryRiachuelo?: string;
  urlRiachuelo?: string;
  dimensions?: IProductSKUDimensions;
  stock?: IProductSKUStock;
  price?: IProductSKUPrice;
  variations?: ProductSKUVariations;
  images?: ProductSKUImages;
  skuSeller?: string;
  status?: SKUStatus;
  statusPtBr?: string;
  channels?: Array<IChannel>;
};

export type ProductSKUAction<T> = Action<string> & {
  payload: T;
};

export type FetchProductSKUByIdAction = ProductSKUAction<ProductSKURequest>;

const initialState: ProductSKUState = {
  error: false,
  fetching: false,
  success: false,
  errorMessages: [],
  images: [],
  variations: [],
};

const reducer: Reducer<ProductSKUState, ProductSKUAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@productSKU/FETCH_SUCCESS':
      return { ...state, ...payload };
    case '@productSKU/FETCH_ERROR':
      return { ...state, ...payload };
    default:
      return state;
  }
};

function* fetchProductSKUById({ payload: { skuId } }: FetchProductSKUByIdAction) {
  try {
    const response: DataResponse<{}> = yield call(api.get, `${API_SERVICE}/skus/${skuId}`);

    yield put({
      type: '@productSKU/FETCH_SUCCESS',
      payload: {
        success: response.success,
        errorMessages: response.errorMessages,
        fetching: false,
        ...response.data,
      },
    });
  } catch (error) {
    const e = error as IError;

    yield put({
      type: '@productSKU/FETCH_ERROR',
      payload: {
        ...initialState,
        ...e.data,
        error: true,
      },
    });
  }
}

function* saga() {
  yield takeLatest('@productSKU/FETCH', fetchProductSKUById);
}

export { reducer as productSkuReducer, saga as productSkuSaga };
