import type { Action, Reducer } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { Pagination } from '../../../@types';
import { Steps } from '../../../@types/platformValues';
import { api } from '../../../services/api';
import {
  ProvidersRequest,
  ProviderRequest,
  ProvidersDelete,
  ProvidersCreate,
  ProviderItem,
  ProviderResponse,
  ProviderPaginationResponse,
  ProviderValidate,
} from './../../../@types/providers.d';

const API_SERVICE = process.env.REACT_APP_FREIGHT_API_URL;

export type ProviderState = Pagination<ProviderItem> & {
  indexFrom?: number;
  pageIndex?: number;
  pageSize?: number;
  totalCount?: number;
  totalPages?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  fetching: boolean;
  redirect: boolean;
  error: boolean;
};

export type ProviderAction<T> = Action<string> & {
  payload: T;
};

export type FetchProviders = ProviderAction<ProvidersRequest>;

export type FetchProvider = ProviderAction<ProviderRequest>;

export type CreateProviders = ProviderAction<ProvidersCreate>;

export type DeleteProviders = ProviderAction<ProvidersDelete>;

export type ValidateProvider = ProviderAction<ProviderValidate>;

const initialState: ProviderState = {
  items: [],
  indexFrom: 0,
  pageIndex: 0,
  pageSize: 0,
  totalCount: 0,
  totalPages: 0,
  hasPreviousPage: true,
  hasNextPage: true,
  fetching: true,
  redirect: false,
  error: false,
};

const reducer: Reducer<ProviderState, ProviderAction<any>> = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@providers/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
        redirect: false,
      };
    case '@provider/FETCH':
      return {
        ...state,
        ...payload,
        fetching: true,
        redirect: false,
      };
    case '@provider/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@provider/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@providers/FETCH_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@providers/FETCH_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@providers/CREATE_PROVIDER':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@providers/CREATE_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
        redirect: true,
      };
    case '@providers/CREATE_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@provider/UPDATE_PROVIDER':
      return {
        ...state,
        ...payload,
        fetching: true,
      };
    case '@providers/UPDATE_SUCCESS':
      return {
        ...state,
        ...payload,
        fetching: false,
        redirect: true,
      };
    case '@providers/UPDATE_ERROR':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@provider/DELETE':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    case '@layout/FETCH_SNACKBAR_MESSAGE':
      return {
        ...state,
        ...payload,
        fetching: false,
      };
    default:
      return state;
  }
};

function* createProvider({ payload: { name, url, sellerId, isOnboarding } }: CreateProviders | any) {
  try {
    const response: ProviderResponse = yield call(api.post, `${API_SERVICE}/providers`, {
      name,
      url,
      sellerId,
      type: 'ThirdParty',
    });

    yield put({
      type: '@providers/CREATE_SUCCESS',
      payload: {
        items: [response.data],
      },
    });
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Provedor adicionado com sucesso!',
        type: 'success',
        open: true,
      },
    });
    if (isOnboarding) {
      yield put({
        type: '@platformValues/SET_NEXT_STEP',
        payload: { nextStep: Steps.WEBHOOK },
      });
    }
  } catch (err) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente!',
        type: 'error',
        open: true,
      },
    });
  }
}

function* updateProvider({ payload: { name, url, sellerId, id } }: CreateProviders) {
  try {
    const response: ProviderResponse = yield call(api.put, `${API_SERVICE}/providers/${id}`, {
      name,
      url,
      sellerId,
      priority: 1,
      type: 'ThirdParty',
    });
    yield put({
      type: '@providers/UPDATE_SUCCESS',
      payload: {
        items: [response.data],
      },
    });
  } catch (err) {
    yield put({
      type: '@providers/UPDATE_ERROR',
      payload: {
        items: [],
      },
    });
  } finally {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Provedor editado com sucesso!',
        type: 'success',
        open: true,
      },
    });
  }
}

function* fetchOneProvider({ payload: { id } }: FetchProvider) {
  try {
    const response: ProviderResponse = yield call(api.get, `${API_SERVICE}/providers/${id}`);
    yield put({
      type: '@providers/FETCH_SUCCESS',
      payload: {
        items: [response.data],
      },
    });
  } catch (error) {
    yield put({
      type: '@providers/FETCH_ERROR',
      payload: {
        items: [],
      },
    });
  }
}

function* fetchProviders({ payload: { pageIndex = 0, pageSize = 1 } }: FetchProviders) {
  try {
    const response: ProviderPaginationResponse = yield call(api.get, `${API_SERVICE}/providers`, {
      params: {
        pageIndex,
        pageSize,
      },
    });

    yield put({
      type: '@providers/FETCH_SUCCESS',
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    yield put({
      type: '@providers/FETCH_ERROR',
      payload: {
        items: [],
      },
    });
  }
}

function* deleteProvider({ payload: { id } }: DeleteProviders) {
  try {
    const response: ProviderResponse = yield call(api.delete, `${API_SERVICE}/providers/${id}`);

    yield put({
      type: '@providers/DELETE_SUCCESS',
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Ocorreu um erro ao processar sua solicitação, por favor tente novamente!',
        type: 'error',
        open: true,
      },
    });
  } finally {
    yield put({
      type: '@layout/FETCH_SNACKBAR_MESSAGE',
      payload: {
        message: 'Provedor excluído com sucesso!',
        type: 'success',
        open: true,
      },
    });
    yield put({
      type: '@providers/FETCH',
      payload: {
        pageIndex: 0,
        pageSize: 1,
      },
    });
  }
}

function* saga() {
  yield takeLatest('@providers/FETCH', fetchProviders);
  yield takeLatest('@provider/FETCH', fetchOneProvider);
  yield takeLatest('@provider/DELETE', deleteProvider);
  yield takeLatest('@providers/CREATE_PROVIDER', createProvider);
  yield takeLatest('@providers/UPDATE_PROVIDER', updateProvider);
}

export { reducer as providerReducer, saga as providerSaga };
